import React from 'react';
import './Divider.scss';

const Divider = () => {
  return (
    <div className="divider">
      <div className="divider-handle">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Divider;
