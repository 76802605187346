import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppHero.scss';
import { Link } from 'wouter';
import useCurrentPage from 'hooks/useCurrentPage';
import { AppRoutes } from 'models/enums/AppRoutes';

interface Props {
  isComingFromMediaSearch: boolean;
}

export default function AppHero({ isComingFromMediaSearch }: Props) {
  const currentPage = useCurrentPage();
  const { t, i18n } = useTranslation();
  const searchKeys = ['putin', 'usa', 'nato', 'ukraina', 'ryssland', 'bothSides'];
  return (
    <section className="AppHero ukraine hero compact mediasearch">
      <div className="hero-body is-flex is-flex-direction-row is-align-items-center">
        <div className="container">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              {searchKeys.map((searchKey) => {
                const title = t(`ukraine.searches.${searchKey}`);
                return (
                  <Link
                    key={searchKey}
                    href={AppRoutes.UKRAINE_SEARCH.replace(':searchLang', i18n.language).replace(':query', searchKey)}
                    className={`button is-rounded mx-1 my-1 ${currentPage.rawQuery === searchKey && 'is-active'}`}
                    title={title}
                  >
                    {title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
