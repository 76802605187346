/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import useStore from 'store';
import { useLocation } from 'wouter';
import AppNavigation from './app/AppNavigation';
import AppHero from 'components/app/AppHero';
import AppFooter from 'components/app/AppFooter';
import AppLoader from 'components/app/AppLoader';
import BackButton from 'components/common/BackButton';
import AppAudioPlayer from 'components/app/AppAudioPlayer';
import SingleEncyclopediaSearchResult from 'components/pages/PageSingleEncyclopediaEntry';
import SingleNewsEntry from 'components/pages/PageSingleNewsEntry';
import PageSingleSREntry from 'components/pages/PageSingleSREntry';
import PageSingleSNLEntry from 'components/pages/PageSingleSNLEntry';
import PageStart from 'components/pages/PageStart';
import Page404 from 'components/pages/Page404';
import PagechatGpt from './pages/PageChatGpt';
import PageInfoAboutChatGpt from './pages/PageInfoAboutChatGpt';
import PageCategory from 'components/pages/PageCategory';
import PageMultiSearch from 'components/pages/PageMultiSearch';
import SearchSourceTags from 'components/search/SearchSourceTags';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import useCurrentPage from 'hooks/useCurrentPage';
import usePageMeta from 'hooks/usePageMeta';
import { DictionarySearchResult } from 'models/DictionarySearchResult';
import DictionaryResultComponent, {
  DictionaryMultiLanguageSearchResult,
} from 'components/pages/PageSingleDictionaryEntry';
import { convertSNLArticleUrlJsonToQuery } from 'utils/searchHelpers';
import { insummaryAPI } from 'apis/insummary/InsummaryAPI';
import { snlAPI } from 'apis/snl/SNLAPI';
import { wiktionaryAPI } from 'apis/wiktionary/WiktionaryAPI';
import { gNewsAPI } from 'apis/gnews/GNewsAPI';
import { srAPI } from 'apis/sr/SRAPI';
import { tmpUserConfig } from 'utils/tmpUserConfig';
import { GNewsResponse } from 'apis/gnews/models/GNewsResponse';
import { SRResponse } from 'apis/sr/models/SRResponse';
import { AppRoutes } from 'models/enums/AppRoutes';
import { SourceVariables } from 'models/enums/SourceVariables';
import { LangList } from 'models/enums/LangList';
import { SNLMultiResponse, SNLSingleResponse } from 'apis/snl/models/SNLResponses';
import { ActiveAudioMeta } from 'models/ActiveAudioMeta';
export default function Index() {
  const [sourceTagFilters, setSourceTagFilters] = useState<string[]>([]);
  const [activeAudioSrc, setActiveAudioSrc] = useState<string | null>(null);
  const [activeAudioisPlaying, setActiveAudioIsPlaying] = useState<boolean>(false);
  const [activeAudioMeta, setActiveAudioMeta] = useState<ActiveAudioMeta | null>(null);
  const [singleResult, setSingleResult] = useState<
    EncyclopediaSearchResult | DictionarySearchResult | GNewsResponse | SRResponse | SNLSingleResponse | undefined
  >(undefined);
  const [encyclopediaResult, setEncyclopediaResult] = useState<EncyclopediaSearchResult | undefined>(undefined);
  const [encyclopediaSuggestionResult, setEncyclopediaSuggestionResult] = useState<
    EncyclopediaSearchResult[] | undefined
  >(undefined);
  const [snlResult, setSNLResult] = useState<SNLMultiResponse[] | undefined>(undefined);
  const [dictionaryResult, setDictionaryResult] = useState<DictionarySearchResult | undefined>(undefined);
  const [newsResult, setNewsResult] = useState<GNewsResponse[] | undefined>(undefined);
  const [srResult, setSRResult] = useState<SRResponse[] | undefined>(undefined);
  const [dictionaryMultiLangResult, setDictionaryMultiLangResult] = useState<DictionarySearchResult[] | undefined>(
    undefined
  );
  const [isComingFromMediaSearch, setIsComingFromMediaSearch] = useState<boolean>(false);
  const [, setLocation] = useLocation();
  const setLoading = useStore((state) => state.setLoading);

  const currentPage = useCurrentPage();
  usePageMeta();

  const useDictionaryMultiLangSearch = tmpUserConfig.requestLanguages.length > 1;

  useEffect(() => {
    (async () => {
      setLoading(true);
      resetResults();
      if (currentPage.isMultiSearch) {
        const multiSearchPromises = [
          //encyclopediaMultiSearch(),
          //snlMultiSearch(),
          dictionaryMultiSearch(),
          newsMultiSearch(),
        ];
        if (currentPage.searchLang !== LangList.no) {
          multiSearchPromises.push(srMultiSearch());
        }
        await Promise.all(multiSearchPromises);
      } else if (currentPage.isMediaSearch) {
        const mediaSearchPromises = [newsMultiSearch()];
        if (currentPage.searchLang !== LangList.no) {
          mediaSearchPromises.push(srMultiSearch());
        }
        await Promise.all(mediaSearchPromises);
      } /*else if (currentPage.isEncyclopediaSearch) {
        if (currentPage.searchLang === LangList.no) {
          await snlSearch();
        } else {
          await encyclopediaSearch();
        }
      } else if (currentPage.isEncyclopediaSingle) {
        if (currentPage.isSingleSNLPage) {
          setSingleResult(await snlAPI.getSingle(currentPage.rawQuery));
        } else {
          setSingleResult(await insummaryAPI.getResult(currentPage.query, currentPage.searchLang));
        }
      }*/ else if (currentPage.isDictionarySingle) {
        if (useDictionaryMultiLangSearch) {
          setDictionaryMultiLangResult(
            await wiktionaryAPI.getMultiLangResult(currentPage.query, currentPage.searchLang)
          );
        } else {
          setSingleResult(await wiktionaryAPI.getResult(currentPage.query, currentPage.searchLang));
        }
      } else if (currentPage.isNewsSingle) {
        const data = window.sessionStorage.getItem(currentPage.rawQuery);
        if (data) {
          setSingleResult(JSON.parse(data));
        } else {
          setLocation(AppRoutes.START);
        }
      } else if (currentPage.isSRSingle) {
        setSingleResult(await srAPI.getSingle(currentPage.rawQuery));
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.query, currentPage.searchLang, currentPage.isMultiSearch, currentPage.isSingleEntry]);

  useEffect(() => {
    if (
      currentPage.isStart ||
      currentPage.isEncyclopediaStart ||
      currentPage.isDictionaryStart ||
      currentPage.isMediaStart
    ) {
      resetResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleResult, encyclopediaResult, dictionaryResult, dictionaryMultiLangResult]);

  useEffect(() => {
    if (currentPage.isMediaStart || currentPage.isMediaSearch) {
      setIsComingFromMediaSearch(true);
    } else if (!currentPage.isNewsSingle && !currentPage.isSRSingle) {
      setIsComingFromMediaSearch(false);
    }
  }, [currentPage.isMediaStart, currentPage.isMediaSearch, currentPage.isNewsSingle, currentPage.isSRSingle]);

  const resetResults = () => {
    setSingleResult(undefined);
    setEncyclopediaResult(undefined);
    setEncyclopediaSuggestionResult(undefined);
    setDictionaryResult(undefined);
    setDictionaryMultiLangResult(undefined);
    setNewsResult(undefined);
    setSRResult(undefined);
    setSNLResult(undefined);
  };

  const addRedirectToRouteHistory = () => {
    const routeHistory = JSON.parse(window.sessionStorage.getItem('routeHistory') ?? '[]');
    routeHistory.push('redirect');
    window.sessionStorage.setItem('routeHistory', JSON.stringify(routeHistory.slice(-50)));
  };

  const encyclopediaMultiSearch = async () => {
    if (currentPage.searchLang !== LangList.no) {
      const mainResult = await insummaryAPI.getResult(currentPage.query, currentPage.searchLang);
      setEncyclopediaResult(mainResult);
      if (mainResult?.suggestions.length) {
        setEncyclopediaSuggestionResult(
          await insummaryAPI.getEncyclopediaSuggestionResult(mainResult, currentPage.searchLang)
        );
      }
    }
  };

  const encyclopediaSearch = async () => {
    const mainResult = await insummaryAPI.getResult(currentPage.query, currentPage.searchLang);
    if (mainResult?.suggestions.length) {
      setEncyclopediaResult(mainResult);
      setEncyclopediaSuggestionResult(
        await insummaryAPI.getEncyclopediaSuggestionResult(mainResult, currentPage.searchLang)
      );
    } else {
      addRedirectToRouteHistory();
      window.history.replaceState(
        null,
        '',
        AppRoutes.ENCYCLOPEDIA_SINGLE.replace(':searchLang', currentPage.searchLang).replace(
          ':query',
          currentPage.query
        )
      );
    }
  };

  const snlMultiSearch = async () => {
    if (currentPage.searchLang === LangList.no) {
      setSNLResult(await snlAPI.getResult(currentPage.query));
    }
  };

  const snlSearch = async () => {
    const result = await snlAPI.getResult(currentPage.query);
    if (result.length > 1) {
      setSNLResult(result);
    } else if (result.length === 1) {
      if (result[0].license === SourceVariables.SNL_FREE_LICENSE) {
        addRedirectToRouteHistory();
        const query = convertSNLArticleUrlJsonToQuery(result[0].articleUrlJson);
        window.history.replaceState(
          null,
          '',
          AppRoutes.ENCYCLOPEDIA_SINGLE.replace(':searchLang', currentPage.searchLang).replace(':query', query)
        );
      } else {
        setSNLResult(result);
      }
    }
  };

  const dictionaryMultiSearch = async () => {
    const promises = [wiktionaryAPI.getResult(currentPage.query, currentPage.searchLang)];
    if (useDictionaryMultiLangSearch) {
      promises.push(wiktionaryAPI.getMultiLangResult(currentPage.query, currentPage.searchLang) as any);
    }

    const results = await Promise.all(promises);

    setDictionaryResult(results[0]);
    if (results[1]) {
      setDictionaryMultiLangResult(results[1] as any);
    }
  };

  const newsMultiSearch = async () => {
    setNewsResult(await gNewsAPI.getResult(currentPage.query, currentPage.searchLang));
  };

  const srMultiSearch = async () => {
    setSRResult(await srAPI.getResult(currentPage.query, currentPage.searchLang));
  };

  const SingleEntry = () => {
    return (
      <section className="section pt-0">
        <div className="container">
          <BackButton />
          {/*currentPage.isEncyclopediaSingle && currentPage.isSingleSNLPage && (
            <PageSingleSNLEntry result={singleResult as SNLSingleResponse} />
          )*/}
          {/*currentPage.isEncyclopediaSingle && !currentPage.isSingleSNLPage && (
            <SingleEncyclopediaSearchResult result={singleResult as EncyclopediaSearchResult} />
          )*/}
          {currentPage.isNewsSingle && <SingleNewsEntry result={singleResult as GNewsResponse} />}
          {currentPage.isSRSingle && (
            <PageSingleSREntry
              result={singleResult as SRResponse}
              activeAudioSrc={activeAudioSrc}
              activeAudioisPlaying={activeAudioisPlaying}
              setActiveAudioSrc={setActiveAudioSrc}
              setActiveAudioIsPlaying={setActiveAudioIsPlaying}
              setActiveAudioMeta={setActiveAudioMeta}
            />
          )}
          {currentPage.isDictionarySingle && singleResult && (
            <DictionaryResultComponent result={singleResult as DictionarySearchResult} />
          )}
          {currentPage.isDictionarySingle && dictionaryMultiLangResult && (
            <DictionaryMultiLanguageSearchResult dictionaryMultiLangResult={dictionaryMultiLangResult} />
          )}
        </div>
      </section>
    );
  };

  const pageContentClasses = (): string => {
    if (currentPage.isStart) {
      return 'startPage';
    }
    return '';
  };

  const PageContent = () => {
    const hasAnyResults =
      //encyclopediaResult ||
      //encyclopediaSuggestionResult ||
      newsResult ||
      srResult ||
      //snlResult ||
      dictionaryResult ||
      dictionaryMultiLangResult;

    return (
      <div className={pageContentClasses()}>
        <AppLoader hasAnyResults={!!hasAnyResults} />
        {currentPage.isStart && <PageStart />}
        {currentPage.isSingleEntry && <SingleEntry />}
        {
          /*currentPage.isEncyclopediaStart ||*/ (currentPage.isDictionaryStart || currentPage.isMediaStart) && (
            <PageCategory />
          )
        }
        {(currentPage.isMultiSearch || currentPage.isMediaSearch) /*|| currentPage.isEncyclopediaSearch*/ && (
          <PageMultiSearch
            encyclopediaResult={encyclopediaResult}
            encyclopediaSuggestionResult={encyclopediaSuggestionResult}
            gnewsResult={newsResult}
            srResult={srResult}
            snlResult={snlResult}
            dictionaryResult={dictionaryResult}
            singleResult={singleResult}
            sourceTagFilters={sourceTagFilters}
            activeAudioSrc={activeAudioSrc}
            activeAudioisPlaying={activeAudioisPlaying}
            setActiveAudioSrc={setActiveAudioSrc}
            setActiveAudioIsPlaying={setActiveAudioIsPlaying}
            setActiveAudioMeta={setActiveAudioMeta}
          />
        )}
        {currentPage.is404 && <Page404 />}
        {currentPage.ischatGpt && <PagechatGpt />}
        {currentPage.isInfoAboutChatGpt && <PageInfoAboutChatGpt />}
      </div>
    );
  };

  return (
    <Fragment>
      <AppNavigation />
      <AppHero isComingFromMediaSearch={isComingFromMediaSearch} />
      <SearchSourceTags
        encyclopediaResult={encyclopediaResult}
        gnewsResult={newsResult}
        srResult={srResult}
        snlResult={snlResult}
        dictionaryResult={dictionaryResult}
        singleResult={singleResult}
        dictionaryMultiLangResult={dictionaryMultiLangResult}
        sourceTagFilters={sourceTagFilters}
        setSourceTagFilters={setSourceTagFilters}
      />
      <PageContent />
      <AppAudioPlayer
        activeAudioSrc={activeAudioSrc}
        setActiveAudioSrc={setActiveAudioSrc}
        activeAudioisPlaying={activeAudioisPlaying}
        setActiveAudioIsPlaying={setActiveAudioIsPlaying}
        activeAudioMeta={activeAudioMeta}
        setActiveAudioMeta={setActiveAudioMeta}
      />
      {/*<AppFooter />*/}
    </Fragment>
  );
}
