/* eslint-disable */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import TuneIcon from '@mui/icons-material/Tune';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendMsg from 'assets/icons/send.svg';
import LoadingDots from 'components/common/LoadingDots';
import SEFlag from 'assets/icons/flag-sv.png';
import NOFlag from 'assets/icons/flag-no.png';
import ENFlag from 'assets/icons/flag-en.png';
import DEFlag from 'assets/icons/flag-de.png';
import DNFlag from 'assets/icons/flag-dn.png';
import ESFlag from 'assets/icons/flag-es.png';
import FIFlag from 'assets/icons/flag-fi.png';
import FRFlag from 'assets/icons/flag-fr.png';
import ITFlag from 'assets/icons/flag-it.png';
import JAFlag from 'assets/icons/flag-ja.png';
import './InputField.scss';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useChatStore } from 'store/chatStore';
import { GenerateImageMessageInner, QAItem } from 'models/AIChatTypes';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from './fileUpload/uploadButton';
import { Button } from '@mui/material';

const languages = [
  { code: 'NO', label: 'Norsk', icon: NOFlag },
  { code: 'EN', label: 'English', icon: ENFlag },
  { code: 'SV', label: 'Svenska', icon: SEFlag },
  { code: 'DE', label: 'Deutsch', icon: DEFlag },
  { code: 'DN', label: 'Dansk', icon: DNFlag },
  { code: 'ES', label: 'Español', icon: ESFlag },
  { code: 'FI', label: 'Suomalainen', icon: FIFlag },
  { code: 'FR', label: 'Français', icon: FRFlag },
  { code: 'IT', label: 'Italiano', icon: ITFlag },
  { code: 'JA', label: '日本', icon: JAFlag },
];

export interface InputFieldProps {
  setConfigSidebarActive: (active: boolean) => void;
  setFileUploadActive: (active: boolean) => void;
  setUploadedImages: React.Dispatch<React.SetStateAction<File[] | null>>;
  setEncodedImages: React.Dispatch<React.SetStateAction<string[]>>;
  isProcessingQuestion: boolean;
  setInput: (input: string) => void;
  userInput: string;
  userType: string | null;
  showFileUploadButton: boolean;
}

export const InputField = ({
  setConfigSidebarActive,
  setFileUploadActive,
  setUploadedImages,
  setEncodedImages,
  isProcessingQuestion,
  setInput,
  userInput,
  showFileUploadButton,
}: InputFieldProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [t] = useTranslation();

  const {
    selectedLanguage,
    activeView,
    questionActiveStatus,
    cancelViewPrompt,
    addMessage,
    setQuestionActiveStatus,
    setCancelViewPrompt,
    markLastQuestionProcessed,
    clearEmptyMessageIfExist,
    setSelectedLanguage,
    setGenerateImageMessage,
  } = useChatStore();

  const onSubmit = async (
    event?: React.FormEvent,
    question?: string,
    model?: string,
    ignoreLanguageCommand?: boolean,
    repeatEnabled?: boolean
  ) => {
    event?.preventDefault();
    const getQuestion = question != undefined ? question : userInput;
    if (getQuestion.length === 0 || questionActiveStatus[activeView]) {
      return;
    }
    !question && setInput('');
    const questionId = uuidv4();
    const qaItem: QAItem = {
      id: questionId,
      question: getQuestion,
      answer: null,
      ignoreRenderInChat: question != undefined,
      questionModel: model,
      ignoreLanguageCommand: ignoreLanguageCommand != undefined,
      isSpecialNeedsHelpAnswer: repeatEnabled != undefined,
    };

    addMessage(activeView, qaItem);
  };

  const cancelQuestion = (activeView: string) => {
    setQuestionActiveStatus(activeView, false);
    setCancelViewPrompt(activeView);
    markLastQuestionProcessed(activeView);
    clearEmptyMessageIfExist(activeView);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: any) => {
    if (index == undefined) {
      setSelectedLanguage(undefined);
    } else {
      setSelectedLanguage(languages[index]);
    }
    handleClose();
  };

  useEffect(() => {
    console.log('Processing question', isProcessingQuestion);
  }, [isProcessingQuestion]);

  return (
    <div className="input-container">
      <div className="cancel-and-input-wrapper">
        {isProcessingQuestion && (
          <div className="cancel-button-container">
            <div className={'button is-rounded cancel-button-bulma'} onClick={() => cancelQuestion(activeView)}>
              <label className="cancel-button-label">{t('chatGpt.cancelPrompt')}</label>
            </div>
          </div>
        )}
        <form onSubmit={onSubmit} className="input-form">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <IconButton size="medium" onClick={() => setConfigSidebarActive(true)}>
              <TuneIcon fontSize="inherit" />
            </IconButton>
            {showFileUploadButton ? (
              <IconButton size="medium" component="label" role={undefined}>
                <input
                  hidden
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={async (event) => {
                    let params = {
                      setUploadedImages: setUploadedImages,
                      setEncodedImages: setEncodedImages,
                      files: event.target.files,
                    };
                    await uploadFile(params);
                  }}
                />
                <FileUploadIcon fontSize="inherit" />
              </IconButton>
            ) : null}
            <TextField
              label={t('chatGpt.message')}
              style={{ backgroundColor: '#ffffff' }}
              variant="outlined"
              fullWidth
              onChange={(event) => setInput(event.target.value)}
              value={userInput}
              className="custom-border-radius"
              minRows={1}
              maxRows={30}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectedLanguage ? selectedLanguage.code : ''}
                    <IconButton onClick={handleClick}>
                      {selectedLanguage ? (
                        <img src={selectedLanguage.icon} alt={selectedLanguage.label} width={20} height={20} />
                      ) : (
                        <LanguageIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              disableScrollLock={true}
              onClose={handleClose}
            >
              {languages.map((lang, index) => (
                <div key={lang.code}>
                  <MenuItem selected={selectedLanguage?.code === lang.code} onClick={() => handleMenuItemClick(index)}>
                    <img src={lang.icon} alt={lang.label} width={20} height={20} />
                    <label className="ml-2">{lang.label}</label>
                  </MenuItem>
                  {index !== languages.length - 1 && <hr className="item-divider" />}
                </div>
              ))}
            </Menu>
            {(!isProcessingQuestion && (
              <input type="image" alt="sendMsgImage" src={SendMsg} onClick={() => onSubmit} />
            )) || <LoadingDots />}
          </div>
        </form>
      </div>
    </div>
  );
};
