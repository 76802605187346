import React, { Fragment } from 'react';
import './PageSingleSREntry.scss';
import { useTranslation } from 'react-i18next';
import { definableValue } from 'utils/definableValue';
import { SRResponse } from 'apis/sr/models/SRResponse';
import Icon from 'components/app/AppIcon';
import SourceCriticism from 'components/common/SourceCriticism';
import useCurrentPage from 'hooks/useCurrentPage';
import { ActiveAudioMeta } from 'models/ActiveAudioMeta';

interface Props {
  result?: SRResponse;
  activeAudioSrc: string | null;
  activeAudioisPlaying: boolean | null;
  setActiveAudioSrc: (src: string | null) => void;
  setActiveAudioIsPlaying: (isPlaying: boolean) => void;
  setActiveAudioMeta: (meta: ActiveAudioMeta | null) => void;
}

export default function PageSingleNewsEntry({
  result,
  activeAudioSrc,
  activeAudioisPlaying,
  setActiveAudioSrc,
  setActiveAudioIsPlaying,
  setActiveAudioMeta,
}: Props) {
  const [t] = useTranslation();
  const currentPage = useCurrentPage();

  const date = result?.publishDate ? result.publishDate?.split('T')?.[0] : '';

  if (!definableValue(result)) return <div></div>;

  const image = (figureClass: string) => {
    if (!result?.imageURL) return undefined;
    return (
      <figure className={figureClass}>
        <img src={result.imageURL} alt={result.title} />
      </figure>
    );
  };

  const paragraphs = result.content.split(/\n/g);
  const currentEpisodeIsPlaying = activeAudioisPlaying && activeAudioSrc === result.downloadURL;

  return (
    <Fragment>
      <div className="PageSingleSREntry">
        <h1 className="single-title">{result.title}</h1>

        <p className="meta-data mb-3">
          <span className="date">
            {t('general.published')}: {date}
          </span>
        </p>

        {image('inline-image')}

        <div className="content">
          {paragraphs.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })}
        </div>

        <div className="mb-3">
          {!currentPage.isSingleUkraineSREntry && (
            <a
              href="#!"
              rel="noopener noreferrer"
              className="button is-rounded is-link is-light mr-2 mb-3"
              onClick={async (e) => {
                e.preventDefault();
                setActiveAudioSrc(result.downloadURL);
                setActiveAudioMeta({ title: result.title, source: result.source });
                if (currentEpisodeIsPlaying) {
                  setActiveAudioIsPlaying(false);
                } else {
                  setActiveAudioIsPlaying(true);
                }
              }}
            >
              <Icon name={currentEpisodeIsPlaying ? 'pause' : 'play'} />
              <span>{currentEpisodeIsPlaying ? t('general.pauseEpisode') : t('general.listenToEpisode')}</span>
            </a>
          )}
          <a
            href={result.url}
            target="_blank"
            rel="noopener noreferrer"
            className="button is-rounded is-link is-light mb-3"
          >
            <span>{currentPage.isSingleUkraineSREntry ? t('general.listenToEpisode') : t('general.goToSource')}</span>
            <Icon name="external-link-alt" />
          </a>
        </div>

        <SourceCriticism className="mb-5" sourceType={result.sourceType} />

        {image('block-image')}
      </div>
    </Fragment>
  );
}
