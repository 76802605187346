/* eslint-disable */

import { useChatStore } from 'store/chatStore';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { AuthData } from 'models/enums/AuthData';
import { AIView, QAItem } from 'models/AIChatTypes';
import { prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import ConfigSidebar from '../ConfigSidebar';
import ChatWindow from '../ChatWindow';
import { InputField } from '../InputField';
import './FormView.scss';
import { CreateQuestionsForm } from '../forms/CreateQuestionsForm';
import { StudyHelpForm } from '../forms/StudyHelpForm';
import { AssistanceForm } from '../forms/AssistanceForm';
import { LectureInspirationForm } from '../forms/LectureInspirationForm';
import { VikarieAkutenForm } from '../forms/VikarieAkutenForm';
import { ExplanationForm } from '../forms/ExplainForm';
import { GetStartedForm } from '../forms/GetStartedForm';
import FileUploadWindow, { ImageMessage, ImageURL, TextMessage } from '../fileUpload/FileUploadWindow';
import { SendImageToGPTParams, sendImageToGPT } from './Chat';
import { DEFAULT_VISION_PROMT } from 'config';
import { GenerateImageForm } from '../forms/GenerateImageForm';
import { ModelOption } from '../ModelSelector';

export const Forms = () => {
  const [t] = useTranslation();
  const { i18n } = useTranslation();

  const {
    setPrompt,
    setQuestionActiveStatus,
    setSelectedLanguage,
    setSelectedFrequencyPenalty,
    setSelectedGPTModel,
    setSelectedPresencePenalty,
    setSelectedTemperature,
    addMessage,
    messages,
    activeView,
    questionActiveStatus,
    selectedFrequencyPenalty,
    selectedTemperature,
    selectedPresencePenalty,
    selectedLanguage,
    selectedGPTModel,
  } = useChatStore();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const languageMap: { [key: string]: string } = {
    en: 'en-US',
    sv: 'sv-SE',
    no: 'nb-NO',
    da: 'da-DK',
  };

  const errorId = 'ERROR_ID';

  const [userInput, setUserInput] = useState('');
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<QAItem[]>([]);
  const [toggleSetErrorMessage, setToggleSetErrorMessage] = useState<boolean>(false);
  const [toggleClearErrorMessage, setToggleClearErrorMessage] = useState<boolean>(false);
  const [isCancelling] = useState<boolean>(false);
  const [configSidebarActive, setConfigSidebarActive] = useState<boolean>(false);
  const [fileUploadActive, setFileUploadActive] = useState<boolean>(false);
  const [encodedImages, setEncodedImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[] | null>(null);
  const [sendImage, setSendImage] = useState<boolean>(false);
  const [GPTPromt, setGPTPromt] = useState<string>(DEFAULT_VISION_PROMT);
  const [lang, setLang] = useState('');
  const [voiceURI, setVoiceURI] = useState('');

  useEffect(() => {
    setLang(languageMap[i18n.language] || i18n.language);
  }, [i18n.language]);

  const userType = useRef<string | null>(localStorage.getItem(AuthData.USER_TYPE));

  const setErrorMessage = () => {
    const newItem: QAItem = {
      id: errorId,
      question: '',
      answer: t('chatGpt.errorMessage'),
      ignoreRenderInChat: false,
    };

    const errorExists = questionsAndAnswers.find((item) => item.id === errorId);
    if (errorExists) {
      return;
    }

    setQuestionsAndAnswers((prevState) => [...prevState, newItem]);
  };

  const clearErrorMessage = () => {
    const filteredQuestionsAndAnswers = questionsAndAnswers.filter((item) => item.id !== errorId);
    setQuestionsAndAnswers(filteredQuestionsAndAnswers);
  };

  useEffect(() => {
    if (toggleClearErrorMessage) {
      clearErrorMessage();
      setToggleClearErrorMessage(false);
    }
  }, [toggleClearErrorMessage]);

  useEffect(() => {
    if (toggleSetErrorMessage) {
      if (isCancelling) {
        return;
      }
      setErrorMessage();
      setToggleSetErrorMessage(false);
    }
  }, [toggleSetErrorMessage]);

  useEffect(() => {
    const nrOfMessages = messages[activeView]?.length || 0;
    if (nrOfMessages === 0) return;
    const lastMessage = messages[activeView][nrOfMessages - 1];
    if (!questionActiveStatus[activeView] && !lastMessage.questionProcessed) {
      setQuestionActiveStatus(activeView, true);
      const payload: any = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        }
      );
      payload.authString = getAuthenticationStringAttachment() || '';
      setPrompt(payload);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages[activeView]]);

  const resetConfigurations = () => {
    setSelectedFrequencyPenalty(0);
    setSelectedTemperature(1);
    setSelectedPresencePenalty(0);
    setSelectedLanguage(undefined);
  };

  let showFileUploadButton: boolean = selectedGPTModel.vision;

  useEffect(() => {
    showFileUploadButton = selectedGPTModel.vision;
  }, [selectedGPTModel]);

  let params: SendImageToGPTParams = {
    sendImage,
    activeView,
    addMessage,
    encodedImages,
    uploadedImages,
    selectedGPTModel,
    setSendImage,
    setFileUploadActive,
    GPTPromt,
    stepByStepPrompt: t('chatGpt.stepByStepPrompt'),
  };

  useEffect(() => {
    sendImageToGPT(params);
  }, [sendImage]);

  const imageModels: Array<ModelOption> = [
    {
      label: 'DALL·E 2',
      model: 'dall-e-2',
    },
    {
      label: 'DALL·E 3',
      model: 'dall-e-3',
    },
  ];

  return (
    <div className="formcontent-container">
      <div className="chat-box">
        <ConfigSidebar
          isActive={configSidebarActive}
          setIsActive={setConfigSidebarActive}
          temperature={selectedTemperature}
          setTemperature={setSelectedTemperature}
          frequencyPenalty={selectedFrequencyPenalty}
          setFrequencyPenalty={setSelectedFrequencyPenalty}
          presencePenalty={selectedPresencePenalty}
          setPresencePenalty={setSelectedPresencePenalty}
          userType={userType.current}
          selectedModel={selectedGPTModel}
          setSelectedModel={setSelectedGPTModel}
          resetConfig={resetConfigurations}
          setVoiceURI={setVoiceURI}
          lang={lang}
        />
        {showFileUploadButton ? (
          <FileUploadWindow
            isActive={fileUploadActive}
            setIsActive={setFileUploadActive}
            uploadedImages={uploadedImages}
            setUploadedImages={setUploadedImages}
            setEncodedImages={setEncodedImages}
            setSendImage={setSendImage}
            setGPTPromt={setGPTPromt}
          />
        ) : null}
        <div id="scroll" className="form-conversation">
          <ChatWindow voiceURI={voiceURI} lang={lang} />
        </div>
        <div className="input-field">
          {activeView != AIView.GENERATE_IMAGE ? (
            <InputField
              setConfigSidebarActive={setConfigSidebarActive}
              setFileUploadActive={setFileUploadActive}
              setUploadedImages={setUploadedImages}
              setEncodedImages={setEncodedImages}
              isProcessingQuestion={questionActiveStatus[activeView]}
              setInput={setUserInput}
              userInput={userInput}
              userType={userType.current}
              showFileUploadButton={showFileUploadButton}
            />
          ) : null}
        </div>
      </div>
      {activeView == AIView.CREATE_QUESTIONS ? (
        <CreateQuestionsForm />
      ) : activeView == AIView.QUIZ ? (
        <StudyHelpForm />
      ) : activeView == AIView.DISABILITY_HELP ? (
        <AssistanceForm />
      ) : activeView == AIView.LECTURE_INSPIRATION ? (
        <LectureInspirationForm />
      ) : activeView == AIView.SUBSTITUTE_TEACHER_HELP ? (
        <VikarieAkutenForm />
      ) : activeView == AIView.EXPLANATION ? (
        <ExplanationForm />
      ) : activeView == AIView.HELP_GETTING_STARTED ? (
        <GetStartedForm />
      ) : activeView == AIView.GENERATE_IMAGE ? (
        <GenerateImageForm models={imageModels} />
      ) : null}
    </div>
  );
};
