import React from 'react';
import { useTranslation } from 'react-i18next';
import './SearchFormLanguageSwitcher.scss';
import IconFlagSV from 'assets/icons/flag-sv.png';
import IconFlagNO from 'assets/icons/flag-no.png';
import IconFlagEN from 'assets/icons/flag-en.png';

interface Props {
  searchLanguage: string;
  showSearchLanguageDropdown: boolean;
  handleSearchLanguageClick: (language: string) => void;
  setShowSearchLanguageDropdown: (show: boolean) => void;
}

export default function SearchFormLanguageSwitcher({
  searchLanguage,
  showSearchLanguageDropdown,
  handleSearchLanguageClick,
  setShowSearchLanguageDropdown,
}: Props) {
  const [t] = useTranslation();

  const langFlags: any = {
    sv: IconFlagSV,
    no: IconFlagNO,
    en: IconFlagEN,
  };

  return (
    <div className={`SearchFormLanguageSwitcher dropdown is-right${showSearchLanguageDropdown ? ' is-active' : ''}`}>
      <div className="dropdown-trigger">
        <div
          className="button"
          aria-haspopup="true"
          aria-controls="searchLanguageDropdown"
          data-cy="search-language-button"
          onClick={(e) => {
            e.preventDefault();
            setShowSearchLanguageDropdown(!showSearchLanguageDropdown);
          }}
        >
          <img src={langFlags[searchLanguage]} alt="" className="icon" />
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div className="dropdown-menu" id="searchLanguageDropdown" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item" onClick={() => handleSearchLanguageClick('sv')} data-cy="search-language-sv">
            <img src={langFlags['sv']} alt="" />
            <p>{t('appHero.findSwedishWords')}</p>
          </div>
          <hr className="dropdown-divider" />
          <div className="dropdown-item" onClick={() => handleSearchLanguageClick('no')} data-cy="search-language-no">
            <img src={langFlags['no']} alt="" />
            <p>{t('appHero.findNorwegianWords')}</p>
          </div>
          <hr className="dropdown-divider" />
          <div className="dropdown-item" onClick={() => handleSearchLanguageClick('en')} data-cy="search-language-en">
            <img src={langFlags['en']} alt="" />
            <p>{t('appHero.findEnglishWords')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
