import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

interface MultiLineTooltipProps extends TooltipProps {
  title: string;
}

const MultiLineTooltip = ({ title, ...props }: MultiLineTooltipProps) => {
  const multilineTitle = title.split('\n').map((line, index) => <div key={index}>{line}</div>);

  return <Tooltip title={multilineTitle} {...props} />;
};

export default MultiLineTooltip;
