import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AppAudioPlayer.scss';
import ReactHowler from 'react-howler';
import Icon from 'components/app/AppIcon';
import { ActiveAudioMeta } from 'models/ActiveAudioMeta';
import iconHeadphones from 'assets/icons/headphones.png';

interface Props {
  activeAudioSrc: string | null;
  setActiveAudioSrc: (src: string | null) => void;
  activeAudioisPlaying: boolean;
  setActiveAudioIsPlaying: (isPlaying: boolean) => void;
  activeAudioMeta: ActiveAudioMeta | null;
  setActiveAudioMeta: (meta: ActiveAudioMeta | null) => void;
}

interface AudioPlayerStatus {
  progress: number;
  wasPlaying: boolean;
  activeAudioSrc: string | null;
  timestamp: number;
  activeAudioMeta: ActiveAudioMeta | null;
}

export default function AppAudioPlayer({
  activeAudioSrc,
  setActiveAudioSrc,
  activeAudioisPlaying,
  setActiveAudioIsPlaying,
  activeAudioMeta,
  setActiveAudioMeta,
}: Props) {
  const audioPlayer = useRef<ReactHowler | null>(null);
  const audioPlayerStatus = useRef<AudioPlayerStatus | null>(null);
  const audioPlayerStatusFromStore = useRef<AudioPlayerStatus | null>(null);
  const audioPlayerInterval = useRef<any>(null);
  const sessionStorageKey = 'audioPlayerStatus';
  const [t] = useTranslation();

  const saveAudioPlayerStatusToStore = () => {
    if (!audioPlayerStatus.current) {
      return;
    }
    window.sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        ...audioPlayerStatus.current,
        timestamp: Math.floor(Date.now() / 1000),
      })
    );
  };

  const loadAudioPlayerStatusFromStore = () => {
    const audioPlayerStatusJson = window.sessionStorage.getItem(sessionStorageKey);
    if (audioPlayerStatusJson) {
      const audioPlayerStatus = JSON.parse(audioPlayerStatusJson) as unknown as AudioPlayerStatus;
      const timestampAge = Math.floor(Date.now() / 1000) - audioPlayerStatus.timestamp;
      if (timestampAge < 3) {
        audioPlayerStatusFromStore.current = audioPlayerStatus;
        setActiveAudioSrc(audioPlayerStatus.activeAudioSrc);
        setActiveAudioMeta(audioPlayerStatus.activeAudioMeta);
      } else {
        audioPlayerStatusFromStore.current = null;
        window.sessionStorage.removeItem(sessionStorageKey);
      }
    }
  };

  const onActiveAudioSrcLoaded = () => {
    clearInterval(audioPlayerInterval.current);
    if (audioPlayerStatusFromStore.current) {
      if (audioPlayerStatusFromStore.current.activeAudioSrc === activeAudioSrc) {
        audioPlayer.current?.howler.seek(audioPlayerStatusFromStore.current.progress);
      } else {
        audioPlayer.current?.howler.seek(0);
      }
      if (audioPlayerStatusFromStore.current.wasPlaying && !audioPlayer.current?.howler.playing()) {
        audioPlayer.current?.howler.play();
        setActiveAudioIsPlaying(true);
      }
    } else if (!audioPlayer.current?.howler.playing()) {
      audioPlayer.current?.howler.play();
      setActiveAudioIsPlaying(true);
    }
    audioPlayerInterval.current = setInterval(() => {
      if (audioPlayer.current) {
        audioPlayerStatus.current = {
          progress: audioPlayer.current?.howler.seek(),
          wasPlaying: audioPlayer.current?.howler.playing(),
          activeAudioSrc,
          timestamp: 0,
          activeAudioMeta,
        };
      }
    }, 25);
  };

  useEffect(() => {
    loadAudioPlayerStatusFromStore();
    return () => {
      clearInterval(audioPlayerInterval.current);
      saveAudioPlayerStatusToStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activeAudioSrc) {
    return <div />;
  }

  return (
    <div className="AppAudioPlayer">
      <ReactHowler
        src={activeAudioSrc}
        html5={true}
        playing={activeAudioisPlaying}
        ref={(ref) => (audioPlayer.current = ref)}
        onLoad={() => {
          onActiveAudioSrcLoaded();
        }}
      />
      <div className="meta">
        <span className="meta-title">{activeAudioMeta?.title}</span>
        <div>
          <img className="source-icon" src={iconHeadphones} alt="" />
          <span className="meta-source">{activeAudioMeta?.source}</span>
        </div>
      </div>
      <div className="buttons">
        {activeAudioisPlaying ? (
          <button
            className="button is-rounded"
            onClick={() => {
              audioPlayer.current?.howler.pause();
              setActiveAudioIsPlaying(false);
            }}
          >
            <div className="eq-bar-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <rect className="eq-bar eq-bar--1" x="4" y="4" width="3.7" height="8" />
                <rect className="eq-bar eq-bar--2" x="10.2" y="4" width="3.7" height="16" />
                <rect className="eq-bar eq-bar--3" x="16.3" y="4" width="3.7" height="11" />
              </svg>
            </div>

            <span>{t('audioPlayer.pause')}</span>
          </button>
        ) : (
          <button
            className="button is-rounded"
            onClick={() => {
              if (!audioPlayer.current?.howler.playing()) {
                audioPlayer.current?.howler.play();
                setActiveAudioIsPlaying(true);
              }
            }}
          >
            <Icon name="play" />
            <span>{t('audioPlayer.listen')}</span>
          </button>
        )}

        <button
          className="button is-rounded"
          onClick={() => {
            setActiveAudioSrc(null);
            setActiveAudioIsPlaying(false);
            clearInterval(audioPlayerInterval.current);
            audioPlayerStatus.current = null;
            audioPlayerStatusFromStore.current = null;
            window.sessionStorage.removeItem(sessionStorageKey);
          }}
        >
          <Icon name="times" className="close-icon" />
          <span>{t('audioPlayer.close')}</span>
        </button>
      </div>
    </div>
  );
}
