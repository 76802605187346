import React from 'react';
import './FileUploadWindow.scss';
import { Button } from '@mui/material';
import { DEFAULT_VISION_PROMT } from 'config';
import { useTranslation } from 'react-i18next';

export interface PromtButtonProps {
  text: string;
  promt?: string;
  setSendImage: React.Dispatch<React.SetStateAction<boolean>>;
  setGPTPromt: React.Dispatch<React.SetStateAction<string>>;
  icon?: React.ReactNode;
  className?: string;
}

export const PromtButton = ({ text, promt, setSendImage, setGPTPromt, icon, className }: PromtButtonProps) => {
  const [t] = useTranslation();
  const defaulted_promt = promt || text || DEFAULT_VISION_PROMT;
  const defaulted_text = text || DEFAULT_VISION_PROMT;

  return (
    <>
      <Button
        className={className}
        role={undefined}
        variant="contained"
        startIcon={icon}
        onClick={() => {
          setGPTPromt(t(defaulted_promt));
          setSendImage(true);
        }}
      >
        {defaulted_text}
      </Button>
    </>
  );
};
