import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.scss';
import { useTranslation } from 'react-i18next';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { Tooltip } from '@mui/material';

interface AssistanceFormData {
  specialNeeds: string;
  studentAge: string;
  taskDescription: string;
}

interface AssistanceFormErrors {
  specialNeeds: boolean;
  studentAge: boolean;
  taskDescription: boolean;
}

export const AssistanceForm: React.FC = () => {
  const [formData, setFormData] = useState<AssistanceFormData>({
    specialNeeds: '',
    studentAge: '',
    taskDescription: '',
  });

  const [errors, setErrors] = useState<AssistanceFormErrors>({
    specialNeeds: false,
    studentAge: false,
    taskDescription: false,
  });

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();

  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const validateField = (name: keyof AssistanceFormData, value: string): boolean => {
    if (name === 'studentAge') {
      return value.trim() === '' || isNaN(Number(value));
    }
    return value.trim() === '';
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: validateField(name as keyof AssistanceFormData, value) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors: AssistanceFormErrors = {
      specialNeeds: validateField('specialNeeds', formData.specialNeeds),
      studentAge: validateField('studentAge', formData.studentAge),
      taskDescription: validateField('taskDescription', formData.taskDescription),
    };

    setErrors(formErrors);

    const isValid = !formErrors.specialNeeds && !formErrors.studentAge && !formErrors.taskDescription;

    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      const prompt = t('chatGpt.specialNeedsModal.prompt', {
        specialNeeds: formData.specialNeeds,
        assignment: formData.taskDescription,
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, true, false);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  const isFormValid =
    formData.specialNeeds.trim() !== '' &&
    formData.studentAge.trim() !== '' &&
    formData.taskDescription.trim() !== '' &&
    !errors.specialNeeds &&
    !errors.studentAge &&
    !errors.taskDescription;

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.helpWithStudentDisability')}</label>
      <Tooltip
        title={t('chatGpt.specialNeedsModal.specialNeeds')}
        placement="top-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <TextField
          label={t('chatGpt.specialNeedsModal.specialNeeds')}
          name="specialNeeds"
          variant="outlined"
          value={formData.specialNeeds}
          onChange={handleInputChange}
          error={errors.specialNeeds}
          fullWidth
          className="form-input"
        />
      </Tooltip>

      <Tooltip title={t('chatGpt.specialNeedsModal.age')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.specialNeedsModal.age')}
          name="studentAge"
          type="text"
          variant="outlined"
          value={formData.studentAge}
          onChange={handleInputChange}
          error={errors.studentAge}
          fullWidth
          className="form-input"
        />
      </Tooltip>

      <Tooltip
        title={t('chatGpt.specialNeedsModal.assignmentDescription')}
        placement="top-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <TextField
          label={t('chatGpt.specialNeedsModal.assignmentDescription')}
          name="taskDescription"
          variant="outlined"
          value={formData.taskDescription}
          onChange={handleInputChange}
          error={errors.taskDescription}
          fullWidth
          multiline
          className="form-textarea"
          rows={3}
        />
      </Tooltip>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
      >
        {t('chatGpt.specialNeedsModal.title')}
      </Button>
    </form>
  );
};
