import React from 'react';
import useStore from 'store';
import { useTranslation } from 'react-i18next';
import { AuthData } from 'models/enums/AuthData';
import SearchForm from 'components/search/SearchForm';
import './AppHero.scss';
import useCurrentPage from 'hooks/useCurrentPage';
import { tmpUserConfig } from 'utils/tmpUserConfig';

interface Props {
  isComingFromMediaSearch: boolean;
}

export default function AppHero({ isComingFromMediaSearch }: Props) {
  const [t] = useTranslation();
  const loading = useStore((state) => state.loading);
  const currentPage = useCurrentPage();

  const isCompact =
    loading ||
    currentPage.is404 ||
    currentPage.isMultiSearch ||
    currentPage.isMediaSearch ||
    currentPage.isEncyclopediaSearch ||
    currentPage.isSingleEntry ||
    (currentPage.isEncyclopediaStart && tmpUserConfig.items.encyclopedia.length === 0) ||
    (currentPage.isDictionaryStart && tmpUserConfig.items.dictionary.length === 0) ||
    (currentPage.isMediaStart && tmpUserConfig.items.media.length === 0) ||
    currentPage.ischatGpt ||
    currentPage.isInfoAboutChatGpt;

  const heroClasses = () => {
    let classes = 'AppHero hero';

    if (isCompact) {
      classes += ' compact';
    }

    if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSearch || currentPage.isEncyclopediaSingle) {
      classes += ' encyclopedia';
    } else if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
      classes += ' dictionary';
    } else if (currentPage.isMediaStart || currentPage.isMediaSearch || isComingFromMediaSearch) {
      classes += ' mediasearch';
    } else if (currentPage.ischatGpt) {
      classes += ' chatGpt';
    } else if (currentPage.isInfoAboutChatGpt) {
      classes += ' infoaboutchatgpt';
    }

    return classes;
  };

  let title = t('appHero.startHeading', { name: window.localStorage.getItem(AuthData.FIRST_NAME) });
  if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSearch || currentPage.isEncyclopediaSingle) {
    title = t('general.encyclopedia');
  } else if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
    title = t('general.dictionary');
  } else if (currentPage.isMediaStart || currentPage.isMediaSearch) {
    title = t('general.mediasearch');
  } else if (currentPage.ischatGpt) {
    title = t('appHero.chatGpt');
  } else if (currentPage.isInfoAboutChatGpt) {
    title = t('appHero.chatGpt');
  }

  return (
    <section className={heroClasses()}>
      <div className="hero-body is-flex is-flex-direction-row is-align-items-center">
        <div className="container">
          <div className="columns is-centered">
            {!currentPage.ischatGpt && (
              <div className="column has-text-centered">
                {!isCompact && <h1 className="title has-text-weight-light is-size-1 is-size-4-mobile">{title}</h1>}
                <SearchForm isComingFromMediaSearch={isComingFromMediaSearch} />
              </div>
            )}
            {currentPage.ischatGpt && (
              <div className="has-text-centered">
                <h1 className="title has-text-weight-light is-size-1 is-size-4-mobile">{title}</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
