import React, { Fragment, useState } from 'react';
import './FeedbackComponent.scss';
import FeedbackFloatingForm from 'components/feedback/FeedbackFloatingForm';
import FeedbackButtons from 'components/feedback/FeedbackButtons';
import { FeedbackRating } from 'apis/insummary/models/FeedbackRating';

interface Props {
  resultId?: number | string;
}

export default function FeedbackComponent({ resultId }: Props) {
  const [resultRating, setResultRating] = useState<FeedbackRating>(FeedbackRating.Waiting);
  const showFloatingFeedbackForm = [FeedbackRating.Good, FeedbackRating.Bad].includes(resultRating);

  return (
    <Fragment>
      <FeedbackButtons onFeedbackButtonPressed={setResultRating}></FeedbackButtons>
      {showFloatingFeedbackForm && (
        <FeedbackFloatingForm id={resultId} setFeedbackStatus={setResultRating} ratingStatus={resultRating} />
      )}
    </Fragment>
  );
}
