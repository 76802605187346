import React from 'react';
import './GPTModelSelector.scss';
import { UserTypes } from 'models/enums/AuthData';

export interface GPTModels {
  label: string;
  model: string;
  vision: boolean;
}

export enum GPTModelIds {
  // eslint-disable-next-line no-unused-vars
  GPT4OMINI = 'gpt-4o-mini',
  // eslint-disable-next-line no-unused-vars
  GPT4 = 'gpt-4',
  // eslint-disable-next-line no-unused-vars
  GPT4TURBO = 'gpt-4-turbo',
  // eslint-disable-next-line no-unused-vars
  GPT4O = 'gpt-4o',
}

export interface GPTModelSelectorProps {
  userType: string | null;
  setSelectedModel: (model: GPTModels) => void;
  selectedModel: GPTModels;
}

export const studentModels: GPTModels[] = [
  { label: 'GPT 4o Mini', model: 'gpt-4o-mini', vision: false },
  { label: 'GPT 4o', model: 'gpt-4o', vision: true },
];

export const teacherModels: GPTModels[] = [
  ...studentModels,
  { label: 'GPT 4', model: 'gpt-4', vision: true },
  { label: 'GPT 4 Turbo', model: 'gpt-4-turbo', vision: true },
];

export const GPTModelSelector = ({ userType, selectedModel, setSelectedModel }: GPTModelSelectorProps) => {
  const modelsToUse = userType === UserTypes.TEACHER ? teacherModels : studentModels;

  const getItemClass = (model: GPTModels) => {
    return model.model === selectedModel.model ? 'is-active' : '';
  };

  return (
    <div className="tabs is-toggle is-toggle-rounded model-tabs" id="gpt-model-selector">
      <ul>
        {modelsToUse.map((model) => (
          <li className={getItemClass(model)} onClick={() => setSelectedModel(model)} key={model.model}>
            <a className="has-text-centered">{model.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
