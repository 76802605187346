import axios, { AxiosInstance } from 'axios';

export abstract class BaseAPI {
  protected request: AxiosInstance;

  constructor(baseURL: string) {
    this.request = axios.create({
      baseURL,
    });
  }
}
