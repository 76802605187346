import React, { useState, Fragment } from 'react';
import './PageSingleDictionaryEntry.scss';
import { useTranslation } from 'react-i18next';
import useCurrentPage from 'hooks/useCurrentPage';
import { DictionarySearchResult as DictionarySearchResultModel } from 'models/DictionarySearchResult';
import { definableValue } from 'utils/definableValue';
import { PartOfSpeechDivider, WordDefinition, WordMorphology } from 'apis/wiktionary/models/DictionaryResponse';
import { groupBy } from 'underscore';
import IconFlagSV from 'assets/icons/flag-sv.png';
import IconFlagNO from 'assets/icons/flag-no.png';
import IconFlagEN from 'assets/icons/flag-en.png';
import PageNoResults from 'components/pages/PageNoResults';
import SourceCriticism from 'components/common/SourceCriticism';

interface Props {
  result?: DictionarySearchResultModel;
}

interface MultiLangProps {
  dictionaryMultiLangResult?: DictionarySearchResultModel[];
}

interface DictionaryPartOfSpeechSubResultProps {
  word: string;
  wordClassDescription: string;
  nrOfWordClasses: number;
  dataEntries: PartOfSpeechDivider;
  language?: string;
}

const WordDefinitions = (wordDefinition: WordDefinition) => {
  return (
    <div>
      <div>
        <p className="row mx-4">
          {CleanUpText(
            wordDefinition.definition!.charAt(0).toUpperCase() + wordDefinition.definition?.slice(1)?.split('@')[0]
          )}
        </p>
      </div>
      <div className="row ml-6 is-italic">{CleanUpText(wordDefinition.example?.split('@')[0])}</div>
    </div>
  );
};

const CleanUpText = (text: string | undefined): any => {
  if (text) {
    const cleanText = text
      .replaceAll('"', '')
      .replaceAll("'''", '')
      .replaceAll('&rarr', '->')
      .replaceAll('#', '')
      .replaceAll('{', '')
      .replaceAll('}', '');
    return cleanText;
  }
};

const WordMorphologies = (wordMorphology: WordMorphology[]) => {
  const [t] = useTranslation();

  const containsNumber = Object.values(wordMorphology).some((item) => !!item.number);

  if (containsNumber) {
    var morphsSeparatedByNumber = groupBy(wordMorphology, 'number');

    return (
      <div className="columns px-4 mt-1">
        {Object.entries(morphsSeparatedByNumber).map(([key, morphsSepByNr]) => {
          return (
            <div key={key} className="column">
              <details>
                <summary className="is-mobile is-clickable is-unselectable has-text-weight-semibold">
                  {t('dictionary.grammaticalAttributes.' + key)}
                </summary>
                <div className="rows mt-3">
                  {morphsSepByNr.map((morphEntry, index) => {
                    return (
                      <div key={morphEntry.id + index} className="row is-size-6">
                        <span className="has-text-weight-semibold">{morphEntry.writtenRep}</span>
                        {' - ' +
                          t('dictionary.grammaticalAttributes.' + morphEntry.definitiveness) +
                          ', ' +
                          t('dictionary.grammaticalAttributes.' + morphEntry.caseRep?.replace('Case', '')) +
                          ', ' +
                          t('dictionary.grammaticalAttributes.' + morphEntry.gender?.replace('Gender', ''))}
                      </div>
                    );
                  })}
                </div>
              </details>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};

const WordDefinitionDivider = (nrOfDefinitions: number, currentDefinition: number, divisionWord: string) => {
  if (nrOfDefinitions - 1 !== currentDefinition) {
    return (
      <div className="divider py-3">
        <span>{divisionWord}</span>
      </div>
    );
  }
};

const DictionaryPartOfSpeechSubResult = ({
  word,
  wordClassDescription,
  nrOfWordClasses,
  dataEntries,
  language,
}: DictionaryPartOfSpeechSubResultProps) => {
  const [t] = useTranslation();

  const leadingText =
    nrOfWordClasses > 1
      ? t('dictionary.wordClassesLeadingText.Multiple.' + dataEntries.partOfSpeech)
      : t('dictionary.wordClassesLeadingText.Single.' + dataEntries.partOfSpeech);

  return (
    <div className="mb-5">
      <div>
        <p className="is-size-4">
          <span className="has-text-weight-semibold">{word} </span>
          <span>{leadingText} </span>
          <span>{t('dictionary.wordClassNames.' + dataEntries.partOfSpeech)}</span>
        </p>
        <p className="mb-4">{wordClassDescription}</p>
        {dataEntries.WordAndDefPkg.length > 0 &&
          dataEntries.WordAndDefPkg.map((pkg, index) => {
            return (
              <div key={pkg.word + index}>
                <div className="word-definition">
                  <ul>
                    {pkg.wordDefinitions.map((wordDefs, wordIndex) => {
                      var defsLength = pkg.wordDefinitions.length;
                      return (
                        <div key={pkg.wordDefinitions[wordIndex].definition}>
                          {<WordDefinitions {...wordDefs} />}
                          {WordDefinitionDivider(defsLength, wordIndex, t('general.or'))}
                        </div>
                      );
                    })}
                  </ul>
                  <div>{language === 'sv' && <WordMorphologies {...pkg.WordMorphologies} />}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default function DictionarySearchResult({ result }: Props) {
  const [t] = useTranslation();
  if (!definableValue(result)) return <div></div>;
  //var rows:any[] = [];
  var separatedData = result.wordDataSplitByPartOfSPeech;
  let nrOfWorldClasses = result.wordDataSplitByPartOfSPeech.length;
  var word = result?.word.charAt(0).toUpperCase() + result?.word.slice(1);
  //let nrOfWorldClasses = result.wordDataSplitByPartOfSPeech.length;
  //var word = result?.word.charAt(0).toUpperCase() + result?.word.slice(1);
  if (result.wordDataSplitByPartOfSPeech.length > 0) {
    return (
      <div className="rows is-centered PageSingleDictionaryEntry single">
        {Object.keys(result.wordDataSplitByPartOfSPeech).map((key, index) => (
          <div key={key + index} className="row mt-2 ">
            <DictionaryPartOfSpeechSubResult
              word={word}
              wordClassDescription={t('dictionary.wordClasses.' + separatedData[index].partOfSpeech)}
              nrOfWordClasses={nrOfWorldClasses}
              dataEntries={separatedData[index]}
            />
          </div>
        ))}
        <SourceCriticism sourceType={result.sourceType as string} />
      </div>
    );
  } else return <PageNoResults />;
}

const DictionaryLanguageEntry = ({ result }: Props) => {
  const [t] = useTranslation();
  var separatedData = result!.wordDataSplitByPartOfSPeech;
  if (!definableValue(result) || separatedData.length === 0) return <div></div>;

  let nrOfWorldClasses = result.wordDataSplitByPartOfSPeech.length;
  var word: string =
    separatedData[0].WordAndDefPkg[0].word.charAt(0).toUpperCase() + separatedData[0].WordAndDefPkg[0].word.slice(1);
  if (result!.wordDataSplitByPartOfSPeech.length > 0) {
    return (
      <div className="rows is-centered">
        {/*eslint-disable-next-line*/}
        {Object.keys(result!.wordDataSplitByPartOfSPeech).map((key, index) => (
          <div key={key + index} className="row mt-2 ">
            <DictionaryPartOfSpeechSubResult
              word={word}
              wordClassDescription={t('dictionary.wordClasses.' + separatedData[index].partOfSpeech)}
              nrOfWordClasses={nrOfWorldClasses}
              dataEntries={separatedData[index]}
              language={result.language}
            />
          </div>
        ))}
      </div>
    );
  }
  return <div></div>;
};

export function MultiLanguageWordSelector({ words, currentWordIndex, setCurrentWordIndex }: any) {
  const [showMultiLanguageWordSelector, setShowMultiLanguageWordSelector] = useState(false);

  const langFlags: any = {
    sv: IconFlagSV,
    no: IconFlagNO,
    en: IconFlagEN,
  };

  const handleMultiLanguageWordClick = (wordIndex: number) => {
    setCurrentWordIndex(wordIndex);
    setShowMultiLanguageWordSelector(false);
  };

  return (
    <div className={`MultiLanguageWordSelector dropdown ${showMultiLanguageWordSelector ? ' is-active' : ''}`}>
      <div className="dropdown-trigger">
        <div
          className="button current-word"
          aria-haspopup="true"
          aria-controls="multiLanguageWordSelector"
          onClick={(e) => {
            e.preventDefault();
            setShowMultiLanguageWordSelector(!showMultiLanguageWordSelector);
          }}
        >
          <img src={langFlags[words[currentWordIndex].language]} alt="" className="icon language" />
          <p className="capitalize-first-letter">{words[currentWordIndex].word}</p>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div className="dropdown-menu" id="multiLanguageWordSelector" role="menu">
        <div className="dropdown-content">
          {words.map((wordData: any, wordIndex: number) => {
            return (
              <Fragment key={`${wordData.language}-${wordData.word}`}>
                <div className="dropdown-item" onClick={() => handleMultiLanguageWordClick(wordIndex)}>
                  <img src={langFlags[wordData.language]} alt="" />
                  <p className="capitalize-first-letter">{wordData.word}</p>
                </div>
                <hr className="dropdown-divider" />
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function DictionaryMultiLanguageSearchResult({ dictionaryMultiLangResult }: MultiLangProps) {
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const currentPage = useCurrentPage();

  if (dictionaryMultiLangResult?.length && dictionaryMultiLangResult?.[0].word) {
    if (isFirstPageLoad) {
      dictionaryMultiLangResult.forEach((wordData, wordIndex) => {
        if (wordData.language === currentPage.searchLang && currentPage.query === wordData.word) {
          setCurrentWordIndex(wordIndex);
        }
      });
      setIsFirstPageLoad(false);
    }
    return (
      <div className="PageSingleDictionaryEntry">
        <MultiLanguageWordSelector
          words={dictionaryMultiLangResult}
          currentWordIndex={currentWordIndex}
          setCurrentWordIndex={setCurrentWordIndex}
        />
        <DictionaryLanguageEntry result={dictionaryMultiLangResult[currentWordIndex]} />
        <SourceCriticism sourceType={dictionaryMultiLangResult[currentWordIndex].sourceType as string} />
      </div>
    );
  } else {
    return <PageNoResults />;
  }
}
