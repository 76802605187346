import { BaseAPI } from '../BaseAPI';
import { definableValue } from '../../utils/definableValue';
import { GNewsAPIMethods } from './GNewsAPIMethods';
import { GNewsResponse } from './models/GNewsResponse';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { convertNewsUrlToQuery } from 'utils/searchHelpers';
import { SourceType } from 'models/enums/SourceType';

export class GNewsAPI extends BaseAPI implements GNewsAPIMethods {
  constructor() {
    super(process.env.REACT_APP_BACKEND_URL!);
  }

  async newsSearch(keyWord: string, language: string): Promise<GNewsResponse[]> {
    const authenticationString = getAuthenticationStringAttachment();
    const { data } = await this.request.get<any>(
      `/api/gnews?keyWord=${keyWord}&authString=${authenticationString}&language=${language}`
    );

    var gnewsEntry: GNewsResponse[] = [];
    if (definableValue(data)) {
      for (let i = 0; i < data.length; i++) {
        gnewsEntry.push(this.createGNewsResponse(data[i]));
      }
    }
    return gnewsEntry!;
  }

  createGNewsResponse = (data: any): GNewsResponse => {
    const newGNewsResponse = {
      title: data.title,
      content: data.content,
      description: data.description,
      articleURL: data.articleURL,
      imageURL: data.imageURL,
      source: data.sourceName,
      sourceType: SourceType.News,
      sourceURL: data.sourceUrl,
      publishDate: data.publishDate,
      sourceCriticism: data.sourceCriticism,
    };
    const storageKey = convertNewsUrlToQuery(data.articleURL as string);
    window.sessionStorage.setItem(storageKey, JSON.stringify(newGNewsResponse));
    return newGNewsResponse;
  };

  async getResult(keyWord: string, language: string = 'sv') {
    const cacheKey = `gnews/${language}/${keyWord}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }
    try {
      const result = await this.newsSearch(keyWord, language);
      window.sessionStorage.setItem(cacheKey, JSON.stringify(result));
      return result;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        clearAuthentication();
        document.location.reload();
      }
    }
  }
}
export const gNewsAPI = new GNewsAPI();
