import create from 'zustand';

interface State {
  loading: boolean;
  setLoading: (loading: boolean) => void;

  examModalRedirected: boolean;
  setExamModalRedirected: (input: boolean) => void;
  quizModalRedirected: boolean;
  setQuizModalRedirected: (input: boolean) => void;
  activeModal: number;
  setActiveModal: (input: number) => void;
  studentDisabilityHelpModalRedirected: boolean;
  setStudentDisabilityHelpModalRedirected: (input: boolean) => void;
  substituteModalRedirected: boolean;
  setSubstituteModalRedirected: (input: boolean) => void;
  instructionRephraseModalRedirected: boolean;
  setInstructionRephraseModalRedirected: (input: boolean) => void;
}

const useStore = create<State>((set) => ({
  loading: false,
  setLoading: (input) => set(() => ({ loading: input })),
  examModalRedirected: false,
  setExamModalRedirected: (input) => set(() => ({ examModalRedirected: input })),
  quizModalRedirected: false,
  setQuizModalRedirected: (input) => set(() => ({ quizModalRedirected: input })),
  activeModal: 0,
  setActiveModal: (input) => set(() => ({ activeModal: input })),
  studentDisabilityHelpModalRedirected: false,
  setStudentDisabilityHelpModalRedirected: (input) => set(() => ({ studentDisabilityHelpModalRedirected: input })),
  substituteModalRedirected: false,
  setSubstituteModalRedirected: (input) => set(() => ({ substituteModalRedirected: input })),
  instructionRephraseModalRedirected: false,
  setInstructionRephraseModalRedirected: (input) => set(() => ({ instructionRephraseModalRedirected: input })),
}));

export default useStore;
