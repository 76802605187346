import React from 'react';
import './SourceCriticism.scss';
import Icon from 'components/app/AppIcon';
import { useTranslation } from 'react-i18next';
import { getSourceCriticismData } from 'utils/searchHelpers';
import { tmpUserConfig } from 'utils/tmpUserConfig';

interface Props {
  sourceType: string;
  source?: string;
  className?: string;
}

export default function SourceCriticism({ sourceType, source = '', className }: Props) {
  const [t] = useTranslation();

  if (!tmpUserConfig.showSourceCriticism) {
    return <div />;
  }

  const sourceCriticismData = getSourceCriticismData(sourceType, source);

  return (
    <div className={`SourceCriticism ${sourceCriticismData.className} ${className}`}>
      <Icon name={sourceCriticismData.iconName} />
      <span className="text">{t(sourceCriticismData.textLongTranslationKey)}</span>
    </div>
  );
}
