//disable eslint for this file
/* eslint-disable */
import { useEffect, useRef } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useChatStore } from '../store/chatStore';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';

export const useWebSocket = (url: string, clientUUID: string) => {
  const {
    setConnectionStatus,
    addResponse,
    addImageResponse,
    getViewLatestQuestionId,
    setCancelViewPrompt,
    setQuestionActiveStatus,
    markLastQuestionProcessed,
    setPrompt,
    activeView,
    messages,
    generateImageMessage,
    cancelViewPrompt,
    prompt,
    imageResponseBuffer,
  } = useChatStore();
  const clientRef = useRef<Client | null>(null);

  useEffect(() => {
    clientRef.current = new Client({
      webSocketFactory: () => new SockJS(url),
      reconnectDelay: 5000,
      debug: (str) => {
        console.log(str);
      },
      onConnect: () => {
        setConnectionStatus('connected');
        clientRef.current?.subscribe('/user/queue/reply', (message) => {
          try {
            if (message.body) {
              if (message.body.includes('data: [DONE]')) {
                const data = message.body.split('!;!');
                if (data.length > 2) {
                  markLastQuestionProcessed(data[2]);
                  setQuestionActiveStatus(data[2], false);
                } else {
                  markLastQuestionProcessed(activeView);
                  setQuestionActiveStatus(activeView, false);
                }
                setPrompt(null);
                console.log('FINISHED PROCESSING YOUR PROMPT');

                return;
              }
              const splitMessage = message.body.split('!;!');
              const questionid = splitMessage[0];
              console.log('message: ', message.body);

              console.log('Question ID: ', questionid);
              if (splitMessage.length < 2 || splitMessage[1].length < 1) {
                console.log('No message body');
                return;
              } else {
                addResponse(splitMessage[2], splitMessage[1].substring(6));
                console.log('Message body: ', splitMessage[1].substring(6));
              }
            }
          } catch (error) {
            console.error('Error parsing message body: ' + error);
          }
        });

        clientRef.current?.subscribe('/user/queue/reply/image', (message) => {
          // Append incoming message to the buffer
          imageResponseBuffer.appendMessage(message.body);

          // Try to parse the buffered message
          const data = imageResponseBuffer.tryParse();

          // Check if data is not null (successfully parsed)
          if (data) {
            addImageResponse('generateImage', JSON.stringify(data));
          }
        });
      },
      onStompError: (frame) => {
        if (frame.headers['message'].includes('Invalid authString')) {
          clearAuthentication();
          document.location.reload();
        }

        setConnectionStatus('error');
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
      },
      onWebSocketClose: (event) => {
        setConnectionStatus('closed');
        console.error('WebSocket connection closed: ', event);
      },
    });

    clientRef.current.connectHeaders = {
      Authorization: `Bearer ${getAuthenticationStringAttachment()}`,
      ClientUUID: clientUUID,
    };
    clientRef.current.activate();

    return () => {
      setQuestionActiveStatus(activeView, false);
      //setCancelViewPrompt(activeView);
      clientRef.current?.deactivate();
    };
  }, [url, setConnectionStatus]);

  useEffect(() => {
    if (prompt != null && clientRef.current?.connected) {
      console.log('PROMPT: ', prompt);

      clientRef.current.publish({
        destination: '/app/chat',
        headers: { authString: prompt.authString, ClientUUID: clientUUID },
        body: JSON.stringify(prompt),
      });
    }
  }, [prompt]);

  useEffect(() => {
    if (generateImageMessage != null && clientRef.current?.connected) {
      console.log('GENERATE IMAGE REQUEST: ', generateImageMessage);

      clientRef.current.publish({
        destination: '/app/image',
        headers: { authString: generateImageMessage.authString, ClientUUID: clientUUID },
        body: JSON.stringify(generateImageMessage),
      });
    }
  }, [generateImageMessage]);

  useEffect(() => {
    const latestQuestionId = getViewLatestQuestionId(activeView);
    if (cancelViewPrompt != null && clientRef.current?.connected && latestQuestionId) {
      console.log('cancelViewPrompt: ', cancelViewPrompt);
      clientRef.current.publish({
        destination: '/app/cancel',
        headers: {
          questionId: latestQuestionId,
          ClientUUID: clientUUID,
          authString: getAuthenticationStringAttachment() || '',
        },
        body: '',
      });
      setCancelViewPrompt(null);
    }
  }, [cancelViewPrompt]);
};
