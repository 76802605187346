import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.scss';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { useTranslation } from 'react-i18next';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { Tooltip } from '@mui/material';

interface FormData {
  assignmentInstructions: string;
  age: string;
}

interface FormErrors {
  assignmentInstructions: boolean;
  age: boolean;
}

export const GetStartedForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ assignmentInstructions: '', age: '' });
  const [errors, setErrors] = useState<FormErrors>({ assignmentInstructions: false, age: false });

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();
  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const validateField = (name: keyof FormData, value: string): boolean => {
    switch (name) {
      case 'assignmentInstructions':
        return !value.trim();
      case 'age':
        return isNaN(Number(value)) || value.trim() === '' || parseInt(value, 10) < 0;
      default:
        return false;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: validateField(name as keyof FormData, value) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors: FormErrors = {
      assignmentInstructions: validateField('assignmentInstructions', formData.assignmentInstructions),
      age: validateField('age', formData.age),
    };

    setErrors(formErrors);

    const isValid =
      !Object.values(formErrors).some(Boolean) &&
      formData.assignmentInstructions.trim() !== '' &&
      formData.age.trim() !== '';

    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      console.log('Submitting Form Data:', formData);
      const prompt = t('chatGpt.helpModal.helpWithGettingStarted.getStartedPrompt', {
        textForAssignmentToGetStartedWith: formData.assignmentInstructions,
        age: formData.age,
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, false, true);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  const isFormValid =
    !errors.assignmentInstructions &&
    !errors.age &&
    formData.assignmentInstructions.trim() !== '' &&
    formData.age.trim() !== '';

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.helpWithAssignment')}</label>
      <Tooltip
        title={t('chatGpt.helpModal.helpWithGettingStarted.pasteTextToGetStarted')}
        placement="top-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <TextField
          label={t('chatGpt.helpModal.helpWithGettingStarted.pasteTextToGetStarted')}
          name="assignmentInstructions"
          variant="outlined"
          value={formData.assignmentInstructions}
          onChange={handleInputChange}
          error={errors.assignmentInstructions}
          fullWidth
          className="form-textarea"
          rows={3}
          multiline
        />
      </Tooltip>

      <Tooltip title={t('chatGpt.helpModal.quiz.age')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.helpModal.quiz.age')}
          name="age"
          type="text"
          variant="outlined"
          value={formData.age}
          onChange={handleInputChange}
          error={errors.age}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : undefined }}
        className="submit-button"
      >
        {t('chatGpt.helpModal.helpWithGettingStarted.submit')}
      </Button>
    </form>
  );
};
