import { BaseAPI } from '../BaseAPI';
import jsonp from 'jsonp';
import axios from 'axios';
import { DictionaryRequest } from './models/DictionaryRequest';
import {
  DictionaryResponseEntry,
  WordMorphology,
  WordDefinition,
  WordDefAndMorphPackage,
  PartOfSpeechDivider,
} from './models/DictionaryResponse';
import { WiktionaryAPIMethods } from './WiktionaryAPIMethods';
import { definableValue } from 'utils/definableValue';
import { LangList } from 'models/enums/LangList';
import { getAuthenticationStringAttachment, clearAuthentication } from 'utils/AuthenticationHelpers';
import { SearchResultSource } from 'models/enums/SearchResultSource';
import { SourceType } from 'models/enums/SourceType';

export class WiktionaryAPI extends BaseAPI implements WiktionaryAPIMethods {
  constructor() {
    super(process.env.REACT_APP_BACKEND_URL!);
  }

  async dictionary({ query, language }: DictionaryRequest): Promise<DictionaryResponseEntry> {
    const authenticationString = getAuthenticationStringAttachment();

    const cacheKey = `wiktionary/${language}/${query}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }
    const { data } = await this.request.get<any>(
      `/api/words?word=${query}&language=${language}&authString=${authenticationString}`
    );

    var responseBundle: DictionaryResponseEntry;
    var partOfSpeechDivider: PartOfSpeechDivider[] = [];
    /*TODO: Handle when data is empty */
    if (definableValue(data)) {
      var multiSearchString: string = '';
      for (let y = 0; y < data.wordDefinitions?.length; y++) {
        var wordDefAndMorphPackages: WordDefAndMorphPackage[] = [];
        for (let i = 0; i < data.wordDefinitions[y].length; i++) {
          var morphologies = data.wordDefinitions[y][i].morphologies;
          var definitions = data.wordDefinitions[y][i].words;
          var word = definitions[0].name;

          wordDefAndMorphPackages.push(createWordDefAndMorphPackage(word, definitions, morphologies));
        }
        partOfSpeechDivider.push(createPartOfSpeechDivder(definitions[0]?.partOfSpeech, wordDefAndMorphPackages));
        for (let x = 0; x < definitions.length; x++) {
          if (
            definitions[x].definition !== null &&
            definitions[x].definition !== undefined &&
            definitions[x].definition !== ''
          ) {
            multiSearchString +=
              definitions[x]?.definition.charAt(0).toUpperCase() +
              definitions[x].definition
                .slice(1)
                ?.split('@')[0]
                .replace(" '''", ' (')
                .replace("''' ", ') ')
                .replace("'''", ') ')
                .replace('"', '') +
              '. ';
          }
          if (
            definitions[x].example !== null &&
            definitions[x].example !== undefined &&
            definitions[x].example !== ''
          ) {
            multiSearchString += definitions[x].example
              ?.split('@')[0]
              .replaceAll('"', '')
              .replaceAll("'''", '')
              .replaceAll('&rarr', '->');
          }
        }
      }
      responseBundle = createDictionaryResponseEntry(
        query,
        partOfSpeechDivider,
        multiSearchString,
        'sv',
        SearchResultSource.Wiktionary,
        data.trustFactor,
        SourceType.Dictionary
      );
    }
    //TODO: Check if responsbundle is empty or null when no word is found
    window.sessionStorage.setItem(cacheKey, JSON.stringify(responseBundle!));
    return responseBundle!;
  }

  async multiLanguageDictionary({ language, query }: DictionaryRequest): Promise<DictionaryResponseEntry[]> {
    const authenticationString = getAuthenticationStringAttachment();
    const cacheKey = `wiktionary/ml/${language}/${query}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }

    const selectedLangs: LangList[] = [];
    selectedLangs.push(LangList.sv);
    selectedLangs.push(LangList.en);
    selectedLangs.push(LangList.no);
    var responseBundle: DictionaryResponseEntry[] = [];

    type DataDictionary = { [key: string]: any };
    var dataDictionary: DataDictionary = {};

    for (let i = 0; i < selectedLangs.length; i++) {
      if (selectedLangs[i] === language) {
        const { data } = await this.request.get<any>(
          '/api/words?word=' + query + '&language=' + language + '&authString=' + authenticationString + '&origin=*'
        );
        if (data.wordDefinitions?.length > 0) {
          dataDictionary[selectedLangs[i]] = data.wordDefinitions;
          const dictionaryResponseEntry = packageSelectedLanguage(
            data.wordDefinitions,
            selectedLangs[i],
            query,
            SearchResultSource.Wiktionary,
            data.trustFactor,
            SourceType.Dictionary
          );
          if (definableValue(dictionaryResponseEntry)) {
            responseBundle.push(dictionaryResponseEntry);
          }
        }
      } else {
        const translationRequestResult = await translate(language!, selectedLangs[i], query);
        const translations = storeTranslations(translationRequestResult);
        for (let y = 0; y < translations.length; y++) {
          let requestLanguage: string = '';
          let requestedWord: string = '';
          requestLanguage = selectedLangs[i];
          requestedWord = translations[y];
          const { data } = await this.request.get<any>(
            '/api/words?word=' +
              requestedWord +
              '&language=' +
              requestLanguage +
              '&authString=' +
              authenticationString +
              '&origin=*'
          );
          if (definableValue(data.wordDefinitions)) {
            if (data.wordDefinitions?.length > 0) {
              let filteredData: any[] = [];
              for (let entry of data.wordDefinitions) {
                entry && filteredData.push(entry);
              }
              if (filteredData.length > 0) {
                const dictionaryResponseEntry = packageSelectedLanguage(
                  filteredData,
                  selectedLangs[i],
                  query,
                  SearchResultSource.Wiktionary,
                  data.trustFactor,
                  SourceType.Dictionary
                );
                if (definableValue(dictionaryResponseEntry)) {
                  responseBundle.push(dictionaryResponseEntry);
                }
              }
            }
          }
        }
      }
    }

    //TODO: Check if responsbundle is empty or null when no word is found
    window.sessionStorage.setItem(cacheKey, JSON.stringify(responseBundle));
    return responseBundle!;
  }

  async getResult(query: string, language: string = 'sv') {
    try {
      return await this.dictionary({ query, language });
    } catch (error: any) {
      if (error?.response?.status === 403) {
        clearAuthentication();
        document.location.reload();
      } else if (error?.response?.status === 401) {
        clearAuthentication();
        document.location.reload();
      }
      console.error(error);
    }
  }

  async getMultiLangResult(query: string, language: string) {
    try {
      return await this.multiLanguageDictionary({ query, language });
    } catch (error: any) {
      if (error?.response?.status === 403) {
        clearAuthentication();
        document.location.reload();
      } else if (error?.response?.status === 401) {
        clearAuthentication();
        document.location.reload();
      }
      console.error(error);
    }
  }
}

const packageSelectedLanguage = (
  languageEntries: any,
  language: string,
  query: string,
  source: string = SearchResultSource.Wiktionary,
  trustFactor: number,
  sourceType: string
): DictionaryResponseEntry | undefined => {
  if (definableValue(languageEntries)) {
    var word: string = '';
    var multiSearchString: string = '';
    var partOfSpeechDivider: PartOfSpeechDivider[] = [];
    for (let y = 0; y < languageEntries.length; y++) {
      var wordDefAndMorphPackages: WordDefAndMorphPackage[] = [];
      if (!languageEntries[y]) {
        continue;
      }
      for (let i = 0; i < languageEntries[y].length; i++) {
        var morphologies = languageEntries[y][i].morphologies;
        var definitions = languageEntries[y][i].words;
        word = definitions[0].name;
        wordDefAndMorphPackages.push(createWordDefAndMorphPackage(word, definitions, morphologies));
      }
      partOfSpeechDivider.push(createPartOfSpeechDivder(definitions[0].partOfSpeech, wordDefAndMorphPackages));
      multiSearchString = definitions[0].definition;
    }
    const response: DictionaryResponseEntry = createDictionaryResponseEntry(
      word,
      partOfSpeechDivider,
      multiSearchString,
      language,
      source,
      trustFactor,
      sourceType
    );
    return response;
  } else return undefined;
};

/*const packageTranslationsOfSelectedLanguage = (
  languageEntries: any,
  language: string,
  query: string
): DictionaryResponseEntry | undefined => {
  if (definableValue(languageEntries)) {
    var multiSearchString: string = '';
    var partOfSpeechDivider: PartOfSpeechDivider[] = [];
    for (let y = 0; y < languageEntries.length; y++) {
      var wordDefAndMorphPackages: WordDefAndMorphPackage[] = [];
      for (let i = 0; i < languageEntries[y].length; i++) {
        for (let x = 0; x < languageEntries[y][i].length; x++) {
          var morphologies = languageEntries[y][i][x].morphologies;
          var definitions = languageEntries[y][i][x].words;
          var word = definitions[0].name;
          wordDefAndMorphPackages.push(createWordDefAndMorphPackage(word, definitions, morphologies));
        }
      }
      partOfSpeechDivider.push(createPartOfSpeechDivder(definitions[0].partOfSpeech, wordDefAndMorphPackages));
      multiSearchString = definitions[0].definition;
    }
    const response: DictionaryResponseEntry = createDictionaryResponseEntry(
      query,
      partOfSpeechDivider,
      multiSearchString,
      language
    );
    return response;
  } else return undefined;
};*/

async function translate(fromLang: string, toLang: string, word: string) {
  const url = `https://${fromLang}.wiktionary.org/w/api.php?action=query&prop=iwlinks&format=json&iwlimit=30&iwprefix=${toLang}&titles=${word}`;
  if ((window as any).Cypress) {
    //? Only for testing, jsonp is not interceptable by Cypress
    const { data } = await axios.get(url);
    return data;
  } else {
    return new Promise((resolve, reject) => {
      jsonp(url, (error, data) => {
        if (error) {
          reject(error.message);
        } else {
          resolve(data);
        }
      });
    });
  }
}

function storeTranslations(data: any): string[] {
  return Object.values(data.query.pages)
    .filter((page: any) => !!page.iwlinks)
    .map((page: any) => page.iwlinks.map((link: any) => link['*'].replace('Special:Search/', '').replace('_', ' ')))
    .flat();
}

function createPartOfSpeechDivder(partOfSpeech: string, wordPkgs: WordDefAndMorphPackage[]): PartOfSpeechDivider {
  let newDivider = {
    partOfSpeech: partOfSpeech,
    WordAndDefPkg: wordPkgs,
  };

  return newDivider;
}

function createDictionaryResponseEntry(
  word: string,
  partOfSpeechDividers: PartOfSpeechDivider[],
  multiSearchPreviewText: string,
  language: string = 'sv',
  source: string = SearchResultSource.Wiktionary,
  trustFactor: number,
  sourceType: string
): DictionaryResponseEntry {
  let newResponseEntry = {
    wordDataSplitByPartOfSPeech: partOfSpeechDividers,
    word: word,
    multiSearchPreviewText: multiSearchPreviewText,
    language: language,
    trustFactor,
    source,
    sourceType,
  };
  return newResponseEntry;
}

function createWordDefAndMorphPackage(
  word: string,
  wordDefArray: WordDefinition[],
  wordMorphologyArr: WordMorphology[]
): WordDefAndMorphPackage {
  let newPackage = {
    word: word,
    wordDefinitions: wordDefArray,
    WordMorphologies: wordMorphologyArr,
  };
  return newPackage;
}

export const wiktionaryAPI = new WiktionaryAPI();
