import React, { Fragment, useEffect, useState } from 'react';
import useStore from 'store';
import { useLocation } from 'wouter';
import AppHeroUkraine from 'components/app/AppHeroUkraine';
import BackButton from 'components/common/BackButton';
import SingleEncyclopediaSearchResult from 'components/pages/PageSingleEncyclopediaEntry';
import SingleNewsEntry from 'components/pages/PageSingleNewsEntry';
import PageSingleSREntry from 'components/pages/PageSingleSREntry';
import PageCategory from 'components/pages/PageCategory';
import PageMultiSearch from 'components/pages/PageMultiSearch';
import SearchSourceTags from 'components/search/SearchSourceTags';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import useCurrentPage from 'hooks/useCurrentPage';
import { DictionarySearchResult } from 'models/DictionarySearchResult';
import DictionaryResultComponent, {
  DictionaryMultiLanguageSearchResult,
} from 'components/pages/PageSingleDictionaryEntry';
import { insummaryAPI } from 'apis/insummary/InsummaryAPI';
import { wiktionaryAPI } from 'apis/wiktionary/WiktionaryAPI';
import { gNewsAPI } from 'apis/gnews/GNewsAPI';
import { srAPI } from 'apis/sr/SRAPI';
import { tmpUserConfig } from 'utils/tmpUserConfig';
import { GNewsResponse } from 'apis/gnews/models/GNewsResponse';
import { SRResponse } from 'apis/sr/models/SRResponse';
import { AppRoutes } from 'models/enums/AppRoutes';
import AppNavigationUkraine from './app/AppNavigationUkraine';
import { convertNewsUrlToQuery } from 'utils/searchHelpers';
import { t } from 'i18next';

export default function Index() {
  const [sourceTagFilters, setSourceTagFilters] = useState<string[]>([]);
  const [singleResult, setSingleResult] = useState<
    EncyclopediaSearchResult | DictionarySearchResult | GNewsResponse | SRResponse | undefined
  >(undefined);
  const [encyclopediaResult, setEncyclopediaResult] = useState<EncyclopediaSearchResult | undefined>(undefined);
  const [dictionaryResult, setDictionaryResult] = useState<DictionarySearchResult | undefined>(undefined);
  const [newsResult, setNewsResult] = useState<GNewsResponse[] | undefined>(undefined);
  const [srResult, setSRResult] = useState<SRResponse[] | undefined>(undefined);
  const [dictionaryMultiLangResult, setDictionaryMultiLangResult] = useState<DictionarySearchResult[] | undefined>(
    undefined
  );
  const [isComingFromMediaSearch, setIsComingFromMediaSearch] = useState<boolean>(false);
  const [, setLocation] = useLocation();
  const setLoading = useStore((state) => state.setLoading);
  const currentPage = useCurrentPage();

  const useDictionaryMultiLangSearch = tmpUserConfig.requestLanguages.length > 1;

  useEffect(() => {
    (async () => {
      setLoading(true);
      resetResults();
      if (currentPage.isUkraineSearch) {
        try {
          const {
            gnewsEntries,
            srEntries,
          } = require(`../apis/ukraine/${currentPage.query}-${currentPage.searchLang}.json`);
          setNewsResult(
            gnewsEntries.map((entry: any) => {
              const newGNewsResponse = gNewsAPI.createGNewsResponse(entry);
              const storageKey = convertNewsUrlToQuery(entry.articleURL as string);
              window.sessionStorage.setItem(storageKey, JSON.stringify(newGNewsResponse));
              return newGNewsResponse;
            })
          );
          setSRResult(
            srEntries.map((entry: any) => {
              const newSRResponse = srAPI.createSRResponse(entry);
              const storageKey = entry.episodeId;
              window.sessionStorage.setItem(storageKey, JSON.stringify(newSRResponse));
              return newSRResponse;
            })
          );
        } catch (error) {
          console.log(error);
        }
      } else if (currentPage.isMultiSearch) {
        await Promise.all([encyclopediaMultiSearch(), dictionaryMultiSearch(), newsMultiSearch(), SRMultiSearch()]);
      } else if (currentPage.isMediaSearch) {
        await Promise.all([newsMultiSearch(), SRMultiSearch()]);
      } else if (currentPage.isEncyclopediaSingle) {
        setSingleResult(await insummaryAPI.getResult(currentPage.query));
      } else if (currentPage.isDictionarySingle) {
        if (useDictionaryMultiLangSearch) {
          setDictionaryMultiLangResult(
            await wiktionaryAPI.getMultiLangResult(currentPage.query, currentPage.searchLang)
          );
        } else {
          setSingleResult(await wiktionaryAPI.getResult(currentPage.query, currentPage.searchLang));
        }
      } else if (currentPage.isNewsSingle || currentPage.isSingleUkraineNewsEntry) {
        const data = window.sessionStorage.getItem(currentPage.rawQuery);
        if (data) {
          setSingleResult(JSON.parse(data));
        } else {
          setLocation(AppRoutes.START);
        }
      } else if (currentPage.isSRSingle || currentPage.isSingleUkraineSREntry) {
        const data = window.sessionStorage.getItem(currentPage.rawQuery);
        if (data) {
          setSingleResult(JSON.parse(data));
        } else {
          setLocation(AppRoutes.START);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.query, currentPage.searchLang, currentPage.isMultiSearch, currentPage.isSingleEntry]);

  useEffect(() => {
    if (
      currentPage.isStart ||
      currentPage.isEncyclopediaStart ||
      currentPage.isDictionaryStart ||
      currentPage.isMediaStart
    ) {
      resetResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleResult, encyclopediaResult, dictionaryResult, dictionaryMultiLangResult]);

  useEffect(() => {
    if (currentPage.isMediaStart || currentPage.isMediaSearch) {
      setIsComingFromMediaSearch(true);
    } else if (!currentPage.isNewsSingle && !currentPage.isSRSingle) {
      setIsComingFromMediaSearch(false);
    }
  }, [currentPage.isMediaStart, currentPage.isMediaSearch, currentPage.isNewsSingle, currentPage.isSRSingle]);

  const resetResults = () => {
    setSingleResult(undefined);
    setEncyclopediaResult(undefined);
    setDictionaryResult(undefined);
    setDictionaryMultiLangResult(undefined);
    setNewsResult(undefined);
    setSRResult(undefined);
  };

  const encyclopediaMultiSearch = async () => {
    setEncyclopediaResult(await insummaryAPI.getResult(currentPage.query, currentPage.searchLang));
  };

  const dictionaryMultiSearch = async () => {
    const promises = [wiktionaryAPI.getResult(currentPage.query, currentPage.searchLang)];
    if (useDictionaryMultiLangSearch) {
      promises.push(wiktionaryAPI.getMultiLangResult(currentPage.query, currentPage.searchLang) as any);
    }

    const results = await Promise.all(promises);

    setDictionaryResult(results[0]);
    if (results[1]) {
      setDictionaryMultiLangResult(results[1] as any);
    }
  };

  const newsMultiSearch = async () => {
    setNewsResult(await gNewsAPI.getResult(currentPage.query, currentPage.searchLang));
  };

  const SRMultiSearch = async () => {
    setSRResult(await srAPI.getResult(currentPage.query, currentPage.searchLang));
  };

  const SingleEntry = () => {
    return (
      <section className="section pt-0">
        <div className="container">
          <BackButton />

          {currentPage.isSingleUkraineNewsEntry && <SingleNewsEntry result={singleResult as GNewsResponse} />}
          {currentPage.isSingleUkraineSREntry && (
            <PageSingleSREntry
              result={singleResult as SRResponse}
              activeAudioSrc={null}
              activeAudioisPlaying={false}
              setActiveAudioSrc={() => {}}
              setActiveAudioIsPlaying={() => {}}
              setActiveAudioMeta={() => {}}
            />
          )}

          {currentPage.isEncyclopediaSingle && (
            <SingleEncyclopediaSearchResult result={singleResult as EncyclopediaSearchResult} />
          )}
          {currentPage.isNewsSingle && <SingleNewsEntry result={singleResult as GNewsResponse} />}
          {currentPage.isSRSingle && (
            <PageSingleSREntry
              result={singleResult as SRResponse}
              activeAudioSrc={null}
              activeAudioisPlaying={false}
              setActiveAudioSrc={() => {}}
              setActiveAudioIsPlaying={() => {}}
              setActiveAudioMeta={() => {}}
            />
          )}
          {currentPage.isDictionarySingle && singleResult && (
            <DictionaryResultComponent result={singleResult as DictionarySearchResult} />
          )}
          {currentPage.isDictionarySingle && dictionaryMultiLangResult && (
            <DictionaryMultiLanguageSearchResult dictionaryMultiLangResult={dictionaryMultiLangResult} />
          )}
        </div>
      </section>
    );
  };

  const checkIfOnBothSides = () => {
    const currUrl = window.location.href;
    let isOnBothSides = currUrl.includes('bothSides');
    return isOnBothSides;
  };

  const PageContent = () => {
    return (
      <Fragment>
        {currentPage.isSingleEntry && <SingleEntry />}
        {currentPage.isUkraineStart && <PageCategory />}
        {currentPage.isUkraineSearch && checkIfOnBothSides() && (
          <div className="has-text-centered title is-size-4	">
            <p>{t('ukraine.bothSidesHeadsUpText')}</p>
          </div>
        )}
        {currentPage.isUkraineSearch && (
          <PageMultiSearch
            encyclopediaResult={encyclopediaResult}
            encyclopediaSuggestionResult={[]}
            gnewsResult={newsResult}
            srResult={srResult}
            snlResult={[]}
            dictionaryResult={dictionaryResult}
            singleResult={singleResult}
            sourceTagFilters={sourceTagFilters}
            activeAudioSrc={null}
            activeAudioisPlaying={false}
            setActiveAudioSrc={() => {}}
            setActiveAudioIsPlaying={() => {}}
            setActiveAudioMeta={() => {}}
          />
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <AppNavigationUkraine />
      <AppHeroUkraine isComingFromMediaSearch={isComingFromMediaSearch} />
      <SearchSourceTags
        encyclopediaResult={encyclopediaResult}
        gnewsResult={newsResult}
        srResult={srResult}
        snlResult={[]}
        dictionaryResult={dictionaryResult}
        singleResult={singleResult}
        dictionaryMultiLangResult={dictionaryMultiLangResult}
        sourceTagFilters={sourceTagFilters}
        setSourceTagFilters={setSourceTagFilters}
      />
      <PageContent />
    </Fragment>
  );
}
