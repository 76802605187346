import React, { useEffect, useState } from 'react';
import './AppNavigation.scss';
import { useTranslation } from 'react-i18next';
import IconHome from 'assets/icons/home.png';
import IconFlagSV from 'assets/icons/flag-sv.png';
import IconFlagNO from 'assets/icons/flag-no.png';
import IconFlagEN from 'assets/icons/flag-en.png';
import LogoSkolup from 'assets/icons/skolup.png';
import { Link } from 'wouter';
import { AppRoutes } from 'models/enums/AppRoutes';
import useSessionLanguage from 'hooks/useSessionLanguage';
import useCurrentPage from 'hooks/useCurrentPage';

export default function AppNavigation() {
  const { t, i18n } = useTranslation();
  const [showLangMenu, setShowLangMenu] = useState(false);
  const currentPage = useCurrentPage();
  useSessionLanguage();

  useEffect(() => {
    (window as any).SkolonMenuButton?.render();
  });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.sessionStorage.setItem('lang', lang);
    setShowLangMenu(false);
  };

  const langFlags: any = {
    sv: IconFlagSV,
    no: IconFlagNO,
    en: IconFlagEN,
  };

  const langMenuButtons = Object.keys(i18n.services.resourceStore.data).map((lang) => {
    if (lang === i18n.language) {
      return undefined;
    }
    return (
      <button
        key={lang}
        className="button icon-button"
        title={t('navigation.language')}
        onClick={() => changeLanguage(lang)}
      >
        <img src={langFlags[lang]} alt={t('navigation.language')} className="flag" />
      </button>
    );
  });

  return (
    <nav className="AppNavigation navbar is-transparent" role="navigation" aria-label="main navigation">
      <div className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            <div className="buttons">
              <Link href={AppRoutes.UKRAINE_START}>
                <span
                  className={'button icon-button home mr-4' + (currentPage.isUkraineStart ? ' is-hidden' : '')}
                  title={t('navigation.home')}
                  role="link"
                >
                  <img src={IconHome} alt={t('navigation.home')} className="light" />
                </span>
              </Link>
            </div>
          </div>
          <div className="navbar-item logo mr-4">
            <img src={LogoSkolup} alt="Skolup" />
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <div className="is-relative mr-3">
                <div className={`language-menu ${showLangMenu ? 'show' : ''}`}>{langMenuButtons}</div>
                <button
                  className="button icon-button"
                  title={t('navigation.language')}
                  onClick={() => setShowLangMenu(!showLangMenu)}
                >
                  <img src={langFlags[i18n.language]} alt={t('navigation.language')} className="flag" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
