import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useSessionLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const sessionLanguage = window.sessionStorage.getItem('lang');
    if (sessionLanguage && sessionLanguage !== i18n.language) {
      i18n.changeLanguage(sessionLanguage);
    }
  };

  useEffect(() => {
    changeLanguage();
    setTimeout(() => {
      changeLanguage();
    }, 250);
  });
};

export default useSessionLanguage;
