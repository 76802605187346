import React from 'react';
import { useTranslation } from 'react-i18next';
import './InfoModal.scss';
interface InformationModalProps {
  isActive: boolean;
  closeModal: () => void;
}

const InformationModal = ({ isActive, closeModal }: InformationModalProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card ai-tips">
        <header className="modal-card-head">
          <strong className="label">{t('chatGpt.infoModal.intructionHeader')}</strong>
        </header>
        <section className="modal-card-body">
          <p>{t('chatGpt.infoModal.p1')}</p>
          <br />
          <p>{t('chatGpt.infoModal.p2.1')}</p>
          <p>
            {t('chatGpt.infoModal.p2.2.1')}
            <i>{t('chatGpt.infoModal.p2.2.2')}</i>
          </p>
          <p>
            {t('chatGpt.infoModal.p2.3.1')}
            <i>{t('chatGpt.infoModal.p2.3.2')}</i>
          </p>
          <br />
          <p>
            <strong>{t('chatGpt.infoModal.p3')}</strong>
          </p>
          <p>{t('chatGpt.infoModal.p4')}</p>
          <br />
          <p>{t('chatGpt.infoModal.p5')}</p>
          <br />
          <p>
            <i>{t('chatGpt.infoModal.p6')}</i>
          </p>
          <br />
          <p>{t('chatGpt.infoModal.p7')}</p>
          <br />
          {i18n.language === 'sv' && (
            <>
              <p>{t('chatGpt.infoModal.p8')}</p>
              <br />
              <p>
                <a
                  href="https://www.skolverket.se/skolutveckling/inspiration-och-stod-i-arbetet/stod-i-arbetet/rad-om-ai-chat-gpt-och-liknande-verktyg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.skolverket.se/skolutveckling/inspiration-och-stod-i-arbetet/stod-i-arbetet/rad-om-ai-chat-gpt-och-liknande-verktyg
                </a>
              </p>
            </>
          )}
        </section>
        <button className="button" onClick={closeModal}>
          {t('chatGpt.infoModal.closeModal')}
        </button>
      </div>
    </div>
  );
};

export default InformationModal;
