import { Sources, SourceType } from 'models/enums/SourceType';
import LogoWikipedia from 'assets/logos/wikipedia.png';
import LogoWiktionary from 'assets/logos/wiktionary.png';
import LogoGP from 'assets/logos/gp.png';
import LogoSVD from 'assets/logos/svd.png';
import LogoDN from 'assets/logos/dn.png';
import LogoHN from 'assets/logos/hn.png';
import LogoHP from 'assets/logos/hp.png';
import LogoNWT from 'assets/logos/nwt.png';
import LogoSVT from 'assets/logos/svt.png';
import LogoSR from 'assets/logos/sr.png';
import LogoFZ from 'assets/logos/fz.png';
import LogoSNL from 'assets/logos/snl.png';
import LogoAftonbladet from 'assets/logos/aftonbladet.png';
import LogoExpressen from 'assets/logos/expressen.png';
import LogoSmalandsposten from 'assets/logos/smalandsposten.png';
import LogoSydsvenskan from 'assets/logos/sydsvenskan.png';

export function convertNewsUrlToQuery(url: string = '') {
  return encodeURIComponent(url.replace(/^https?:\/\//, '').replace('www.', ''));
}

export function convertSNLArticleUrlJsonToQuery(articleUrlJson: string = '') {
  return articleUrlJson.replace('https://snl.no/', '').replace('.json', '');
}

export function convertSNLQueryToArticleUrlJson(term: string = '') {
  return `https://snl.no/${term}.json`;
}

export const allowedSNLImageLicenses = ['fri', 'Falt i det fri (Public domain)', 'CC BY 2.0', 'CC BY SA 3.0'];

export function getLogoFromSource(source: string = '') {
  if (source === 'Wikipedia') {
    return LogoWikipedia;
  } else if (source === 'Wiktionary') {
    return LogoWiktionary;
  } else if (source === 'SNL') {
    return LogoSNL;
  } else if (source === 'Göteborgs-Posten') {
    return LogoGP;
  } else if (source === 'Dagens Nyheter') {
    return LogoDN;
  } else if (source === 'Svenska Dagbladet') {
    return LogoSVD;
  } else if (source === 'FZ.se') {
    return LogoFZ;
  } else if (source === 'Sveriges Television') {
    return LogoSVT;
  } else if (source === 'Sveriges Radio') {
    return LogoSR;
  } else if (source === 'Aftonbladet') {
    return LogoAftonbladet;
  } else if (source === 'Expressen') {
    return LogoExpressen;
  } else if (source === 'Hallandsposten') {
    return LogoHP;
  } else if (source === 'Hallands Nyheter') {
    return LogoHN;
  } else if (source === 'Smålandsposten') {
    return LogoSmalandsposten;
  } else if (source === 'Sydsvenskan') {
    return LogoSydsvenskan;
  } else if (source === 'nwt.se') {
    return LogoNWT;
  } else {
    return '';
  }
}

export function getSourceCriticismData(sourceType: string, source: string = '') {
  let iconName = 'info';
  let className = 'source-criticism-news';
  let textShortTranslationKey = 'sourceCriticism.news.short';
  let textLongTranslationKey = 'sourceCriticism.news.long';

  if (sourceType === SourceType.SverigesRadio) {
    className = 'source-criticism-sveriges-radio';
    textShortTranslationKey = 'sourceCriticism.sverigesRadio.short';
    textLongTranslationKey = 'sourceCriticism.sverigesRadio.long';
  } else if (sourceType === SourceType.Encyclopedia) {
    className = 'source-criticism-encyclopedia';
    textShortTranslationKey = 'sourceCriticism.encyclopedia.short';
    textLongTranslationKey = 'sourceCriticism.encyclopedia.long';
  } else if (sourceType === SourceType.SNL) {
    className = 'source-criticism-snl';
    textShortTranslationKey = 'sourceCriticism.snl.short';
    textLongTranslationKey = 'sourceCriticism.snl.long';
  } else if (sourceType === SourceType.Dictionary) {
    className = 'source-criticism-dictionary';
    textShortTranslationKey = 'sourceCriticism.dictionary.short';
    textLongTranslationKey = 'sourceCriticism.dictionary.long';
  } else if (sourceType === SourceType.News && source === Sources.RT) {
    className = 'source-criticism-russian-news';
    textShortTranslationKey = 'sourceCriticism.newsRussia.short';
    textLongTranslationKey = 'sourceCriticism.newsRussia.long';
  } else if (sourceType === SourceType.News && source === Sources.SVT) {
    className = 'source-criticism-sveriges-television';
    textShortTranslationKey = 'sourceCriticism.sverigesTelevision.short';
    textLongTranslationKey = 'sourceCriticism.sverigesTelevision.long';
  }

  return { iconName, className, textShortTranslationKey, textLongTranslationKey };
}
