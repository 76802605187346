import { useRoute } from 'wouter';
import { AppRoutes } from 'models/enums/AppRoutes';

const useCurrentPage = () => {
  const [isStart] = useRoute(AppRoutes.START);
  const [isMultiSearch, multiSearchParams] = useRoute(AppRoutes.MULTI_SEARCH);
  const [isMediaStart] = useRoute(AppRoutes.MEDIA_START);
  const [isMediaSearch, mediaSearchParams] = useRoute(AppRoutes.MEDIA_SEARCH);
  const [isEncyclopediaStart] = useRoute(AppRoutes.ENCYCLOPEDIA_START);
  const [isEncyclopediaSearch, encyclopediaSearchParams] = useRoute(AppRoutes.ENCYCLOPEDIA_SEARCH);
  const [isEncyclopediaSingle, encyclopediaSingleParams] = useRoute(AppRoutes.ENCYCLOPEDIA_SINGLE);
  const [isDictionaryStart] = useRoute(AppRoutes.DICTIONARY_START);
  const [isDictionarySingle, dictionarySingleParams] = useRoute(AppRoutes.DICTIONARY_SINGLE);
  const [isNewsSingle, newsSingleParams] = useRoute(AppRoutes.NEWS_SINGLE);
  const [isSRSingle, srSingleParams] = useRoute(AppRoutes.SR_SINGLE);
  const [isUkraineStart] = useRoute(AppRoutes.UKRAINE_START);
  const [isUkraineSearch, ukraineSearchParams] = useRoute(AppRoutes.UKRAINE_SEARCH);
  const [isSingleUkraineNewsEntry, ukraineNewsParams] = useRoute(AppRoutes.UKRAINE_NEWS_SINGLE);
  const [isSingleUkraineSREntry, ukraineSrParams] = useRoute(AppRoutes.UKRAINE_SR_SINGLE);
  const [ischatGpt] = useRoute(AppRoutes.ASK_JUNIE);
  const [isInfoAboutChatGpt] = useRoute(AppRoutes.INFO_CHATGPT);

  const rawQuery =
    (multiSearchParams?.query as string) ||
    (mediaSearchParams?.query as string) ||
    (encyclopediaSearchParams?.query as string) ||
    (encyclopediaSingleParams?.query as string) ||
    (dictionarySingleParams?.query as string) ||
    (newsSingleParams?.query as string) ||
    (srSingleParams?.query as string) ||
    (ukraineSearchParams?.query as string) ||
    (ukraineNewsParams?.query as string) ||
    (ukraineSrParams?.query as string);

  const query = rawQuery ? decodeURIComponent(rawQuery).toLocaleLowerCase() : rawQuery;

  const searchLang =
    (multiSearchParams?.searchLang as string) ||
    (mediaSearchParams?.searchLang as string) ||
    (encyclopediaSearchParams?.searchLang as string) ||
    (encyclopediaSingleParams?.searchLang as string) ||
    (dictionarySingleParams?.searchLang as string) ||
    (ukraineSearchParams?.searchLang as string);

  const isSingleEntry =
    isEncyclopediaSingle ||
    isDictionarySingle ||
    isNewsSingle ||
    isSRSingle ||
    isSingleUkraineNewsEntry ||
    isSingleUkraineSREntry;

  const isSingleSNLPage = isEncyclopediaSingle && searchLang === 'no';

  const is404 =
    !isSingleEntry &&
    !isStart &&
    !isMultiSearch &&
    !isMediaStart &&
    !isMediaSearch &&
    !isDictionaryStart &&
    !isUkraineStart &&
    !isUkraineSearch &&
    !ischatGpt &&
    !isInfoAboutChatGpt;

  return {
    query,
    rawQuery,
    searchLang,
    is404,
    isStart,
    isMultiSearch,
    isMediaSearch,
    isMediaStart,
    isEncyclopediaStart,
    isEncyclopediaSearch,
    isEncyclopediaSingle,
    isDictionaryStart,
    isDictionarySingle,
    isNewsSingle,
    isSRSingle,
    isSingleEntry,
    isUkraineStart,
    isUkraineSearch,
    isSingleUkraineNewsEntry,
    isSingleUkraineSREntry,
    isSingleSNLPage,
    ischatGpt,
    isInfoAboutChatGpt,
  };
};

export default useCurrentPage;
