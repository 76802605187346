import { useChatStore } from 'store/chatStore';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { AuthData } from 'models/enums/AuthData';
import { QAItem } from 'models/AIChatTypes';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import ConfigSidebar from '../ConfigSidebar';
import ChatWindow from '../ChatWindow';
import { InputField } from '../InputField';
import './Chat.scss';
import FileUploadWindow, { ImageMessage, ImageURL, TextMessage } from '../fileUpload/FileUploadWindow';
import { GPTModels } from '../GPTModelSelector';
import { DEFAULT_VISION_PROMT } from 'config';

export interface SendImageToGPTParams {
  sendImage: boolean;
  encodedImages: Array<string>;
  uploadedImages: File[] | null;
  selectedGPTModel: GPTModels;
  addMessage: (view: string, qaItem: QAItem) => void;
  activeView: string;
  setSendImage: React.Dispatch<React.SetStateAction<boolean>>;
  setFileUploadActive: React.Dispatch<React.SetStateAction<boolean>>;
  GPTPromt: string;
  stepByStepPrompt: string;
}

export const sendImageToGPT = (params: SendImageToGPTParams) => {
  if (params.sendImage) {
    let taskDescription: TextMessage = { type: 'text', text: params.GPTPromt || DEFAULT_VISION_PROMT };
    let isStepByStep: boolean = false;
    if (taskDescription.text === params.stepByStepPrompt) {
      isStepByStep = true;
    }

    let extension: string | undefined = 'jpeg';
    if (params.uploadedImages != null) {
      extension = params.uploadedImages[0].name.split('.').pop();
    }

    if (extension == null) {
      extension = 'jpeg';
    }

    let url: ImageURL = { url: 'data:image/' + extension + ';base64,' + params.encodedImages[0] };
    let image: ImageMessage = { type: 'image_url', image_url: url };

    let imageMessagesArr: Array<ImageMessage | TextMessage> = [taskDescription, image];

    let qaItem = generateQAItem(imageMessagesArr, params.selectedGPTModel.model);
    qaItem.ignoreRenderInChat = false;
    qaItem.isStepByStep = isStepByStep;

    params.addMessage(params.activeView, qaItem);
    params.setSendImage(false);
    params.setFileUploadActive(false);
  }
};

export const Chat = () => {
  const [t] = useTranslation();
  const { i18n } = useTranslation();

  const {
    setPrompt,
    setQuestionActiveStatus,
    setSelectedLanguage,
    setSelectedFrequencyPenalty,
    setSelectedGPTModel,
    setSelectedPresencePenalty,
    setSelectedTemperature,
    addMessage,
    messages,
    activeView,
    questionActiveStatus,
    selectedFrequencyPenalty,
    selectedTemperature,
    selectedPresencePenalty,
    selectedLanguage,
    selectedGPTModel,
  } = useChatStore();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const errorId = 'ERROR_ID';

  const [userInput, setUserInput] = useState('');
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<QAItem[]>([]);
  const [toggleSetErrorMessage, setToggleSetErrorMessage] = useState<boolean>(false);
  const [toggleClearErrorMessage, setToggleClearErrorMessage] = useState<boolean>(false);
  const [isCancelling] = useState<boolean>(false);
  const [configSidebarActive, setConfigSidebarActive] = useState<boolean>(false);
  const [fileUploadActive, setFileUploadActive] = useState<boolean>(false);
  const [encodedImages, setEncodedImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[] | null>(null);
  const [sendImage, setSendImage] = useState<boolean>(false);
  const [GPTPromt, setGPTPromt] = useState<string>(DEFAULT_VISION_PROMT);
  const [lang, setLang] = useState('');
  const [voiceURI, setVoiceURI] = useState('');

  const languageMap: { [key: string]: string } = {
    en: 'en-US',
    sv: 'sv-SE',
    no: 'nb-NO',
    da: 'da-DK',
  };

  useEffect(() => {
    setLang(languageMap[i18n.language] || i18n.language);
  }, [i18n.language]);

  const userType = useRef<string | null>(localStorage.getItem(AuthData.USER_TYPE));

  const setErrorMessage = () => {
    const newItem: QAItem = {
      id: errorId,
      question: '',
      answer: t('chatGpt.errorMessage'),
      ignoreRenderInChat: false,
    };

    const errorExists = questionsAndAnswers.find((item) => item.id === errorId);
    if (errorExists) {
      return;
    }

    setQuestionsAndAnswers((prevState) => [...prevState, newItem]);
  };

  const clearErrorMessage = () => {
    const filteredQuestionsAndAnswers = questionsAndAnswers.filter((item) => item.id !== errorId);
    setQuestionsAndAnswers(filteredQuestionsAndAnswers);
  };

  useEffect(() => {
    if (toggleClearErrorMessage) {
      clearErrorMessage();
      setToggleClearErrorMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleClearErrorMessage]);

  useEffect(() => {
    if (toggleSetErrorMessage) {
      if (isCancelling) {
        return;
      }
      setErrorMessage();
      setToggleSetErrorMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleSetErrorMessage]);

  useEffect(() => {
    const nrOfMessages = messages[activeView]?.length || 0;
    if (nrOfMessages === 0) return;
    const lastMessage = messages[activeView][nrOfMessages - 1];
    if (!questionActiveStatus[activeView] && !lastMessage.questionProcessed) {
      setQuestionActiveStatus(activeView, true);
      const payload: any = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        }
      );

      payload.authString = getAuthenticationStringAttachment() || '';

      setPrompt(payload);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages[activeView]]);

  const resetConfigurations = () => {
    setSelectedFrequencyPenalty(0);
    setSelectedTemperature(1);
    setSelectedPresencePenalty(0);
    setSelectedLanguage(undefined);
  };

  let showFileUploadButton: boolean = selectedGPTModel.vision;

  useEffect(() => {
    showFileUploadButton = selectedGPTModel.vision;
  }, [selectedGPTModel]);

  let params: SendImageToGPTParams = {
    sendImage,
    activeView,
    addMessage,
    encodedImages,
    uploadedImages,
    selectedGPTModel,
    setSendImage,
    setFileUploadActive,
    GPTPromt,
    stepByStepPrompt: t('chatGpt.stepByStepPrompt'),
  };

  useEffect(() => {
    sendImageToGPT(params);
  }, [sendImage]);

  return (
    <div className="content-container">
      <ConfigSidebar
        isActive={configSidebarActive}
        setIsActive={setConfigSidebarActive}
        temperature={selectedTemperature}
        setTemperature={setSelectedTemperature}
        frequencyPenalty={selectedFrequencyPenalty}
        setFrequencyPenalty={setSelectedFrequencyPenalty}
        presencePenalty={selectedPresencePenalty}
        setPresencePenalty={setSelectedPresencePenalty}
        userType={userType.current}
        selectedModel={selectedGPTModel}
        setSelectedModel={setSelectedGPTModel}
        resetConfig={resetConfigurations}
        setVoiceURI={setVoiceURI}
        lang={lang}
      />
      {showFileUploadButton ? (
        <FileUploadWindow
          isActive={fileUploadActive}
          setIsActive={setFileUploadActive}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          setEncodedImages={setEncodedImages}
          setSendImage={setSendImage}
          setGPTPromt={setGPTPromt}
        />
      ) : null}
      <div className="chat-box">
        <div id="scroll" className="conversation">
          <ChatWindow voiceURI={voiceURI} lang={lang} />
        </div>
        <div className="input-field">
          <InputField
            setConfigSidebarActive={setConfigSidebarActive}
            setFileUploadActive={setFileUploadActive}
            setUploadedImages={setUploadedImages}
            setEncodedImages={setEncodedImages}
            isProcessingQuestion={questionActiveStatus[activeView]}
            setInput={setUserInput}
            userInput={userInput}
            userType={userType.current}
            showFileUploadButton={showFileUploadButton}
          />
        </div>
      </div>
    </div>
  );
};
