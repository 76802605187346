import axios from 'axios';
import { AppRoutes } from 'models/enums/AppRoutes';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';
import * as Auth from 'utils/AuthenticationHelpers';
import IconSkolon from 'assets/icons/skolon-white.png';
import IconFlagSV from 'assets/icons/flag-sv.png';
import IconFlagNO from 'assets/icons/flag-no.png';
import IconFlagEN from 'assets/icons/flag-en.png';
import IconFlagDK from 'assets/icons/flag-dk.png';
import LogoSkolup from 'assets/icons/skolup.png';
import useSessionLanguage from 'hooks/useSessionLanguage';
import './PageLogin.scss';
interface AuthProps {
  setIsAuthenticated: (data: boolean) => void;
  isAuthenticated: boolean;
}

export default function PageLogin({ setIsAuthenticated, isAuthenticated }: AuthProps) {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const [loginError, setLoginError] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isSSO, setIsSSO] = useState<boolean>(false);
  const ssoLogin = () => {
    Auth.setHasRedirectedForAuthentication(1);
    setLocation(AppRoutes.SSO);
  };
  useEffect(() => {
    const currUrl = window.location.href;
    let ssoCodeExists = currUrl.includes('code=');
    if (ssoCodeExists) {
      Auth.setHasRedirectedForAuthentication(1);
      setIsSSO(true);
    }
  }, [setIsSSO]);
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.sessionStorage.setItem('lang', lang);
  };
  useEffect(() => {
    const getSkolonToken = async (ssoCode: string): Promise<any> => {
      try {
        const { data } = await axios.get<any>(process.env.REACT_APP_BACKEND_URL! + '/api/skolonToken?code=' + ssoCode);
        return new Promise(() => {
          if (!data.error) {
            const language = data.language.split('-')[0];
            Auth.setHasRedirectedForAuthentication(0);
            setToken(data.accessToken);
            setLoginError(false);
            setIsAuthenticated(true);
            Auth.storeUserAuthentication(
              data.accessToken,
              data.firstName,
              data.lastname,
              data.email,
              data.userId,
              data.language,
              data.userType
            );
            i18n.changeLanguage(language);
            window.sessionStorage.setItem('lang', language);
          } else if (data.error) {
            setLoginError(true);
            setIsSSO(false);
            Auth.setHasRedirectedForAuthentication(0);
            Auth.clearAuthentication();
          }
        });
      } catch (error: any) {
        if (error?.response?.status === 403) {
          console.log('Unauthoried');
          Auth.setHasRedirectedForAuthentication(0);
          Auth.clearAuthentication();
          setLoginError(true);
          setIsSSO(false);
        }
      }
    };

    const checkIfAlreadyAuthed = () => {
      const alreadyAuthed = Auth.isUserAlreadyAuthenticated();
      if (alreadyAuthed) {
        Auth.setHasRedirectedForAuthentication(0);
        setIsAuthenticated(true);
      }
    };
    const currUrl = window.location.href;
    let ssoCodeExists = currUrl.includes('code=');
    if (Auth.getHasRedirectedForAuthentication()) {
      if (ssoCodeExists) {
        const ssoCode = currUrl.split('code=')[1];
        getSkolonToken(ssoCode);
      }
    }
    checkIfAlreadyAuthed();
    isAuthenticated && token && setLocation(AppRoutes.START);
  }, [isAuthenticated, token, setLocation, setIsAuthenticated, i18n]);

  const langFlags: any = {
    sv: IconFlagSV,
    no: IconFlagNO,
    en: IconFlagEN,
    da: IconFlagDK,
  };

  const loginErrorMessage = () => {
    return (
      <div className="box login-error">
        <button
          onClick={() => {
            setLoginError(false);
          }}
          className="delete is-small close-login-error"
        ></button>
        <p className="text">{t('login.loginFailed')}</p>
      </div>
    );
  };

  const langButtons = Object.keys(i18n.services.resourceStore.data).map((lang) => {
    if (lang === i18n.language) {
      return undefined;
    }
    return (
      <span key={lang} title={lang} onClick={() => changeLanguage(lang)}>
        <img src={langFlags[lang]} alt="" className="flag" data-cy={`login-lang-${lang}`} />
      </span>
    );
  });

  useSessionLanguage();

  const loginButtonClasses = () => {
    let classes = 'button is-skolon is-medium is-rounded';

    if (Auth.getHasRedirectedForAuthentication() || isSSO) {
      classes += ' is-loading';
    }
    return classes;
  };
  return (
    <div className="PageLogin is-flex is-align-items-center is-justify-content-center">
      <div className="has-text-centered">
        <div className="is-flex is-align-items-center is-justify-content-center">
          <h1 className="is-size-1 is-size-3-mobile mb-5">{t('login.welcome')}</h1>
          <img src={LogoSkolup} alt="Skolup" className="logo" />
        </div>
        <button
          className={loginButtonClasses()}
          title={`${t('login.loginWith')} Skolon`}
          onClick={() => ssoLogin()}
          data-cy="login-button"
        >
          <span className="icon mr-3">
            <img src={IconSkolon} alt="Skolon" />
          </span>
          <span className="text">
            {t('login.loginWith')} <strong>Skolon</strong>
          </span>
        </button>

        {loginError && <div className="pt-5">{loginErrorMessage()}</div>}

        <div>{langButtons}</div>
      </div>
    </div>
  );
}
