import { useEffect } from 'react';
import useCurrentPage from 'hooks/useCurrentPage';
import { useTranslation } from 'react-i18next';

const usePageMeta = () => {
  const { t } = useTranslation();
  const currentPage = useCurrentPage();

  useEffect(() => {
    let title = 'Skolup';

    if (currentPage.isMultiSearch) {
      title += ` - ${t('meta.titles.multiSearch')}`;
    } else if (currentPage.isMediaSearch || currentPage.isMediaStart) {
      title += ` - ${t('meta.titles.mediaSearch')}`;
    } else if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
      title += ` - ${t('meta.titles.dictionary')}`;
    } else if (currentPage.isEncyclopediaSearch) {
      title += ` - ${t('meta.titles.encyclopediaSearch')}`;
    } else if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSingle) {
      title += ` - ${t('meta.titles.encyclopedia')}`;
    } else if (currentPage.isSRSingle) {
      title += ` - ${t('meta.titles.sverigesRadio')}`;
    } else if (currentPage.isNewsSingle) {
      title += ` - ${t('meta.titles.news')}`;
    }

    if (currentPage.query) {
      title += ` - ${currentPage.query}`;
    }
    document.title = title;
  });
};

export default usePageMeta;
