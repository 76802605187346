import React, { useEffect, useState } from 'react';
import './AppNavigation.scss';
import { useTranslation } from 'react-i18next';
import IconHome from 'assets/icons/home.png';
import IconFlagSV from 'assets/icons/flag-sv.png';
import IconFlagNO from 'assets/icons/flag-no.png';
import IconFlagEN from 'assets/icons/flag-en.png';
import IconFlagDK from 'assets/icons/flag-dk.png';
import IconSupport from 'assets/icons/support.png';
import IconFeedback from 'assets/icons/feedback.png';
import IconLogout from 'assets/icons/logout.png';
import { Link } from 'wouter';
import { AppRoutes } from 'models/enums/AppRoutes';
import { clearAuthentication } from 'utils/AuthenticationHelpers';
import useSessionLanguage from 'hooks/useSessionLanguage';
import useCurrentPage from 'hooks/useCurrentPage';
import LogoSkolup from 'assets/icons/skolup.png';

export default function AppNavigation() {
  const { t, i18n } = useTranslation();
  const [showLangMenu, setShowLangMenu] = useState(false);
  const currentPage = useCurrentPage();
  useSessionLanguage();

  useEffect(() => {
    (window as any).SkolonMenuButton?.render();
  });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.sessionStorage.setItem('lang', lang);
    setShowLangMenu(false);
  };

  const langFlags: any = {
    sv: IconFlagSV,
    no: IconFlagNO,
    en: IconFlagEN,
    da: IconFlagDK,
  };

  const logout = () => {
    clearAuthentication();
    document.location.reload();
  };

  const langMenuButtons = Object.keys(i18n.services.resourceStore.data).map((lang) => {
    if (lang === i18n.language) {
      return undefined;
    }
    return (
      <button
        key={lang}
        className="button icon-button"
        title={t('navigation.language')}
        onClick={() => changeLanguage(lang)}
        data-cy={`lang-menu-${lang}`}
      >
        <img src={langFlags[lang]} alt={t('navigation.language')} className="flag" />
      </button>
    );
  });

  return (
    <nav className="AppNavigation navbar is-transparent" role="navigation" aria-label="main navigation">
      <div className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            <div className="buttons">
              <Link href={AppRoutes.START}>
                <span
                  className={'button icon-button home mr-4' + (currentPage.isStart ? ' is-hidden' : '')}
                  title={t('navigation.home')}
                  role="link"
                  data-cy="home-button"
                >
                  <img src={IconHome} alt={t('navigation.home')} className="light" />
                </span>
              </Link>
            </div>
          </div>
          <div className="navbar-item logo mr-4">
            <img src={LogoSkolup} alt="Skolup" />
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <div className="is-relative mr-3">
                <div className={`language-menu ${showLangMenu ? 'show' : ''}`}>{langMenuButtons}</div>
                <button
                  className="button icon-button"
                  title={t('navigation.language')}
                  onClick={() => setShowLangMenu(!showLangMenu)}
                  data-cy="lang-menu-button"
                >
                  <img src={langFlags[i18n.language]} alt={t('navigation.language')} className="flag" />
                </button>
              </div>
              <button className="is-hidden button icon-button" title={t('navigation.support')}>
                <img src={IconSupport} alt={t('navigation.support')} />
              </button>
              <button className="is-hidden button icon-button ml-1" title={t('navigation.feedback')}>
                <img src={IconFeedback} alt={t('navigation.feedback')} />
              </button>
              <button
                className="button icon-button ml-1"
                title={t('navigation.logout')}
                onClick={() => logout()}
                data-cy="logout-button"
              >
                <img src={IconLogout} alt={t('navigation.logout')} />
              </button>
              <div className="skolon-menu-button" data-always-show="true"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
