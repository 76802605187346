import React from 'react';
import './Page404.scss';
import { useTranslation } from 'react-i18next';
import Image404 from 'assets/images/404.png';

export default function PageCategory() {
  const [t] = useTranslation();

  return (
    <section className="Page404 section py-0">
      <h2 className="title is-size-4 mb-1">{t('page404.title')}</h2>
      <p className="is-size-5 mb-5">{t('page404.text')}</p>
      <img src={Image404} alt="" />
    </section>
  );
}
