import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import sv from './locales/sv.json';
import no from './locales/no.json';
import en from './locales/en.json';
import dk from './locales/dk.json';

const resources = {
  sv: {
    translation: sv,
  },
  no: {
    translation: no,
  },
  en: {
    translation: en,
  },
  da: {
    translation: dk,
  },
};

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'sv';

i18next.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: { escapeValue: false },
});

export default i18next;
