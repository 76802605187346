import React, { Fragment, useState } from 'react';
import './PageSingleEncyclopediaEntry.scss';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import { definableValue } from 'utils/definableValue';
import FeedbackComponent from 'components/feedback/FeedbackComponent';
import { useTranslation } from 'react-i18next';
import PageNoResults from 'components/pages/PageNoResults';
import SourceCriticism from 'components/common/SourceCriticism';
import useCurrentPage from 'hooks/useCurrentPage';
interface Props {
  result?: EncyclopediaSearchResult;
}

const sectionsToHide = ['Se även', 'Referenser', 'Externa länkar', 'Kommentarer', 'Källor', 'Vidare läsning'];

export default function PageSingleEncyclopediaEntry({ result }: Props) {
  const [t] = useTranslation();
  const [showFullArticle, setShowFullArticle] = useState(false);
  const currentPage = useCurrentPage();

  if (!definableValue(result)) return <PageNoResults />;

  const scrollToId = async (id: string) => {
    if (!showFullArticle) {
      setShowFullArticle(true);
      await new Promise((r) => setTimeout(r, 250));
    }

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  result.references = result.references.filter((ref) => ref !== 'No sources');
  result.references = [...new Set(result.references)]; // Remove duplicates

  const References = () => {
    const referenceListItems = () =>
      result.references.map((reference) => {
        const cleanReference = reference.replace('http://', '').replace('https://', '').replace('www.', '');
        return (
          <li key={reference}>
            <a href={reference} target="_blank" rel="noopener noreferrer">
              {cleanReference}
            </a>
          </li>
        );
      });

    return (
      <div className="references content">
        <details>
          <summary className="is-mobile is-clickable is-unselectable is-size-5">{t('encyclopedia.references')}</summary>
          {result.references.length > 0 ? (
            <ol className="mt-2">{referenceListItems()}</ol>
          ) : (
            <div className="mt-1">{t('encyclopedia.noReferences')}</div>
          )}
        </details>
      </div>
    );
  };

  const image = (figureClass: string) => {
    if (!result?.imageURL) return undefined;
    return (
      <figure className={figureClass}>
        <img src={result.imageURL} alt={result.heading} />
      </figure>
    );
  };

  const ArticleToggle = () => {
    return (
      <div className="tabs is-toggle article-toggle">
        <ul>
          <li className={!showFullArticle ? 'is-active' : ''}>
            <a
              href="#!"
              data-cy="article-toggle-summary"
              onClick={(e) => {
                e.preventDefault();
                setShowFullArticle(false);
              }}
            >
              <span>{t('encyclopedia.basicArticle')}</span>
            </a>
          </li>
          <li className={showFullArticle ? 'is-active' : ''}>
            <a
              href="#!"
              data-cy="article-toggle-full"
              onClick={(e) => {
                e.preventDefault();
                setShowFullArticle(true);
              }}
            >
              <span>{t('encyclopedia.fullArticle')}</span>
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const BasicArticleContent = () => {
    if (currentPage.searchLang === 'sv') {
      return (
        <Fragment>
          {result.textParagraphed.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })}
        </Fragment>
      );
    } else {
      const basicText = result.text.split('\n');

      return (
        <Fragment>
          {basicText.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })}
        </Fragment>
      );
    }
  };

  const FullArticleContent = () => {
    return (
      <Fragment>
        {result.fullWikiText.map((section, index) => {
          if (sectionsToHide.includes(section.title)) {
            return undefined;
          }
          const isFirstSection = index === 0;
          const paragraphs = section.text.split(/\n/g);
          return (
            <div id={!isFirstSection ? `#${section.order}` : ''} key={section.order} className="content-part">
              {!isFirstSection && <h2>{section.title}</h2>}
              {paragraphs.map((paragraph, index) => {
                return <p key={index}>{paragraph}</p>;
              })}
            </div>
          );
        })}
      </Fragment>
    );
  };

  const TableOfContents = () => {
    return (
      <div className="content table-of-contents" data-cy="table-of-contents">
        <h3 className="is-size-4 is-size-5-mobile has-text-weight-normal">{t('encyclopedia.tableOfContents')}</h3>
        <ol className="list">
          {result.fullWikiText.map((section) => {
            if (sectionsToHide.includes(section.title)) {
              return undefined;
            }
            return (
              <li key={section.order}>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToId(`#${section.order}`);
                  }}
                >
                  {section.title}
                </a>
              </li>
            );
          })}
          <li>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                scrollToId(`#references`);
              }}
            >
              {t('encyclopedia.references')}
            </a>
          </li>
        </ol>
        {image('article-image')}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="PageSingleEncyclopediaEntry" data-cy="single-encyclopedia-page">
        <ArticleToggle />
        <h1 className="single-title" id="#1">
          {result.heading}
        </h1>

        <div className="columns is-desktop article-container">
          <div className="column is-full-tablet is-three-quarters-desktop content mb-0">
            {showFullArticle ? <FullArticleContent /> : <BasicArticleContent />}
            <SourceCriticism sourceType={result.sourceType} className="mt-1 mb-2" />
            <div className="mt-4 mb-5">
              <FeedbackComponent resultId={result.id} />
            </div>

            <div id="#references">
              <References />
            </div>
          </div>

          <div className="column">
            <TableOfContents />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
