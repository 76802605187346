import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.scss';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { useTranslation } from 'react-i18next';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FormData {
  studyMaterial: string;
  age: string;
}

interface FormErrors {
  studyMaterial: boolean;
  age: boolean;
}

export const StudyHelpForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ studyMaterial: '', age: '' });
  const [errors, setErrors] = useState<FormErrors>({ studyMaterial: false, age: false });
  const [isMessageOpen, setIsMessageOpen] = useState(true);

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();
  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const validateField = (name: keyof FormData, value: string): boolean => {
    switch (name) {
      case 'studyMaterial':
        return !value.trim();
      case 'age':
        return isNaN(Number(value)) || value.trim() === '' || parseInt(value, 10) < 0;
      default:
        return false;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: validateField(name as keyof FormData, value) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors: FormErrors = {
      studyMaterial: validateField('studyMaterial', formData.studyMaterial),
      age: validateField('age', formData.age),
    };

    setErrors(formErrors);

    const isValid =
      !Object.values(formErrors).some(Boolean) && formData.studyMaterial.trim() !== '' && formData.age.trim() !== '';
    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      console.log('Submitting Form Data:', formData);
      const prompt = t('chatGpt.helpModal.quiz.createQuizPrompt', {
        age: formData.age,
        quizText: formData.studyMaterial,
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, false, true);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  const isFormValid =
    !errors.studyMaterial && !errors.age && formData.studyMaterial.trim() !== '' && formData.age.trim() !== '';

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        {isMessageOpen && (
          <article className="message warning-message">
            <div className="message-header">
              <p>{t('chatGpt.quizMeWarning')}</p>
              <IconButton
                onClick={() => setIsMessageOpen(false)}
                style={{ alignSelf: 'flex-end', marginLeft: '0rem', width: '0.5rem', height: '0.5rem' }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <div className="message-body">
              <p>{t('chatGpt.quizMeWarningText')}</p>
              <br />
              <p>
                <strong>{t('chatGpt.quizMeWarningExample')}</strong>
              </p>
              <p>
                <strong>{t('chatGpt.quizMeWarningExample2')}</strong>
              </p>
            </div>
          </article>
        )}
        <label className="form-label">{t('chatGpt.quizMe')}</label>
        <Tooltip
          title={t('chatGpt.helpModal.quiz.pasteQuizTextHere')}
          placement="top-start"
          enterDelay={500}
          leaveDelay={200}
        >
          <TextField
            label={t('chatGpt.helpModal.quiz.pasteQuizTextHere')}
            name="studyMaterial"
            variant="outlined"
            value={formData.studyMaterial}
            onChange={handleInputChange}
            error={errors.studyMaterial}
            fullWidth
            className="form-textarea"
            rows={3}
            multiline
          />
        </Tooltip>
        <Tooltip title={t('chatGpt.helpModal.quiz.age')} placement="top-start" enterDelay={500} leaveDelay={200}>
          <TextField
            label={t('chatGpt.helpModal.quiz.age')}
            name="age"
            type="text"
            variant="outlined"
            value={formData.age}
            onChange={handleInputChange}
            error={errors.age}
            fullWidth
            className="form-input"
          />
        </Tooltip>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isFormValid}
          style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
        >
          {t('general.createQuiz')}
        </Button>
      </form>
    </>
  );
};
