import { Slider, Stack } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './ConfigSliders.scss';
import { useTranslation } from 'react-i18next';
import MultiLineTooltip from 'components/common/MultiLineTooltip';

export interface ConfigSlidersProps {
  temperature: number;
  setTemperature: (temperature: number) => void;
  frequencyPenalty: number;
  setFrequencyPenalty: (temperature: number) => void;
  presencePenalty: number;
  setPresencePenalty: (temperature: number) => void;
}

function valuetext(value: number) {
  return `${value}`;
}

export const ConfigSliders = ({
  temperature,
  setTemperature,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
}: ConfigSlidersProps) => {
  const [t] = useTranslation();

  return (
    <div>
      <div style={{ paddingBottom: '3vh' }}>
        <Stack spacing={2} direction={'column'} alignItems={'left'}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <MultiLineTooltip title={t('chatGpt.temperature')} placement="top">
              <InfoOutlinedIcon />
            </MultiLineTooltip>
            <label>{t('chatGpt.temperatureLabel')}</label>
          </Stack>
          <div className="ml-3">
            <Slider
              aria-label="Small steps"
              defaultValue={temperature}
              getAriaValueText={valuetext}
              step={0.1}
              min={0}
              max={2}
              value={temperature}
              onChange={(event, newValue) => setTemperature(newValue as number)}
              valueLabelDisplay="auto"
              marks
            />
          </div>
        </Stack>
      </div>
      <div style={{ paddingBottom: '3vh' }}>
        <Stack spacing={2} direction={'column'} alignItems={'left'}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <MultiLineTooltip title={t('chatGpt.frequencyPenalty')} placement="top">
              <InfoOutlinedIcon />
            </MultiLineTooltip>
            <label>{t('chatGpt.frequencyPenaltyLabel')}</label>
          </Stack>
          <div className="ml-3">
            <Slider
              aria-label="Small steps"
              defaultValue={frequencyPenalty}
              getAriaValueText={valuetext}
              step={0.1}
              min={0}
              max={2}
              value={frequencyPenalty}
              onChange={(event, newValue) => setFrequencyPenalty(newValue as number)}
              valueLabelDisplay="auto"
              marks
            />
          </div>
        </Stack>
      </div>
      <div>
        <Stack spacing={2} direction={'column'} alignItems={'left'}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <MultiLineTooltip title={t('chatGpt.presencePenalty')} placement="top">
              <InfoOutlinedIcon />
            </MultiLineTooltip>
            <label>{t('chatGpt.presencePenaltyLabel')}</label>
          </Stack>
          <div className="ml-3">
            <Slider
              aria-label="Small steps"
              defaultValue={presencePenalty}
              getAriaValueText={valuetext}
              step={0.1}
              min={0}
              max={2}
              value={presencePenalty}
              onChange={(event, newValue) => setPresencePenalty(newValue as number)}
              valueLabelDisplay="auto"
              marks
            />
          </div>
        </Stack>
      </div>
    </div>
  );
};
