import React from 'react';
import { definableValue } from 'utils/definableValue';

interface Props {
  color?: 'primary' | 'info' | 'warning' | 'danger' | 'success';
  left?: true;
  name: string;
  right?: true;
  type?: string;
  className?: string;
}

export default function AppIcon({ color, left, name, right, type = 'fas', className }: Props) {
  const colorClass = color ? `has-text-${color}` : undefined;
  const spanClasses = ['icon', colorClass].filter(definableValue);
  if (left) spanClasses.push('is-left');
  if (right) spanClasses.push('is-right');
  if (className) spanClasses.push(className);

  const iClasses = [type, `fa-${name}`];

  return (
    <span className={spanClasses.join(' ')}>
      <i className={iClasses.join(' ')}></i>
    </span>
  );
}
