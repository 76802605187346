import { BaseAPI } from '../BaseAPI';
import { InsummaryAPIMethods } from './InsummaryAPIMethods';
import { FeedbackRequest } from './models/FeedbackRequest';
import { SummarizeRequest } from './models/SummarizeRequest';
import { SuggestionResponse } from './models/SuggestionResponse';
import { InsummarySummarizeResponse, SummarizeResponse } from './models/SummarizeResponse';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
export class InsummaryAPI extends BaseAPI implements InsummaryAPIMethods {
  constructor() {
    super(process.env.REACT_APP_BACKEND_URL ?? '/insummary');
  }

  async summarize({ query, userID, language }: SummarizeRequest): Promise<SummarizeResponse | undefined> {
    const authString = getAuthenticationStringAttachment();
    const cacheKey = `insummary/${language}/${query}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }

    const { data } = await this.request.get<InsummarySummarizeResponse>(
      '/api/inoolabs/summarize?query=' +
        query +
        '&userId=' +
        userID +
        '&language=' +
        language +
        '&authString=' +
        authString +
        '&origin=*'
    );

    const response: SummarizeResponse = {
      heading: data.heading,
      imageURL: data.image_url,
      query: data?.query,
      queryID: data.query_id,
      summary: data.summary,
      summaryParaghraphed: data.summary_paraghraphed,
      references: data.references,
      nrOfContributors: data.nrOfContributors,
      nrOfReferences: data.nrOfReferences,
      nrOfRevisions: data.nrOfRevisions,
      fullWikiText: data.fullWikiText,
      trustFactor: data.trustFactor,
      suggestions: data.suggestions,
    };

    window.sessionStorage.setItem(cacheKey, JSON.stringify(response));
    return response;
  }

  async feedback({ comment, queryID, rating }: FeedbackRequest): Promise<void> {
    const authString = getAuthenticationStringAttachment();
    await this.request.get<any>(
      '/api/inoolabs/summarize/feedback?query_id=' +
        queryID +
        '&rating=' +
        rating +
        '&comment=' +
        comment +
        '&authString=' +
        authString +
        '&origin=*'
    );
  }

  async getResult(query: string, language: string = 'sv') {
    try {
      return EncyclopediaSearchResult.fromSummarizeResponse(
        await this.summarize({
          query,
          userID: 'test_user_1',
          language,
        })
      );
    } catch (error: any) {
      if (error?.response?.status === 403) {
        clearAuthentication();
        document.location.reload();
      } else if (error?.response?.status === 401) {
        clearAuthentication();
        document.location.reload();
      }
    }
  }

  async getEncyclopediaSuggestionResult(
    mainResult: EncyclopediaSearchResult,
    language: string
  ): Promise<EncyclopediaSearchResult[]> {
    const query = encodeURI(mainResult.suggestions.filter((suggestion) => suggestion !== mainResult.heading).join(';'));

    const cacheKey = `insummary/suggestions/${language}/${query}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }

    const authString = getAuthenticationStringAttachment();
    const userID = 'test_user_1';
    const { data } = await this.request.get<SuggestionResponse[]>(
      '/api/inoolabs/summarize/quick?query=' +
        query +
        '&userId=' +
        userID +
        '&language=' +
        language +
        '&authString=' +
        authString +
        '&origin=*'
    );

    let response: EncyclopediaSearchResult[] = [];

    if (data) {
      response = data.map((article) => {
        return { ...mainResult, heading: article.name, text: article.text, imageURL: article.imageLink };
      });
    }

    window.sessionStorage.setItem(cacheKey, JSON.stringify(response));
    return response;
  }
}

export const insummaryAPI = new InsummaryAPI();
