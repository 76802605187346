import { v4 as uuidv4 } from 'uuid';
import { QAItem, Prompt, MessageHistoryItem } from 'models/AIChatTypes';
import { LanguageItem } from 'components/common/LanguagePicker';
import { ImageMessage, TextMessage } from 'components/AI/fileUpload/FileUploadWindow';

export const generateQAItem = (
  question: string | Array<ImageMessage | TextMessage>,
  model: string = 'gpt-4o-mini',
  ignoreLanguageCommand: boolean = false,
  repeatEnabled: boolean = false,
  showAnswers: boolean = false
): QAItem => {
  return {
    id: uuidv4(),
    question,
    answer: null,
    ignoreRenderInChat: true,
    questionModel: model,
    ignoreLanguageCommand,
    isSpecialNeedsHelpAnswer: repeatEnabled,
    showAnswers,
  };
};

export const prepareMessagePayload = (
  questionsAndAnswers: QAItem[],
  languages: LanguageItem[],
  i18nLanguage: string,
  authString: string,
  settings: {
    selectedLanguage?: string;
    selectedGPTModel: string;
    selectedFrequencyPenalty: number;
    selectedPresencePenalty: number;
    selectedTemperature: number;
    activeView: string;
  },
  currentQAItem?: QAItem
): Prompt => {
  const currentlySelectedLanguage =
    languages.find((lang) => lang.label === settings.selectedLanguage) ||
    languages.find((lang) => lang.code === i18nLanguage);
  const answerLanguageLabel = currentlySelectedLanguage?.label || 'English';
  const answerLanguageCode = currentlySelectedLanguage?.code || 'us';

  const messages: MessageHistoryItem[] =
    questionsAndAnswers
      ?.map((item): MessageHistoryItem[] => {
        if (item.answer) {
          return [
            { role: 'user', content: item.question },
            { role: 'system', content: item.answer },
          ];
        } else {
          return [{ role: 'user', content: item.question }];
        }
      })
      .flat() || [];

  const formatCommand = ` Make sure the answer is formatted with the following guidelines:
    General guidelines:
    1. Use markdown format.
    2. The type of markdown used is GFM (https://github.github.com/gfm).
    5. Raw HTML is supported.
    6. When presenting mathematical expressions, use the appropriate KaTeX delimiters:
       - Display math should be enclosed in double dollar signs \`$$...$$\`.
       - Inline math should be enclosed in dollar signs \`$...$\`.
       - The delimiter is never going to be '(' or ')'.
    7. Separate different parts of the explanation with blank lines for readability.
      `;
  const languageCommand = ` Answer in the following language: ${answerLanguageLabel}-${answerLanguageCode}. ${formatCommand}`;

  let questionId = undefined;
  if (currentQAItem) {
    questionId = currentQAItem.id;
    messages.push({ role: 'user', content: insert_language_command(currentQAItem, languageCommand) });
    console.warn(messages);
  } else {
    const lastQuestionItem = questionsAndAnswers[questionsAndAnswers.length - 1];

    questionId = lastQuestionItem.id;
    const question = insert_language_command(lastQuestionItem, languageCommand);
    messages[messages.length - 1].content = question;
  }

  function insert_language_command(qaItem: QAItem, languageCommand: string) {
    if (typeof qaItem.question === 'string') {
      return qaItem.question + languageCommand;
    } else if (Array.isArray(qaItem.question)) {
      qaItem.question.forEach((item) => {
        if ('text' in item) {
          (item as TextMessage).text += languageCommand;
        }
      });

      return qaItem.question;
    }
    return '';
  }

  const payload: Prompt = {
    model: settings.selectedGPTModel,
    questionId: questionId,
    messages: messages,
    frequencyPenalty: settings.selectedFrequencyPenalty,
    presencePenalty: settings.selectedPresencePenalty,
    temperature: settings.selectedTemperature,
    authString: authString,
    viewId: settings.activeView,
  };

  while (calculateMessagesLength(payload.messages) > 20 * 1024 * 10) {
    payload.messages.shift();
  }

  JSON.stringify(payload);

  return payload;
};

const calculateMessagesLength = (messages: MessageHistoryItem[]): number => {
  return messages.reduce((totalLength, message) => {
    if (typeof message.content === 'string') {
      return totalLength + (message.content as string).length;
    } else {
      let tokenLenght = 0;
      let innerMessages = message.content as Array<ImageMessage | TextMessage>;

      innerMessages.map((innerMessage) => {
        if ('text' in innerMessage) {
          tokenLenght += innerMessage.text.length;
        }
      });

      return totalLength + tokenLenght;
    }
  }, 0);
};
