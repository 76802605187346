import React from 'react';
import useStore from 'store';
import './AppLoader.scss';

interface Props {
  hasAnyResults: boolean;
}

export default function AppLoader({ hasAnyResults }: Props) {
  const loading = useStore((state) => state.loading);
  const show = loading && !hasAnyResults;

  return (
    <div className={`AppLoader ${show && 'show'}`}>
      <div className="inner">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
