import React from 'react';
import './PageInfoAboutChatGpt.scss';

export default function PageInfoAboutChatGpt() {
  return (
    <div>
      <p>Mer information kommer snart</p>
    </div>
  );
}
