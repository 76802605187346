import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import './Form.scss';
import { useTranslation } from 'react-i18next';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { Tooltip } from '@mui/material';

interface LectureInspirationFormData {
  subject: string;
  workArea: string;
  studentAge: string;
  physicalConditions: string;
  resources: string[];
}
interface FormErrors {
  subject: boolean;
  workArea: boolean;
  studentAge: boolean;
}
export const LectureInspirationForm = () => {
  const [formData, setFormData] = useState<LectureInspirationFormData>({
    subject: '',
    workArea: '',
    studentAge: '',
    physicalConditions: '',
    resources: [],
  });
  const [errors, setErrors] = useState<FormErrors>({
    subject: false,
    workArea: false,
    studentAge: false,
  });
  const { i18n, t } = useTranslation();

  const lectureConditions: string[] = [
    t('chatGpt.lectureInspiration.lectureConditionsArr.digitalLecture'),
    t('chatGpt.lectureInspiration.lectureConditionsArr.classroom'),
    t('chatGpt.lectureInspiration.lectureConditionsArr.ClassroomWithGroupRoom'),
  ];
  const resources: string[] = [
    t('chatGpt.lectureInspiration.resourcesArr.sounds'),
    t('chatGpt.lectureInspiration.resourcesArr.projectorOrTv'),
    t('chatGpt.lectureInspiration.resourcesArr.internet'),
    t('chatGpt.lectureInspiration.resourcesArr.digitalDevices'),
  ];

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: validateField(name as keyof LectureInspirationFormData, value) });
  };

  const handleDropdownChange = (event: SelectChangeEvent<string | string[]>) => {
    const { name, value } = event.target;
    if (name === 'resources') {
      setFormData({
        ...formData,
        [name]: typeof value === 'string' ? value.split(',') : value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateField = (name: keyof LectureInspirationFormData, value: string): boolean => {
    switch (name) {
      case 'subject':
      case 'workArea':
        return value.trim() === '';
      case 'studentAge':
        return isNaN(Number(value)) || value.trim() === '' || parseInt(value, 10) < 0;
      default:
        return false;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    const formErrors: FormErrors = {
      subject: validateField('subject', formData.subject),
      workArea: validateField('workArea', formData.workArea),
      studentAge: validateField('studentAge', formData.studentAge),
    };
    setErrors(formErrors);
    const isValid = Object.values(formErrors).every((error) => error === false);
    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      console.log('Submitting Form Data:', formData);
      const prompt = t('chatGpt.lectureInspiration.prompt', {
        subject: formData.subject,
        workArea: formData.workArea,
        age: formData.studentAge,
        conditions: formData.physicalConditions,
        resources: formData.resources.join(', '),
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, false, false);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  const isFormValid =
    Object.values(errors).every((error) => error === false) && Object.values(formData).every((value) => value !== '');

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.lectureInspiration.label')}</label>
      <Tooltip title={t('chatGpt.lectureInspiration.subject')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.lectureInspiration.subject')}
          name="subject"
          variant="outlined"
          value={formData.subject}
          onChange={handleInputChange}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Tooltip title={t('chatGpt.lectureInspiration.workArea')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.lectureInspiration.workArea')}
          name="workArea"
          variant="outlined"
          value={formData.workArea}
          onChange={handleInputChange}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Tooltip title={t('chatGpt.lectureInspiration.age')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.lectureInspiration.age')}
          name="studentAge"
          type="text"
          variant="outlined"
          value={formData.studentAge}
          onChange={handleInputChange}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <FormControl
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            backgroundColor: '#f9f9f9',
            border: '1px solid #dedede',
            borderRadius: '12px',
            '& .MuiSelect-select': {
              borderRadius: '12px',
            },
            '&:hover fieldset': {
              borderColor: '#39B0BB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#39B0BB',
            },
          },
          marginBottom: '20px',
        }}
      >
        <InputLabel>{t('chatGpt.lectureInspiration.physicalConditions')}</InputLabel>
        <Select value={formData.physicalConditions} onChange={handleDropdownChange} name="physicalConditions">
          {lectureConditions.map((condition, index) => (
            <MenuItem key={index} value={condition}>
              {condition}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        sx={{
          '.MuiInputBase-root': {
            backgroundColor: '#f9f9f9',
            border: '1px solid #dedede',
            borderRadius: '12px',
            '& .MuiSelect-select': {
              borderRadius: '12px',
            },
            '&:hover fieldset': {
              borderColor: '#39B0BB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#39B0BB',
            },
          },
          marginBottom: '20px',
        }}
      >
        <InputLabel>{t('chatGpt.lectureInspiration.resources')}</InputLabel>
        <Select
          multiple
          value={formData.resources}
          onChange={handleDropdownChange}
          name="resources"
          renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
        >
          {resources.map((resource) => (
            <MenuItem key={resource} value={resource}>
              {resource}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
      >
        {t('chatGpt.lectureInspiration.submit')}
      </Button>
    </form>
  );
};
