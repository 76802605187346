import React, { useRef } from 'react';
import './PageStart.scss';
import { useTranslation } from 'react-i18next';
import SkapaProv from 'assets/images/skapaProv.svg';
import ForhorMig from 'assets/images/ForhorMig.svg';
import AnpassaLektion from 'assets/images/AnpassaLektion.svg';
import DictionaryImage from 'assets/images/dictionary.png';
import LektionsInspiration from 'assets/images/Lektionsinspiration.svg';
import VikarieAkuten from 'assets/images/Vikarieakuten.svg';
import MediaImage from 'assets/images/mediasearch.png';
import ExplainSomething from 'assets/images/ExplainSomething.svg';
import HelpWithAssignment from 'assets/images/HelpWithAssignment.svg';
import chatGpt from 'assets/images/chatGpt.png';
import { Link } from 'wouter';
import { AppRoutes } from 'models/enums/AppRoutes';
import { useLocation } from 'wouter';

import { useChatStore } from 'store/chatStore';
import { AIView } from 'models/AIChatTypes';
import { AuthData, UserTypes } from 'models/enums/AuthData';

export default function PageStart() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const { setActiveView } = useChatStore();

  const userType = useRef<string | null>(localStorage.getItem(AuthData.USER_TYPE));

  const onAICardRedirect = (view: string): void => {
    setActiveView(view);
    setLocation(AppRoutes.ASK_JUNIE);
  };

  const TeacherCards = () => {
    return (
      <>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.ASK_JUNIE}
                className="item chatGpt"
                title={t('general.chatGpt')}
                data-cy="item-chatGpt"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.chatGpt')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={chatGpt} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>

            <div className="column is-one-quarter">
              <div className="item" onClick={() => onAICardRedirect(AIView.CREATE_QUESTIONS)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.createExam')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={SkapaProv} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="item quizMe" onClick={() => onAICardRedirect(AIView.QUIZ)}>
                <h2 className="title is-size-5 is-size-4-touch">{t('general.quizMe')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={ForhorMig} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <div className="item helpWithAssignment" onClick={() => onAICardRedirect(AIView.HELP_GETTING_STARTED)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('chatGpt.helpWithAssignment')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={HelpWithAssignment} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="item explainSomething" onClick={() => onAICardRedirect(AIView.EXPLANATION)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('chatGpt.instructionRephraseModal.title')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={ExplainSomething} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="item disabilityHelp" onClick={() => onAICardRedirect(AIView.DISABILITY_HELP)}>
                <h2 className="title is-size-5 is-size-4-touch">{t('chatGpt.specialNeedsModal.title')}</h2>
                <div className="px-4 is-flex is-flex-grow-1 is-justify-content-center ">
                  <img src={AnpassaLektion} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <div className="item lectureinspiration" onClick={() => onAICardRedirect(AIView.LECTURE_INSPIRATION)}>
                <h2 className="title is-size-5 is-size-4-touch">{t('chatGpt.lectureInspiration.label')}</h2>
                <div className="px-4 is-flex is-flex-grow-1 is-justify-content-center ">
                  <img src={LektionsInspiration} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div
                className="item substituteTeacherPlanning"
                onClick={() => onAICardRedirect(AIView.SUBSTITUTE_TEACHER_HELP)}
              >
                <h2 className="title is-size-5 is-size-4-touch">{t('chatGpt.substituteTeacherPlanning.title')}</h2>
                <div className="px-4 is-flex is-flex-grow-1 is-justify-content-center ">
                  <img src={VikarieAkuten} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.DICTIONARY_START}
                className="item dictionary"
                title={t('general.dictionary')}
                data-cy="item-dictionary"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.dictionary')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={DictionaryImage} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>
          </div>
        </section>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.MEDIA_START}
                className="item mediasearch"
                title={t('general.mediasearch')}
                data-cy="item-mediasearch"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.mediasearch')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={MediaImage} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>
            <div className="column is-one-quarter"></div>
            <div className="column is-one-quarter"></div>
          </div>
        </section>
      </>
    );
  };

  const StudentCards = () => {
    return (
      <>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.ASK_JUNIE}
                className="item chatGpt"
                title={t('general.chatGpt')}
                data-cy="item-chatGpt"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.chatGpt')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={chatGpt} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>

            <div className="column is-one-quarter">
              <div className="item quizMe" onClick={() => onAICardRedirect(AIView.QUIZ)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.quizMe')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={ForhorMig} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="item explainSomething" onClick={() => onAICardRedirect(AIView.EXPLANATION)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('chatGpt.instructionRephraseModal.title')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={ExplainSomething} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-0 PageStart section">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              <div className="item helpWithAssignment" onClick={() => onAICardRedirect(AIView.HELP_GETTING_STARTED)}>
                <h2 className="title is-size-5 is-size-4-touch ">{t('chatGpt.helpWithAssignment')}</h2>
                <div className="px-4 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={HelpWithAssignment} alt="" className="is-align-self-center item-img" />
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.MEDIA_START}
                className="item mediasearch"
                title={t('general.mediasearch')}
                data-cy="item-mediasearch"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.mediasearch')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={MediaImage} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>

            <div className="column is-one-quarter">
              <Link
                href={AppRoutes.DICTIONARY_START}
                className="item dictionary"
                title={t('general.dictionary')}
                data-cy="item-dictionary"
              >
                <h2 className="title is-size-5 is-size-4-touch ">{t('general.dictionary')}</h2>
                <div className="px-0 mt-4 is-flex is-flex-grow-1 is-justify-content-center">
                  <img src={DictionaryImage} alt="" className="is-align-self-center item-img" />
                </div>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  };

  return <div>{userType.current === UserTypes.TEACHER ? <TeacherCards /> : <StudentCards />}</div>;
}
