import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CompressIcon from '@mui/icons-material/Compress';
import WrapTextIcon from '@mui/icons-material/WrapText';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import Clipboard from 'clipboard';
import './HelpButtons.scss';
import { QAItem } from 'components/pages/PageChatGpt';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useChatStore } from 'store/chatStore';
import { generateQAItem } from 'components/services/promptService';
import { useSpeech } from 'react-text-to-speech';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

export interface HelpButtonsProps {
  questionsAndAnswers: QAItem[];
  itemIndex: number;
  scrollToBottom: () => void;
  textToCopy: string;
  voiceURI: string;
  lang: string;
}

export const HelpButtons = ({
  questionsAndAnswers,
  itemIndex,
  scrollToBottom,
  textToCopy,
  voiceURI,
  lang,
}: HelpButtonsProps) => {
  const [t] = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line
  const { addMessage, activeView } = useChatStore();
  const [answer, setAnswer] = useState<string>('');

  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({ text: answer, lang, voiceURI });

  useEffect(() => {
    const clipboard = new Clipboard(buttonRef.current!, {
      text: function (trigger) {
        return textToCopy;
      },
    });

    clipboard.on('success', function (e) {
      copyAnswerToast();
      e.clearSelection();
    });

    return () => {
      clipboard.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToCopy]);

  const summarizeAnswer = (answerIndex: number) => {
    const answer = questionsAndAnswers[answerIndex].answer;
    if (answer) {
      const command =
        "Give me a tidy and thorough summarization of the following answer you've provided me with, make sure to fit the most crucial information in the summary, using only 1/3 of the length : " +
        answer;
      scrollToBottom();
      const qaItem: QAItem = generateQAItem(command, 'gpt-4-1106-preview');
      addMessage(activeView, qaItem);
    }
  };

  const newSuggestions = (answerIndex: number) => {
    const answer = questionsAndAnswers[answerIndex].answer;
    if (answer) {
      const command = t('chatGpt.specialNeedsModal.repeatPrompt', { tips: answer });
      scrollToBottom();
      const qaItem: QAItem = generateQAItem(command, 'gpt-4-1106-preview', false, true);
      addMessage(activeView, qaItem);
    }
  };

  const simplifyAnswer = (answerIndex: number) => {
    const answer = questionsAndAnswers[answerIndex].answer;
    if (answer) {
      const command =
        'Please simplify the following answer you gave me, be clear and concise, and make sure a 9 year old could understand: ' +
        answer;
      scrollToBottom();
      const qaItem: QAItem = generateQAItem(command, 'gpt-4-1106-preview');
      addMessage(activeView, qaItem);
    }
  };

  const provideAnswers = (answerIndex: number) => {
    const answer = questionsAndAnswers[answerIndex].answer;
    if (answer) {
      const command = t('chatGpt.helpModal.quiz.provideAnswers', {
        quizText: answer,
      });

      const qaItem: QAItem = generateQAItem(command, 'gpt-4-1106-preview', false);
      addMessage(activeView, qaItem);
      scrollToBottom();
    }
  };

  const copyAnswerToast = () => {
    toast(t('general.copyToast'));
  };

  const textToSpeechStart = (answerIndex: number) => {
    const answer = questionsAndAnswers[answerIndex].answer;

    if (answer) {
      setAnswer(answer);
      start();
    }
  };

  const textToSpeechPause = () => {
    pause();
  };

  const textToSpeechStop = () => {
    stop();
  };

  const showNextStep = (answerIndex: number) => {
    let answer = questionsAndAnswers[answerIndex];
    if (answer) {
      answer.isStepByStep = false;
      let qaItem = generateQAItem(t('chatGpt.getNextStep'));
      qaItem.isStepByStep = true;
      addMessage(activeView, qaItem);
      scrollToBottom();
    }
  };

  return (
    <div className="help-button-container">
      <Toaster position="bottom-center" />
      {speechStatus !== 'started' ? (
        <button className="button is-outlined is-small is-rounded " onClick={() => textToSpeechStart(itemIndex)}>
          <IconButton size="small">
            <VolumeUpIcon fontSize="inherit" />
          </IconButton>
        </button>
      ) : (
        <>
          <button className="button is-outlined is-small is-rounded " onClick={() => textToSpeechPause()}>
            <IconButton size="small">
              <PauseIcon fontSize="inherit" />
            </IconButton>
          </button>
          <button className="button is-outlined is-small is-rounded " onClick={() => textToSpeechStop()}>
            <IconButton size="small">
              <StopIcon fontSize="inherit" />
            </IconButton>
          </button>
        </>
      )}

      <button className="button is-outlined is-small is-rounded " onClick={() => simplifyAnswer(itemIndex)}>
        <IconButton size="small">
          <CompressIcon fontSize="inherit" />
        </IconButton>
        {t('chatGpt.simplifyAnswer')}
      </button>

      <button className="button is-outlined is-small is-rounded " onClick={() => summarizeAnswer(itemIndex)}>
        <IconButton size="small">
          <WrapTextIcon fontSize="inherit" />
        </IconButton>
        {t('chatGpt.summarizeAnswer')}
      </button>
      <button ref={buttonRef} className="button is-outlined is-small is-rounded">
        <Tooltip title={t('general.copy')}>
          <IconButton size="small">
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        {t('chatGpt.copyText')}
      </button>
      {questionsAndAnswers[itemIndex]?.isSpecialNeedsHelpAnswer && (
        <button className="button is-outlined is-small is-rounded" onClick={() => newSuggestions(itemIndex)}>
          <Tooltip title={t('general.newSuggestions')}>
            <IconButton size="small">
              <RotateLeftIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {t('general.newSuggestions')}
        </button>
      )}
      {questionsAndAnswers[itemIndex]?.showAnswers && (
        <button className="button is-outlined is-small is-rounded" onClick={() => provideAnswers(itemIndex)}>
          <Tooltip title={t('general.showAnswers')}>
            <IconButton size="small">
              <TipsAndUpdatesIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {t('general.showAnswers')}
        </button>
      )}
      {questionsAndAnswers[itemIndex]?.isStepByStep && (
        <button className="button is-outlined is-small is-rounded" onClick={() => showNextStep(itemIndex)}>
          <Tooltip title={t('chatGpt.showNextStep')}>
            <IconButton size="small">
              <TipsAndUpdatesIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {t('chatGpt.showNextStep')}
        </button>
      )}
    </div>
  );
};
