import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './FileUploadWindow.scss';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploadButton } from './uploadButton';
import { PromtButton } from './promtButton';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { rotate_image, Degree } from 'rustic-image-editor';

export interface FileUploadWindowProps {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  uploadedImages: File[] | null;
  setUploadedImages: React.Dispatch<React.SetStateAction<File[] | null>>;
  setEncodedImages: React.Dispatch<React.SetStateAction<string[]>>;
  setSendImage: React.Dispatch<React.SetStateAction<boolean>>;
  setGPTPromt: React.Dispatch<React.SetStateAction<string>>;
}

export interface TextMessage {
  type: 'text';
  text: string;
}

export interface ImageMessage {
  type: 'image_url';
  image_url: string | ImageURL;
}

export interface ImageURL {
  url: string;
}

const FileUploadWindow = ({
  isActive,
  setIsActive,
  uploadedImages,
  setUploadedImages,
  setEncodedImages,
  setSendImage,
  setGPTPromt,
}: FileUploadWindowProps) => {
  const [customPrompt, setCustomPrompt] = useState<string>('');
  const [t] = useTranslation();

  const toggleWindow = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (uploadedImages != null) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [uploadedImages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPrompt(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setGPTPromt(customPrompt);
      setSendImage(true);
      setIsActive(false);
    }
  };

  const rotateImageClockwise = async (index: number) => {
    const image = uploadedImages![index];
    const binary_file = await image.arrayBuffer();
    const bytes = new Uint8Array(binary_file);

    try {
      const rotated = rotate_image(bytes, Degree.Rotate90);

      const file = new File([rotated], image.name, { type: image.type });
      const newImages = [...uploadedImages!];
      newImages[index] = file;
      setUploadedImages(newImages);
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const rotateImageCounterClockwise = async (index: number) => {
    const image = uploadedImages![index];
    const binary_file = await image.arrayBuffer();
    const bytes = new Uint8Array(binary_file);

    try {
      const rotated = rotate_image(bytes, Degree.Rotate270);

      const file = new File([rotated], image.name, { type: image.type });
      const newImages = [...uploadedImages!];
      newImages[index] = file;
      setUploadedImages(newImages);
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  return (
    <div className={`modal-overlay file-upload ${isActive ? 'active' : ''}`} onClick={toggleWindow}>
      <div className="file-upload-container" onClick={(e) => e.stopPropagation()}>
        <div className={`sidebar file-upload ${isActive ? 'active' : ''}`}>
          <IconButton onClick={toggleWindow} style={{ alignSelf: 'flex-end' }}>
            <CloseIcon />
          </IconButton>
          <UploadButton
            uploadedImages={uploadedImages}
            setUploadedImages={setUploadedImages}
            setEncodedImages={setEncodedImages}
          />
          {uploadedImages && uploadedImages.length > 0 && (
            <div className="image-preview-container">
              <div className="image-preview">
                {Array.from(uploadedImages).map((image, index) => {
                  let fileURL = URL.createObjectURL(image);
                  return (
                    <div key={image.name} className="image-container">
                      <img src={fileURL} alt={image.name} />
                      <IconButton onClick={() => rotateImageCounterClockwise(index)} className="rotate-button">
                        <RotateLeftIcon />
                      </IconButton>
                      <IconButton onClick={() => rotateImageClockwise(index)} className="rotate-button">
                        <RotateRightIcon />
                      </IconButton>
                    </div>
                  );
                })}
              </div>

              <h4>{t('chatGpt.imageHelperText')}</h4>
              <div className="button-group">
                <PromtButton
                  text={t('chatGpt.stepByStep')}
                  promt={t('chatGpt.stepByStepPrompt')}
                  setSendImage={setSendImage}
                  setGPTPromt={setGPTPromt}
                  className="promt-button step-by-step"
                />
                <PromtButton
                  text={t('chatGpt.teachThroughQuestions')}
                  promt={t('chatGpt.teachThroughQuestionsPrompt')}
                  setSendImage={setSendImage}
                  setGPTPromt={setGPTPromt}
                  className="promt-button questions"
                />
                <PromtButton
                  text={t('chatGpt.correct')}
                  promt={t('chatGpt.correctPrompt')}
                  setSendImage={setSendImage}
                  setGPTPromt={setGPTPromt}
                  className="promt-button help-fix"
                />
                <PromtButton
                  text={t('chatGpt.inImage')}
                  promt={t('chatGpt.inImagePrompt')}
                  setSendImage={setSendImage}
                  setGPTPromt={setGPTPromt}
                  className="promt-button help-fix"
                />
                <input
                  type="text"
                  placeholder={t('chatGpt.ownQuestion')}
                  value={customPrompt}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  className="promt-input own-question"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadWindow;
