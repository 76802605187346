import React, { useEffect } from 'react';
import useStore from 'store';
import './PageNoResults.scss';
import { useTranslation } from 'react-i18next';
import useCurrentPage from 'hooks/useCurrentPage';

export default function PageNoResults() {
  const { t } = useTranslation();
  const loading = useStore((state) => state.loading);
  const currentPage = useCurrentPage();

  useEffect(() => {
    document.body.classList.add('no-results');
    return () => {
      document.body.classList.remove('no-results');
    };
  });

  if (loading || currentPage.isUkraineSearch) {
    return <div />;
  }

  return (
    <section className="PageNoResults section pt-6">
      <div className="columns">
        <div className="column">
          <p className="has-text-centered is-size-5">
            {t('general.noResult')} <span className="has-text-weight-semibold">{currentPage.query}</span>.
          </p>
        </div>
      </div>
    </section>
  );
}
