import React from 'react';
import './FeedbackButtons.scss';
import { useTranslation } from 'react-i18next';
import { FeedbackRating } from 'apis/insummary/models/FeedbackRating';
import IconGood from 'assets/icons/happy.png';
import IconBad from 'assets/icons/sad.png';

interface Props {
  onFeedbackButtonPressed: (result: number) => void;
}

export default function FeedbackButtons({ onFeedbackButtonPressed }: Props) {
  const [t] = useTranslation();

  return (
    <div className="FeedbackButtons rows container">
      <p className="row is-size-5">{t('feedbackForm.ratingQuestion')}</p>

      <div className="row mt-3">
        <button className="button is-rounded mr-3" onClick={() => onFeedbackButtonPressed(FeedbackRating.Good)}>
          <span className="icon mr-2">
            <img src={IconGood} alt="" />
          </span>
          <span className="text">{t('feedbackForm.good')}</span>
        </button>

        <button className="button is-rounded" onClick={() => onFeedbackButtonPressed(FeedbackRating.Bad)}>
          <span className="icon mr-2">
            <img src={IconBad} alt="" />
          </span>
          <span className="text">{t('feedbackForm.bad')}</span>
        </button>
      </div>
    </div>
  );
}
