import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './Form.scss';
import { useTranslation } from 'react-i18next';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import Tooltip from '@mui/material/Tooltip';

interface VikarieakutenFormData {
  subject: string;
  workArea: string;
  studentAge: string;
  lessonLength: string;
}

interface VikarieakutenFormErrors {
  subject: boolean;
  workArea: boolean;
  studentAge: boolean;
  lessonLength: boolean;
}

export const VikarieAkutenForm = () => {
  const [formData, setFormData] = useState<VikarieakutenFormData>({
    subject: '',
    workArea: '',
    studentAge: '',
    lessonLength: '',
  });

  const [errors, setErrors] = useState<VikarieakutenFormErrors>({
    subject: false,
    workArea: false,
    studentAge: false,
    lessonLength: false,
  });

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();

  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const validateField = (name: keyof VikarieakutenFormData, value: string): boolean => {
    if (name === 'studentAge' || name === 'lessonLength') {
      return !value.trim() || isNaN(Number(value)) || Number(value) <= 0;
    }
    return value.trim() === '';
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: validateField(name as keyof VikarieakutenFormData, value) });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors: VikarieakutenFormErrors = {
      subject: validateField('subject', formData.subject),
      workArea: validateField('workArea', formData.workArea),
      studentAge: validateField('studentAge', formData.studentAge),
      lessonLength: validateField('lessonLength', formData.lessonLength),
    };

    setErrors(formErrors);

    const isValid = !formErrors.subject && !formErrors.workArea && !formErrors.studentAge && !formErrors.lessonLength;

    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      const prompt = t('chatGpt.substituteTeacherPlanning.prompt', {
        subject: formData.subject,
        workArea: formData.workArea,
        age: formData.studentAge,
        lectureLength: formData.lessonLength,
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, false, false);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  const isFormValid =
    formData.subject.trim() !== '' &&
    formData.workArea.trim() !== '' &&
    formData.studentAge.trim() !== '' &&
    formData.lessonLength.trim() !== '' &&
    !errors.subject &&
    !errors.workArea &&
    !errors.studentAge &&
    !errors.lessonLength;

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.substituteTeacherPlanning.title')}</label>
      <Tooltip
        title={t('chatGpt.substituteTeacherPlanning.subject')}
        enterDelay={500}
        leaveDelay={200}
        placement="top-start"
      >
        <TextField
          label={t('chatGpt.substituteTeacherPlanning.subject')}
          name="subject"
          variant="outlined"
          value={formData.subject}
          onChange={handleInputChange}
          error={errors.subject}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Tooltip
        title={t('chatGpt.substituteTeacherPlanning.workArea')}
        enterDelay={500}
        leaveDelay={200}
        placement="top-start"
      >
        <TextField
          label={t('chatGpt.substituteTeacherPlanning.workArea')}
          name="workArea"
          variant="outlined"
          value={formData.workArea}
          onChange={handleInputChange}
          error={errors.workArea}
          fullWidth
          className="form-input"
        />
      </Tooltip>

      <Tooltip
        title={t('chatGpt.substituteTeacherPlanning.age')}
        enterDelay={500}
        leaveDelay={200}
        placement="top-start"
      >
        <TextField
          label={t('chatGpt.substituteTeacherPlanning.age')}
          name="studentAge"
          type="text"
          variant="outlined"
          value={formData.studentAge}
          onChange={handleInputChange}
          error={errors.studentAge}
          fullWidth
          className="form-input"
        />
      </Tooltip>

      <Tooltip
        title={t('chatGpt.substituteTeacherPlanning.lectureLength')}
        enterDelay={500}
        leaveDelay={200}
        placement="top-start"
      >
        <TextField
          label={t('chatGpt.substituteTeacherPlanning.lectureLength')}
          name="lessonLength"
          type="text"
          variant="outlined"
          value={formData.lessonLength}
          onChange={handleInputChange}
          error={errors.lessonLength}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
      >
        {t('chatGpt.substituteTeacherPlanning.createPlan')}
      </Button>
    </form>
  );
};
