import React, { useRef, useState } from 'react';
import { useChatStore } from 'store/chatStore';
import { AIView } from 'models/AIChatTypes';
import './ModeSelector.scss';
import ExplainSomething from '../../assets/icons/aiFormIcons/ExplainSomething.svg';
import HelpWithAssignment from '../../assets/icons/aiFormIcons/HelpWithAssignment.svg';
//import Divider from './Divider';
import { useTranslation } from 'react-i18next';
import Accessability from '../../assets/icons/aiFormIcons/accessability.svg';
import AskAi from '../../assets/icons/aiFormIcons/AskAi.svg';
import CreateQuestions from '../../assets/icons/aiFormIcons/CreateQuestions.svg';
import QuizMe from '../../assets/icons/aiFormIcons/QuizMe.svg';
import LectureInspiration from '../../assets/icons/aiFormIcons/LectureInspiration.svg';
import SubstituteTeacher from '../../assets/icons/aiFormIcons/SubstituteTeacher.svg';
import { AuthData, UserTypes } from 'models/enums/AuthData';
import InformationModal from './Modal/InfoModal';

export const ModeSelector = () => {
  const { activeView, setActiveView } = useChatStore();
  const [t] = useTranslation();
  const [isModalActive, setIsModalActive] = useState(false);

  const userType = useRef<string | null>(localStorage.getItem(AuthData.USER_TYPE));

  const teacherModes = [
    { label: t('chatGpt.askAi'), view: AIView.CHAT, icon: AskAi },
    { label: t('chatGpt.createQuestions'), view: AIView.CREATE_QUESTIONS, icon: CreateQuestions },
    { label: t('chatGpt.quizMe'), view: AIView.QUIZ, icon: QuizMe },
    {
      label: t('chatGpt.helpWithStudentDisability'),
      view: AIView.DISABILITY_HELP,
      icon: Accessability,
    },
    { label: t('chatGpt.lectureInspiration.label'), view: AIView.LECTURE_INSPIRATION, icon: LectureInspiration },
    {
      label: t('chatGpt.substituteTeacherPlanning.title'),
      view: AIView.SUBSTITUTE_TEACHER_HELP,
      icon: SubstituteTeacher,
    },
    { label: t('chatGpt.explainSomething'), view: AIView.EXPLANATION, icon: ExplainSomething },
    { label: t('chatGpt.helpWithAssignment'), view: AIView.HELP_GETTING_STARTED, icon: HelpWithAssignment },
    { label: t('chatGpt.generateImages'), view: AIView.GENERATE_IMAGE, icon: CreateQuestions },
  ];

  const studentModes = [
    { label: t('chatGpt.askAi'), view: AIView.CHAT, icon: AskAi },
    { label: t('chatGpt.quizMe'), view: AIView.QUIZ, icon: QuizMe },
    { label: t('chatGpt.explainSomething'), view: AIView.EXPLANATION, icon: ExplainSomething },
    { label: t('chatGpt.helpWithAssignment'), view: AIView.HELP_GETTING_STARTED, icon: HelpWithAssignment },
    { label: t('chatGpt.generateImages'), view: AIView.GENERATE_IMAGE, icon: CreateQuestions },
  ];

  const modeSelection = userType.current === UserTypes.TEACHER ? teacherModes : studentModes;

  const handleMoreInfoClick = () => {
    setIsModalActive(true);
  };

  return (
    <div className="mode-selector-container">
      <div className="content-wrapper">
        <div style={{ marginTop: '20px' }}>
          {modeSelection.map((mode) => (
            <button
              key={mode.view}
              className={`mode-button ${activeView === mode.view ? 'active' : ''}`}
              onClick={() => setActiveView(mode.view)}
            >
              <span className="mode-icon">
                <img src={mode.icon} alt={mode.label} />
              </span>
              {mode.label}
            </button>
          ))}
        </div>

        <label className="warning-label">{t('chatGpt.warning')}</label>
        <button className="more-info-button" onClick={handleMoreInfoClick}>
          {t('chatGpt.infoModal.moreInfoLabel')}
        </button>
        <InformationModal isActive={isModalActive} closeModal={() => setIsModalActive(false)} />
      </div>
    </div>
  );
};
