import { BaseAPI } from '../BaseAPI';
import { definableValue } from '../../utils/definableValue';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { SourceType } from 'models/enums/SourceType';
import { SNLMultiResponse, SNLSingleResponse } from './models/SNLResponses';
import { convertSNLQueryToArticleUrlJson } from 'utils/searchHelpers';

interface SNLAPIMethods {
  search(keyWord: string, language: string): Promise<SNLMultiResponse[]>;
}

export class SNLAPI extends BaseAPI implements SNLAPIMethods {
  constructor() {
    super(process.env.REACT_APP_BACKEND_URL!);
  }

  async search(keyWord: string): Promise<SNLMultiResponse[]> {
    const authenticationString = getAuthenticationStringAttachment();
    const { data } = await this.request.get<any[]>(
      `/api/snl/entry?keyWord=${keyWord}&authString=${authenticationString}`
    );
    var snlEntry: SNLMultiResponse[] = [];
    if (definableValue(data)) {
      for (let i = 0; i < data.length; i++) {
        snlEntry.push(this.createSNLResponse(data[i]));
      }
    }
    return snlEntry;
  }

  async getSingle(query: string): Promise<SNLSingleResponse | undefined> {
    try {
      const cacheKey = `snl/single/${query}`;
      const cachedResponse = window.sessionStorage.getItem(cacheKey);
      if (cachedResponse) {
        return JSON.parse(cachedResponse);
      }
      const authenticationString = getAuthenticationStringAttachment();
      const url = convertSNLQueryToArticleUrlJson(query);
      const { data } = await this.request.get<any>(`/api/snl/article?url=${url}&authString=${authenticationString}`);
      data.source = 'SNL';
      data.sourceType = SourceType.SNL;
      window.sessionStorage.setItem(cacheKey, JSON.stringify(data));
      return data;
    } catch (error) {
      console.log('error', error);
    }
  }

  createSNLResponse = (data: any): SNLMultiResponse => {
    const newSNLResponse = {
      ...data,
      source: 'SNL',
      sourceType: SourceType.SNL,
    };
    return newSNLResponse;
  };

  async getResult(keyWord: string) {
    const cacheKey = `snl/${keyWord}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }
    try {
      const result = await this.search(keyWord);
      window.sessionStorage.setItem(cacheKey, JSON.stringify(result));
      return result;
    } catch (error: any) {
      console.log('error', error);
      if ([401, 403].includes(error?.response?.status)) {
        clearAuthentication();
        document.location.reload();
      }
      return [];
    }
  }
}
export const snlAPI = new SNLAPI();
