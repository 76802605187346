import React, { Fragment } from 'react';
import './PageSingleNewsEntry.scss';
import { useTranslation } from 'react-i18next';
import { definableValue } from 'utils/definableValue';
import { GNewsResponse } from 'apis/gnews/models/GNewsResponse';
import Icon from 'components/app/AppIcon';
import SourceCriticism from 'components/common/SourceCriticism';

interface Props {
  result?: GNewsResponse;
}

export default function PageSingleNewsEntry({ result }: Props) {
  const [t] = useTranslation();

  const date = result?.publishDate ? result.publishDate?.split('T')?.[0] : '';

  if (!definableValue(result)) return <div></div>;

  const image = (figureClass: string) => {
    if (!result?.imageURL) return undefined;
    return (
      <figure className={figureClass}>
        <img src={result.imageURL} alt={result.title} />
      </figure>
    );
  };

  const paragraphs = result.content.split(/\n/g);
  paragraphs.unshift(result.description);

  return (
    <Fragment>
      <div className="PageSingleNewsEntry">
        <h1 className="single-title">{result.title}</h1>

        <p className="meta-data">
          <span className="date">
            {t('general.published')}: {date}
          </span>
          <a href={result.articleURL} target="_blank" rel="noopener noreferrer" className="source-link">
            <span className="icon-text">
              <span>{t('general.goToSource')}</span>
              <Icon name="external-link-alt" />
            </span>
          </a>
        </p>

        {image('inline-image')}

        <div className="content">
          {paragraphs.map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>;
          })}
        </div>

        <SourceCriticism className="mb-5" sourceType={result.sourceType} source={result.source} />

        {image('block-image')}
      </div>
    </Fragment>
  );
}
