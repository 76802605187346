import React, { useEffect, useRef, useState } from 'react';
import { useChatStore } from 'store/chatStore';
import ScrollableFeed from 'react-scrollable-feed';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import ChatGptPic from 'assets/images/chatGptPic.svg';
import ChatGptGif from 'assets/animations/SkolupGPT.gif';
import { HelpButtons } from 'components/AI/HelpButtons';
import { ImageChatMessage, QAItem } from 'models/AIChatTypes';
import { t } from 'i18next';
import ErrorBoundary from '../../utils/ErrorBoundary';
import './ChatWindow.scss';

import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
interface ChatWindowProps {
  voiceURI: string;
  lang: string;
}

const downloadImage = (base64Data: string, fileName: string) => {
  try {
    const cleanedData = base64Data.replace(/[^A-Za-z0-9+/=]/g, '');

    const byteCharacters = atob(cleanedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    // freeing memory
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Failed to download image:', error);
  }
};

const ChatWindow = ({ voiceURI, lang }: ChatWindowProps) => {
  const { messages, activeView, questionActiveStatus, imageMessages } = useChatStore();
  const feedRef = useRef<ScrollableFeed>(null);
  //const qaItemsRef = useRef<QAItem[]>(messages[activeView] || []);
  const [qaItems, setQaItems] = useState<QAItem[]>(messages[activeView] || []);
  const [imageChatMessages, setImageChatMessages] = useState<ImageChatMessage[]>(imageMessages[activeView] || []);

  useEffect(() => {
    // qaItemsRef.current = messages[activeView] ?? [];
    setQaItems(messages[activeView] ?? []);
  }, [messages, activeView]);

  useEffect(() => {
    // qaItemsRef.current = messages[activeView] ?? [];
    if (imageMessages[activeView]) {
      setImageChatMessages(imageMessages[activeView] ?? []);
    }
  }, [imageMessages, activeView]);

  const areHelpButtonsActive = (answer: string | null, itemIndex: number) => {
    const isAnswerValid = !(
      answer === t('chatGpt.cancelledPrompt') ||
      answer === t('chatGpt.errorMessage') ||
      answer === ''
    );
    const isCurrItemValid = !(itemIndex === messages[activeView]?.length - 1 && questionActiveStatus[activeView]);
    return isAnswerValid && isCurrItemValid;
  };

  return (
    <ScrollableFeed ref={feedRef}>
      <div className="right-padding">
        {qaItems.map((item: QAItem, itemIndex: number) => {
          const helpButtonsActive = areHelpButtonsActive(item.answer, itemIndex);
          return (
            <div key={item.id + 'Index nr: ' + itemIndex}>
              {item?.question.length > 0 && !item.ignoreRenderInChat && (
                <div id="questionTest" className="question-container">
                  <div className="messages messages--sent">
                    <div className="message">
                      {Array.isArray(item.question) ? (
                        item.question.map((q, idx) => {
                          if (q.type === 'text') {
                            let text = q.text;
                            if (text.includes('Answer in the following language:')) {
                              text = text.split('Answer in the following language:')[0];
                            }
                            return <div key={idx}>{text}</div>;
                          } else if (q.type === 'image_url') {
                            return (
                              <img
                                key={idx}
                                src={typeof q.image_url === 'string' ? q.image_url : q.image_url.url}
                                alt=""
                              />
                            );
                          }
                          return null;
                        })
                      ) : (
                        <div>{item.question}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="answer-container">
                <div className="answer-container-flex">
                  <img className="chatGptPic" src={ChatGptPic} alt="" />
                  <div className="messages messages--received">
                    <div className="message">
                      {item.answer ? (
                        <>
                          <ErrorBoundary fallbackText={item.answer}>
                            <Markdown
                              className="message markdown-content"
                              remarkPlugins={[remarkMath, remarkGfm]}
                              rehypePlugins={[rehypeRaw, rehypeKatex]}
                            >
                              {item.answer}
                            </Markdown>
                          </ErrorBoundary>
                        </>
                      ) : (
                        <img className="gif" src={ChatGptGif} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                {helpButtonsActive && (
                  <HelpButtons
                    itemIndex={itemIndex}
                    questionsAndAnswers={qaItems}
                    scrollToBottom={() => feedRef.current?.scrollToBottom()}
                    textToCopy={item.answer ?? ''}
                    voiceURI={voiceURI}
                    lang={lang}
                  />
                )}
              </div>
            </div>
          );
        })}

        {imageChatMessages.map((item: ImageChatMessage, itemIndex: number) => (
          <div key={item.id + ' Index nr: ' + itemIndex}>
            <div id="questionTest" className="question-container">
              <div className="messages messages--sent">
                <div className="message">
                  <div className="image-response-text">
                    {item.response?.data[0].revised_prompt ? item.response?.data[0].revised_prompt : item.prompt}
                  </div>
                </div>
              </div>
            </div>

            <div className="answer-container">
              <div className="answer-container-flex">
                <img className="chatGptPic" src={ChatGptPic} alt="" />
                <div className="messages messages--received">
                  {item.response ? (
                    <>
                      {item.response.data.map((q, idx) => {
                        const src = q.url ? q.url : q.b64_json ? `data:image/jpeg;base64,${q.b64_json}` : '';
                        const srcDownload = q.url ? q.url : q.b64_json ? `${q.b64_json}` : '';

                        const alt = q.revised_prompt ? q.revised_prompt : '';

                        return (
                          <div className="message" key={idx}>
                            <div className="gpt-response-image-container">
                              <img className="gpt-response-gpt-image" src={src} alt={alt} />
                              <IconButton
                                className="gpt-response-icon-button"
                                onClick={() => downloadImage(srcDownload, 'skolup_ai_generated.jpeg')}
                              >
                                <FileDownloadOutlinedIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="message">
                      <img className="gif" src={ChatGptGif} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ScrollableFeed>
  );
};

export default ChatWindow;
