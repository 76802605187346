import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './ConfigSidebar.scss';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfigSliders } from './ConfigSliders';
import { GPTModelSelector, GPTModels } from './GPTModelSelector';
import { VoiceSelector } from './VoiceSelector';

export interface ConfigSidebarProps {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  temperature: number;
  setTemperature: (temperature: number) => void;
  frequencyPenalty: number;
  setFrequencyPenalty: (frequency: number) => void;
  presencePenalty: number;
  setPresencePenalty: (presence: number) => void;
  userType: string | null;
  selectedModel: GPTModels;
  setSelectedModel: (model: GPTModels) => void;
  setVoiceURI: React.Dispatch<React.SetStateAction<string>>;
  lang: string;
  resetConfig: () => void;
}

const ConfigSidebar = ({
  isActive,
  setIsActive,
  temperature,
  setTemperature,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
  userType,
  selectedModel,
  setSelectedModel,
  setVoiceURI,
  resetConfig,
  lang,
}: ConfigSidebarProps) => {
  const [t] = useTranslation();

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <div className={`modal-overlay ${isActive ? 'active' : ''}`} onClick={toggleSidebar} />
      <div className={`sidebar ${isActive ? 'active' : ''}`}>
        <IconButton onClick={toggleSidebar} style={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <h1 className="title-header">{t('chatGpt.configuration')}</h1>
        <h1 className="reset-header" onClick={() => resetConfig()}>
          {t('chatGpt.resetButton')}
        </h1>
        <hr />
        <div className="gpt-model-selector">
          <GPTModelSelector userType={userType} selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
        </div>
        <div className="config-sliders">
          <ConfigSliders
            temperature={temperature}
            setTemperature={setTemperature}
            frequencyPenalty={frequencyPenalty}
            setFrequencyPenalty={setFrequencyPenalty}
            presencePenalty={presencePenalty}
            setPresencePenalty={setPresencePenalty}
          />
        </div>
        <div className="config-voice-selector">
          <VoiceSelector setVoiceURI={setVoiceURI} lang={lang} />
        </div>
      </div>
    </div>
  );
};

export default ConfigSidebar;
