import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import './Form.scss'; // Assuming the CSS will be shared
import { useTranslation } from 'react-i18next';
import { InputLabel, Tooltip } from '@mui/material';
import { teacherModels } from '../GPTModelSelector';
import { useChatStore } from 'store/chatStore';
import { generateQAItem, prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';

interface FormData {
  subject: string;
  subjectArea: string;
  material: string; // This field will no longer be mandatory
  studentAge: string;
  difficulty: number;
}

interface FormErrors {
  subject: boolean;
  subjectArea: boolean;
  material: boolean; // However, keep the ability to track errors if needed
  studentAge: boolean;
}

export const CreateQuestionsForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    subject: '',
    subjectArea: '',
    material: '', // Default value is empty and it's acceptable
    studentAge: '',
    difficulty: 1, // Default difficulty level set to 1
  });
  const [errors, setErrors] = useState<FormErrors>({
    subject: false,
    subjectArea: false,
    material: false, // Will not impact form validity directly
    studentAge: false,
  });

  const { i18n, t } = useTranslation();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const {
    setPrompt,
    messages,
    activeView,
    questionActiveStatus,
    setQuestionActiveStatus,
    selectedFrequencyPenalty,
    selectedGPTModel,
    selectedLanguage,
    selectedPresencePenalty,
    selectedTemperature,
    addMessage,
  } = useChatStore();

  const validateField = (name: keyof FormData, value: string): boolean => {
    if (name === 'material') {
      // Since 'material' is not mandatory, always return false for errors
      return false;
    }

    switch (name) {
      case 'studentAge':
        return isNaN(Number(value)) || value.trim() === '' || parseInt(value, 10) < 0;
      case 'subject':
      case 'subjectArea':
        return !value.trim();
      default:
        return false;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });

    setErrors({ ...errors, [name]: validateField(name as keyof FormData, value) });
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    setFormData({ ...formData, difficulty: value as number });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors: FormErrors = {
      subject: validateField('subject', formData.subject),
      subjectArea: validateField('subjectArea', formData.subjectArea),
      material: false, // Explicitly set to false since it's not mandatory
      studentAge: validateField('studentAge', formData.studentAge),
    };

    setErrors(formErrors);
    const isValid =
      !formErrors.subject &&
      !formErrors.subjectArea &&
      !formErrors.studentAge &&
      formData.subject.trim() !== '' &&
      formData.subjectArea.trim() !== '' &&
      formData.studentAge.trim() !== '';

    if (isValid && !questionActiveStatus[activeView]) {
      setQuestionActiveStatus(activeView, true);
      console.log('Submitting Form Data:', formData);
      const prompt = t('chatGpt.helpModal.exam.createExamPrompt', {
        age: formData.studentAge,
        subject: formData.subject,
        examDifficulty: formData.difficulty,
        chapter: formData.subjectArea,
        additionalDetails: formData.material,
      });
      const qaItem = generateQAItem(prompt, teacherModels[1].model, false, false, false);
      addMessage(activeView, qaItem);
      const payload = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        },
        qaItem
      );
      setPrompt(payload);
    } else {
      console.log('Validation Failed');
    }
  };

  // Update form validity check to exclude 'material'
  const isFormValid = !errors.subject && !errors.subjectArea && !errors.studentAge && formData.studentAge.trim() !== '';

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label className="form-label">{t('chatGpt.createQuestions')}</label>
      <Tooltip title={t('chatGpt.helpModal.exam.subject')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.helpModal.exam.subject')}
          name="subject"
          variant="outlined"
          value={formData.subject}
          onChange={handleInputChange}
          error={errors.subject}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Tooltip title={t('chatGpt.helpModal.exam.chapter')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.helpModal.exam.chapter')}
          name="subjectArea"
          variant="outlined"
          value={formData.subjectArea}
          onChange={handleInputChange}
          error={errors.subjectArea}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <Tooltip
        title={t('chatGpt.helpModal.exam.additionalDetails')}
        placement="top-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <TextField
          label={t('chatGpt.helpModal.exam.additionalDetails')}
          name="material"
          variant="outlined"
          value={formData.material}
          onChange={handleInputChange}
          error={errors.material}
          fullWidth
          multiline
          className="form-textarea"
          rows={3}
        />
      </Tooltip>
      <Tooltip title={t('chatGpt.helpModal.exam.class')} placement="top-start" enterDelay={500} leaveDelay={200}>
        <TextField
          label={t('chatGpt.helpModal.exam.class')}
          name="studentAge"
          type="text"
          variant="outlined"
          value={formData.studentAge}
          onChange={handleInputChange}
          error={errors.studentAge}
          fullWidth
          className="form-input"
        />
      </Tooltip>
      <div className="difficulty-label">
        <InputLabel style={{ fontWeight: 'lighter', color: 'black', fontSize: '18px' }} htmlFor="grade">
          {t('chatGpt.helpModal.exam.difficulty')}
        </InputLabel>
      </div>

      <div className="difficulty-slider">
        <Slider
          value={formData.difficulty}
          onChange={handleSliderChange}
          aria-labelledby="difficulty-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={10}
        />
      </div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isFormValid}
        style={{ backgroundColor: isFormValid ? '#39B0BB' : '' }}
      >
        {t('general.createExam')}
      </Button>
    </form>
  );
};
