import React, { /*ChangeEvent,*/ FormEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { insummaryAPI } from 'apis/insummary/InsummaryAPI';
import { FeedbackRating } from 'apis/insummary/models/FeedbackRating';
import { definableValue } from 'utils/definableValue';
import './FeedbackFloatingForm.scss';

export interface OnFeedbackData {
  comment?: string;
  id?: number | string;
  ratingStatus?: number;
}

interface Props {
  id?: number | string;
  setFeedbackStatus: (result: number) => void;
  ratingStatus?: number;
}

export default function FeedbackFloatingForm({ id, setFeedbackStatus, ratingStatus }: Props) {
  const [t] = useTranslation();
  const [comment, setComment] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const resetFormData = () => {
    setComment('');
  };

  /*const handleChangeRating = (event: ChangeEvent<HTMLInputElement>) => {
    setRating(Number(event.target.value));
  };*/

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!definableValue(comment) || !definableValue(id) || !definableValue(ratingStatus)) return;

    try {
      await insummaryAPI.feedback({
        comment,
        queryID: Number(id),
        rating: ratingStatus,
      });

      resetFormData();
      setFeedbackStatus(FeedbackRating.None);
    } catch (error) {
      console.info('Something went wrong, see error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    setFeedbackStatus(FeedbackRating.Waiting);
  };

  const cardClasses = () => {
    let classes = 'card search-feedback-form search-feedback-form-visible has-border-white-grey';

    if (ratingStatus === FeedbackRating.None || ratingStatus === FeedbackRating.Waiting) {
      classes += ' search-feedback-form-hidden';
    }

    return classes;
  };

  const submitButtonClasses = () => {
    let classes = 'button is-primary is-fullwidth';

    if (loading) {
      classes += ' is-loading';
    }

    return classes;
  };

  return (
    <div className={cardClasses()}>
      <div className="card-content">
        <p className="title is-4 is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
          <span>{t('feedbackForm.thanks')}</span>

          <button className="delete" onClick={(event) => handleClose(event)}></button>
        </p>

        <form onSubmit={(event) => handleSubmit(event)} data-testid="searchFeedbackForm">
          <p className="my-4">{t('feedbackForm.feedbackDescription')}</p>

          <div className="field">
            <label className="label" htmlFor="comment">
              {t('general.comment')}
            </label>
            <div className="control">
              <textarea
                placeholder={t('feedbackForm.feedbackPlaceholderText')}
                className="textarea"
                id="comment"
                value={comment}
                rows={4}
                readOnly={loading}
                maxLength={500}
                onChange={(event) => setComment(event.target.value)}
                data-testid="commentTextarea"
              ></textarea>
            </div>
          </div>

          <button className={submitButtonClasses()} type="submit">
            {t('general.send')}
          </button>
        </form>
      </div>
    </div>
  );
}
