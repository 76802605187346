/* eslint-disable no-unused-vars */
export enum SearchResultSource {
  Wikipedia = 'Wikipedia',
  Wiktionary = 'Wiktionary',
  GNews = 'GNews',
}

/* eslint-disable no-unused-vars */
export enum SearchResultStatus {
  NoSourcesAvailable = 'No sources',
}
