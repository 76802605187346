import React, { useState, useEffect, Fragment } from 'react';
import { Route, Switch, useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'models/enums/AppRoutes';
import PageLogin from 'components/pages/PageLogin';
import PageSSO from 'components/pages/PageSSO';
import MainUkraine from 'components/MainUkraine';
import ProtectedRoute from 'components/ProtectedRoute';
import { getSkolonSessionStatus, clearAuthentication } from 'utils/AuthenticationHelpers';
import { SkolonAuthStatus } from 'models/enums/AuthData';
import { LangList } from 'models/enums/LangList';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const { i18n } = useTranslation();
  const [location] = useLocation();

  useEffect(() => {
    getSkolonSessionStatus().then((status: SkolonAuthStatus) => {
      if (status !== SkolonAuthStatus.AUTHENTICATED) {
        clearAuthentication();
        setIsAuthenticated(false);
      }
    });
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const langugage = urlSearchParams.get('lang');
    if (langugage && langugage in LangList) {
      i18n.changeLanguage(langugage);
      window.sessionStorage.setItem('lang', langugage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const routeHistory = JSON.parse(window.sessionStorage.getItem('routeHistory') ?? '[]');
    if (routeHistory[routeHistory.length - 1] !== location) {
      routeHistory.push(location);
    }
    window.sessionStorage.setItem('routeHistory', JSON.stringify(routeHistory.slice(-50)));
  }, [location]);

  return (
    <Fragment>
      <Switch>
        <Route path={AppRoutes.UKRAINE_START} component={MainUkraine} />
        <Route path={AppRoutes.UKRAINE_SEARCH} component={MainUkraine} />
        <Route path={AppRoutes.UKRAINE_NEWS_SINGLE} component={MainUkraine} />
        <Route path={AppRoutes.UKRAINE_SR_SINGLE} component={MainUkraine} />

        <Route path={AppRoutes.SSO} component={PageSSO} />
        <Route path={AppRoutes.LOGIN}>
          {<PageLogin setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}
        </Route>
        <ProtectedRoute
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
          authenticationPath={AppRoutes.LOGIN}
        />
      </Switch>
    </Fragment>
  );
}

export default App;
