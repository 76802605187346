import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVoices } from 'react-text-to-speech';

interface VoiceSelectorProps {
  setVoiceURI: React.Dispatch<React.SetStateAction<string>>;
  lang: string;
}

export const VoiceSelector = ({ setVoiceURI, lang }: VoiceSelectorProps) => {
  const [t] = useTranslation();
  const { voices } = useVoices();
  const [selectedVoiceURI, setSelectedVoiceURI] = useState('');

  useEffect(() => {
    const newVoiceURI = voices.filter((voice) => !lang || voice.lang === lang).pop()?.voiceURI || '';

    setSelectedVoiceURI(newVoiceURI);
    setVoiceURI(newVoiceURI);
  }, [lang]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const newVoiceURI = e.target.value as string;
    if (newVoiceURI === '') return;
    setSelectedVoiceURI(newVoiceURI);
    setVoiceURI(newVoiceURI);
  };

  return (
    <>
      <InputLabel>{t('chatGpt.config.voice')}</InputLabel>
      <Select value={selectedVoiceURI} onChange={handleChange}>
        <MenuItem disabled value=" ">
          {t('chatGpt.config.selectVoice')}
        </MenuItem>
        {voices
          .filter((voice) => !lang || voice.lang === lang)
          .map(({ voiceURI }) => (
            <MenuItem key={voiceURI} value={voiceURI}>
              {voiceURI}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};
