//disable eslint for this file
/* eslint-disable */

import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWebSocket } from 'hooks/useWebsocket';
import { useChatStore } from 'store/chatStore';
import { Chat } from 'components/AI/views/Chat';
import { AIView } from 'models/AIChatTypes';
import { ModeSelector } from 'components/AI/ModeSelector';
import './PageChatGpt.scss';
import { prepareMessagePayload } from 'components/services/promptService';
import { getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { useTranslation } from 'react-i18next';

import Divider from 'components/AI/Divider';
import { Forms } from 'components/AI/views/Forms';
import { ImageMessage, TextMessage } from 'components/AI/fileUpload/FileUploadWindow';

export interface QAItem {
  id: string;
  question: string | Array<ImageMessage | TextMessage>;
  answer: string | null;
  ignoreRenderInChat: boolean;
  questionModel?: string;
  ignoreLanguageCommand?: boolean;
  isSpecialNeedsHelpAnswer?: boolean;
  showAnswers?: boolean;
  isStepByStep?: boolean;
}

export default function PagechatGpt() {
  const [clientUUID, _setClientUUID] = useState<string>(uuidv4());

  useWebSocket(process.env.REACT_APP_BACKEND_WEBSOCKET_URL!, clientUUID);

  const [t] = useTranslation();
  const { i18n } = useTranslation();

  const {
    setPrompt,
    setQuestionActiveStatus,
    messages,
    activeView,
    questionActiveStatus,
    selectedFrequencyPenalty,
    selectedTemperature,
    selectedPresencePenalty,
    selectedLanguage,
    selectedGPTModel,
  } = useChatStore();

  const languages = [
    { code: 'us', label: 'English' },
    { code: 'sv', label: 'Svenska' },
    { code: 'no', label: 'Norsk' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'Français' },
    { code: 'dk', label: 'Dansk' },
    { code: 'es', label: 'Español' },
    { code: 'it', label: 'Italiano' },
    { code: 'JA', label: '日本' },
    { code: 'FI', label: 'Suomalainen' },
  ];

  const errorId = 'ERROR_ID';

  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<QAItem[]>([]);
  const [toggleSetErrorMessage, setToggleSetErrorMessage] = useState<boolean>(false);
  const [toggleClearErrorMessage, setToggleClearErrorMessage] = useState<boolean>(false);
  const [isCancelling] = useState<boolean>(false);

  const setErrorMessage = () => {
    const newItem: QAItem = {
      id: errorId,
      question: '',
      answer: t('chatGpt.errorMessage'),
      ignoreRenderInChat: false,
    };

    const errorExists = questionsAndAnswers.find((item) => item.id === errorId);
    if (errorExists) {
      return;
    }

    setQuestionsAndAnswers((prevState) => [...prevState, newItem]);
  };

  const clearErrorMessage = () => {
    const filteredQuestionsAndAnswers = questionsAndAnswers.filter((item) => item.id !== errorId);
    setQuestionsAndAnswers(filteredQuestionsAndAnswers);
  };

  useEffect(() => {
    if (toggleClearErrorMessage) {
      clearErrorMessage();
      setToggleClearErrorMessage(false);
    }
  }, [toggleClearErrorMessage]);

  useEffect(() => {
    if (toggleSetErrorMessage) {
      if (isCancelling) {
        return;
      }
      setErrorMessage();
      setToggleSetErrorMessage(false);
    }
  }, [toggleSetErrorMessage]);

  useEffect(() => {
    const nrOfMessages = messages[activeView]?.length || 0;
    if (nrOfMessages === 0) return;
    const lastMessage = messages[activeView][nrOfMessages - 1];
    if (!questionActiveStatus[activeView] && !lastMessage.questionProcessed) {
      setQuestionActiveStatus(activeView, true);
      const payload: any = prepareMessagePayload(
        messages[activeView],
        languages,
        i18n.language,
        getAuthenticationStringAttachment() || '',
        {
          selectedFrequencyPenalty,
          selectedTemperature,
          selectedPresencePenalty,
          activeView,
          selectedGPTModel: selectedGPTModel.model,
          selectedLanguage: selectedLanguage?.label,
        }
      );
      payload.authString = getAuthenticationStringAttachment() || '';
      setPrompt(payload);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages[activeView]]);

  return (
    <div className="flex-container">
      <ModeSelector />
      <Divider />
      <div className="chat-container">{activeView === AIView.CHAT ? <Chat /> : <Forms />}</div>
    </div>
  );
}

/**
 * activeView == AIView.CREATE_QUESTIONS ? (
          <CreateQuestions />
        ) : activeView == AIView.QUIZ ? (
          <Quiz />
        ) : activeView == AIView.SUBSTITUTE_TEACHER_HELP ? (
          <VikarieAkuten />
        ) : activeView == AIView.LECTURE_INSPIRATION ? (
          <LectureInspiration />
        ) : activeView == AIView.DISABILITY_HELP ? (
          <Assistance />
        ) : activeView == AIView.EXPLANATION ? (
          <Explanation />
        ) : activeView == AIView.HELP_GETTING_STARTED ? (
          <GetStarted />
        ) : null
 */
