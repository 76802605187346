/* eslint-disable no-unused-vars */
export enum SourceType {
  Encyclopedia = 'Encyclopedia',
  Dictionary = 'Dictionary',
  News = 'News',
  SverigesRadio = 'SverigesRadio',
  SNL = 'SNL',
}

export enum Sources {
  SVT = 'Sveriges Television',
  RT = 'RT',
}
