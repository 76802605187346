import { Redirect, Route } from 'wouter';
import React, { Fragment } from 'react';
import MainPage from 'components/Main';
import { useLocation } from 'wouter';

export interface ProtectedRouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
  setIsAuthenticated: (data: boolean) => void;
}

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
}: ProtectedRouteProps) {
  const [location] = useLocation();
  let authenticatedPath: string = location;

  if (isAuthenticated) {
    return (
      <Fragment>
        <Route path={authenticatedPath} component={MainPage} />
      </Fragment>
    );
  } else {
    return <Redirect to={authenticationPath} />;
  }
}
