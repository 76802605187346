import { SummarizeResponse, wikiTripleEntry } from 'apis/insummary/models/SummarizeResponse';
import { SearchResultSource } from 'models/enums/SearchResultSource';
import { SourceType } from './enums/SourceType';

export interface EncyclopediaSearchResultProps {
  id?: string | number;
  imageURL?: string;
  text: string;
  textParagraphed: string[];
  references: string[];
  source?: SearchResultSource;
  sourceType: string;
  heading?: string;
  nrOfContributors: number;
  nrOfRevisions: number;
  nrOfReferences: number;
  fullWikiText: wikiTripleEntry[];
  trustFactor: number;
  suggestions: string[];
}

export class EncyclopediaSearchResult implements EncyclopediaSearchResultProps {
  id?: string | number | undefined;
  imageURL?: string | undefined;
  text!: string;
  textParagraphed!: string[];
  source?: SearchResultSource | undefined;
  sourceType!: string;
  heading?: string;
  references!: string[];
  nrOfContributors!: number;
  nrOfRevisions!: number;
  nrOfReferences!: number;
  fullWikiText!: wikiTripleEntry[];
  trustFactor!: number;
  suggestions!: string[];

  constructor(props: EncyclopediaSearchResultProps) {
    Object.assign(this, props);
  }

  static fromSummarizeResponse(response: SummarizeResponse | undefined): EncyclopediaSearchResult | undefined {
    if (response)
      return new EncyclopediaSearchResult({
        id: response.queryID,
        text: response.summary,
        imageURL: response.imageURL,
        source: SearchResultSource.Wikipedia,
        sourceType: SourceType.Encyclopedia,
        heading: response.heading,
        textParagraphed: response.summaryParaghraphed,
        references: response.references,
        nrOfReferences: response.nrOfReferences,
        nrOfContributors: response.nrOfContributors,
        nrOfRevisions: response.nrOfRevisions,
        fullWikiText: response.fullWikiText,
        trustFactor: response.trustFactor,
        suggestions: response.suggestions,
      });
  }
}
