import React, { useEffect } from 'react';
import './FileUploadWindow.scss';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MAX_FILE_SIZE, IMAGE_HEIGHT, IMAGE_WIDTH } from 'config';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import { resize_image } from 'rustic-image-editor';

export interface UploadButtonProps {
  uploadedImages: File[] | null;
  setUploadedImages: React.Dispatch<React.SetStateAction<File[] | null>>;
  setEncodedImages: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface UploadFileParams {
  setUploadedImages: React.Dispatch<React.SetStateAction<File[] | null>>;
  setEncodedImages: React.Dispatch<React.SetStateAction<string[]>>;
  files: FileList | null;
}

interface ResizeImageParams {
  data: Buffer;
  width: number;
  height: number;
}

// eslint-disable-next-line
async function resizeImage(params: ResizeImageParams) {
  try {
    // Resize the image
    let start = Date.now();
    // eslint-disable-next-line
    let resized_data = resize_image(params.data, params.width, params.height);

    console.log('Image resized in ' + (Date.now() - start) + 'ms');

    console.log('Image resized and saved successfully');
    return resized_data;
  } catch (error) {
    console.error('Error resizing and saving image:', error);
    return null;
  }
}

export const uploadFile = async (params: UploadFileParams) => {
  if (params.files == null) {
    return;
  }

  let files: File[] = [];

  // take the start time
  const start = Date.now();
  // Check file size
  for (let i = 0; i < params.files.length; i++) {
    let original_size = params.files[i].size;

    if (params.files[i].size > MAX_FILE_SIZE) {
      alert(`File ${params.files[i].name} exceeds the size limit of 20MB.`);
      return;
    }

    let file = params.files[i];

    let binary_file = await file.arrayBuffer();
    let buffer = Buffer.from(binary_file);

    let resized_data = await resizeImage({
      data: buffer,
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
    });

    if (resized_data == null) {
      alert(`Error resizing and saving image: ${params.files[i].name}, Will not be resized`);
    } else {
      file = new File([resized_data], file.name, { type: file.type });
    }

    files.push(file);
    let new_size = file.size;
    let savings = original_size - new_size;
    let savings_mb = savings / 1024 / 1024;
    let savings_percent = (savings / original_size) * 100;

    console.log(
      'File ' +
        file.name +
        ' resized from ' +
        original_size / 1024 / 1024 +
        'MB to ' +
        new_size / 1024 / 1024 +
        'MB and saved ' +
        savings +
        ' bytes (' +
        savings_mb +
        ' MB, ' +
        savings_percent.toFixed(2) +
        '%)'
    );
  }

  params.setUploadedImages(files);
  let base64Files = await encodeFiles(files);
  const stopTime = Date.now();
  console.log('File upload time: ' + (stopTime - start) + 'ms');
  params.setEncodedImages(base64Files);
};

export const encodeFiles = async (files: File[]) => {
  let base64Files: string[] = await Promise.all(
    files.map(async (file) => {
      console.log('encoding file ' + file.name);

      let binary_file = await file.arrayBuffer();
      let buffer = Buffer.from(binary_file);
      let base64 = buffer.toString('base64');

      return base64;
    })
  );

  return base64Files;
};

export const UploadButton = ({ uploadedImages, setUploadedImages, setEncodedImages }: UploadButtonProps) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (uploadedImages) {
      encodeFiles(uploadedImages).then((base64Files) => {
        setEncodedImages(base64Files);
      });
    }
  }, [uploadedImages]);

  return (
    <div className="upload-button-container">
      <Button
        className="upload-button"
        component="label"
        role={undefined}
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        {t('chatGpt.imageUploadButton')}
        <input
          hidden
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={async (event) => {
            let params = {
              setUploadedImages: setUploadedImages,
              setEncodedImages: setEncodedImages,
              files: event.target.files,
            };
            await uploadFile(params);
          }}
        />
      </Button>
    </div>
  );
};
