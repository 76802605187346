import React from 'react';
import './ModelSelector.scss';

export interface ModelOption {
  label: string;
  model: string;
}

interface ModelSelectorProps {
  models: ModelOption[];
  selectedModel: ModelOption;
  setSelectedModel: React.Dispatch<React.SetStateAction<ModelOption>>;
}

export const ModelSelector: React.FC<ModelSelectorProps> = ({ models, selectedModel, setSelectedModel }) => {
  const getItemClass = (model: ModelOption) => {
    return model.model === selectedModel.model ? 'is-active' : '';
  };

  return (
    <div className="model-selector tabs is-toggle is-toggle-rounded model-tabs" id="model-selector">
      {models.map((model) => (
        <li className={getItemClass(model)} onClick={() => setSelectedModel(model)} key={model.model}>
          <a className="has-text-centered">{model.label}</a>
        </li>
      ))}
    </div>
  );
};
