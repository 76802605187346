import React, { Fragment, useEffect } from 'react';
import './SearchSourceTags.scss';
import { useTranslation } from 'react-i18next';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import { DictionarySearchResult } from 'models/DictionarySearchResult';
import { GNewsResponse } from 'apis/gnews/models/GNewsResponse';
import { SRResponse } from 'apis/sr/models/SRResponse';
import { getLogoFromSource } from 'utils/searchHelpers';
import useCurrentPage from 'hooks/useCurrentPage';
import { SNLSingleResponse, SNLMultiResponse } from 'apis/snl/models/SNLResponses';

interface Props {
  encyclopediaResult: EncyclopediaSearchResult | undefined;
  gnewsResult: GNewsResponse[] | undefined;
  srResult: SRResponse[] | undefined;
  snlResult: SNLMultiResponse[] | undefined;
  dictionaryResult: DictionarySearchResult | undefined;
  singleResult:
    | EncyclopediaSearchResult
    | DictionarySearchResult
    | GNewsResponse
    | SRResponse
    | SNLSingleResponse
    | undefined;
  dictionaryMultiLangResult?: DictionarySearchResult[] | undefined;
  sourceTagFilters: string[];
  setSourceTagFilters: (filters: string[]) => void;
}

export default function SearchSourceTags({
  encyclopediaResult,
  gnewsResult,
  srResult,
  snlResult,
  dictionaryResult,
  dictionaryMultiLangResult,
  singleResult,
  sourceTagFilters,
  setSourceTagFilters,
}: Props) {
  const [t] = useTranslation();
  const currentPage = useCurrentPage();

  useEffect(() => {
    setSourceTagFilters([]);
  }, [currentPage.rawQuery, setSourceTagFilters]);

  let sources = [];

  if (currentPage.isSingleEntry && singleResult?.source) {
    sources.push(singleResult.source);
  } else if (currentPage.isSingleEntry && dictionaryMultiLangResult && dictionaryMultiLangResult[0]?.source) {
    sources.push(dictionaryMultiLangResult[0].source);
  } else {
    if (encyclopediaResult?.source) {
      sources.push(encyclopediaResult.source);
    }
    if (snlResult) {
      snlResult.forEach((item) => {
        sources.push(item.source);
      });
    }
    if (dictionaryResult?.source && dictionaryResult?.multiSearchPreviewText) {
      sources.push(dictionaryResult.source);
    }
    if (gnewsResult) {
      gnewsResult.forEach((item) => {
        sources.push(item.source);
      });
    }
    if (srResult) {
      srResult.forEach((item) => {
        sources.push(item.source);
      });
    }
  }

  sources = sources.filter((source, index, self) => self.indexOf(source) === index);

  const sourceTags = sources.map((source, index) => {
    let logo = getLogoFromSource(source);
    let text = source;

    if (currentPage.isSingleEntry) {
      if (text) {
        text = `${t(`appHero.informationFrom`)} ${text}`;
      } else {
        text = `${t(`appHero.informationFrom`)} ${singleResult?.source}`;
      }
    }

    const typeClassName = currentPage.isSingleEntry ? 'single-entry' : 'multi-search';
    const activeClassName = sourceTagFilters.includes(source) ? 'active' : '';
    const inActiveClassName = sourceTagFilters.length > 0 && !sourceTagFilters.includes(source) ? 'inactive' : '';

    return (
      <span
        className={`source-tag tag search-result-source-tag is-rounded is-medium ${typeClassName} ${activeClassName} ${inActiveClassName}`}
        key={index}
        data-cy="source-tag"
        onClick={() => {
          if (currentPage.isSingleEntry) {
            return;
          } else if (sourceTagFilters.includes(source)) {
            setSourceTagFilters(sourceTagFilters.filter((sourceTagFilter) => sourceTagFilter !== source));
          } else {
            setSourceTagFilters([...sourceTagFilters, source]);
          }
        }}
      >
        {logo && <img className="image" src={logo} alt={text} />}
        <span className="text">{text}</span>
      </span>
    );
  });

  return (
    <Fragment>
      <div className={`SearchSourceTags ${singleResult || dictionaryMultiLangResult ? 'single-result' : ''}`}>
        <div className="inner-container">{sourceTags}</div>
      </div>
    </Fragment>
  );
}
