/* eslint-disable no-unused-vars */
export enum AppRoutes {
  START = '/',
  LOGIN = '/logga-in',
  SSO = '/sso',
  MULTI_SEARCH = '/s/:searchLang/:query',
  MEDIA_START = '/media',
  MEDIA_SEARCH = '/media/:searchLang/:query',
  ENCYCLOPEDIA_START = '/uppslagsverk',
  ENCYCLOPEDIA_SEARCH = '/uppslagsverk/s/:searchLang/:query',
  ENCYCLOPEDIA_SINGLE = '/uppslagsverk/:searchLang/:query',
  DICTIONARY_START = '/ordbok',
  DICTIONARY_SINGLE = '/ordbok/:searchLang/:query',
  NEWS_SINGLE = '/nyhet/:query',
  SR_SINGLE = '/sr/:query',
  UKRAINE_START = '/ukraina',
  UKRAINE_SEARCH = '/ukraina/:searchLang/:query',
  UKRAINE_NEWS_SINGLE = '/ukraina/nyhet/:searchLang/:query',
  UKRAINE_SR_SINGLE = '/ukraina/sr/:searchLang/:query',
  ASK_JUNIE = '/AI',
  INFO_CHATGPT = '/info-AI',
}
