//Disable eslint for this file
/* eslint-disable */

import { ImageMessage, TextMessage } from 'components/AI/fileUpload/FileUploadWindow';
import { LanguageItem } from 'components/common/LanguagePicker';

export enum AIView {
  CHAT = 'chat',
  DATA_INPUT = 'dataInput',
  CREATE_QUESTIONS = 'createQuestions',
  QUIZ = 'quiz',
  DISABILITY_HELP = 'disabilityHelp',
  LECTURE_INSPIRATION = 'lectureInspiration',
  SUBSTITUTE_TEACHER_HELP = 'substituteTeacherHelp',
  EXPLANATION = 'explanation',
  HELP_GETTING_STARTED = 'helpGettingStarted',
  GENERATE_IMAGE = 'generateImage',
}

export enum GPTModelIds {
  GPT4OMINI = 'gpt-4o-mini',
  GPT4 = 'gpt-4',
  GPT45 = 'gpt-4-1106-preview',
}

export interface GPTModels {
  label: string;
  model: string;
  vision: boolean;
}

export interface QAItem {
  id: string;
  question: string | Array<ImageMessage | TextMessage>;
  answer: string | null;
  ignoreRenderInChat: boolean;
  questionModel?: string;
  ignoreLanguageCommand?: boolean;
  isSpecialNeedsHelpAnswer?: boolean;
  questionProcessed?: boolean;
  showAnswers?: boolean;
  isStepByStep?: boolean;
}

export interface EventData {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: Array<{
    delta: { content: string };
    index: number;
    finish_reason: null | string;
  }>;
}

export interface GenerateImageMessage {
  questionID: string;
  authString: string;
  viewID: string;
  innerRequest: GenerateImageMessageInner;
}

export interface GenerateImageMessageInner {
  model: string;
  prompt: string;
  n?: number;
  size: string;
  quality?: 'hd' | 'standard';
  response_format: 'url' | 'b64_json';
  style?: 'vivid' | 'natural';
}

export interface ImageChatMessagesMap {
  [key: string]: ImageChatMessage[];
}

export interface ImageChatMessage {
  id: string;
  prompt: string;
  response: ImageResponse | null;
  processing: boolean;
}

export interface MessageHistoryItem {
  role: 'user' | 'system';
  content: string | Array<ImageMessage | TextMessage>;
}

export interface Prompt {
  model: string;
  questionId: string;
  messages: MessageHistoryItem[];
  frequencyPenalty: number;
  temperature: number;
  presencePenalty: number;
  authString: string;
  viewId: string;
}

export type MessagesMap = {
  [key: string]: QAItem[];
};

export type QuestionActiveStatus = {
  [key: string]: boolean;
};

export interface ImageResponse {
  created: number;
  data: Array<ImageResponseData>;
}

export interface ImageResponseData {
  revised_prompt?: string;
  url?: string;
  b64_json?: string;
}

export class ImageMessageBuffer {
  private buffer: string = '';
  private id: string = '';

  // Regular expression to match UUIDs
  private uuidRegex: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  // Function to split the message and reconstruct the JSON
  private parseMessage(msg: string): string {
    // Split the message by the delimiter
    const parts = msg.split('!;!');

    // Filter out parts that match the UUID pattern or contain "chat" keyword
    const filteredParts = parts.filter((part) => {
      return !this.uuidRegex.test(part.trim()) && !part.trim().startsWith('chat');
    });

    // Join the remaining parts back into a single string
    const cleanedMessage = filteredParts.join('');

    // Return the cleaned JSON string
    return cleanedMessage;
  }

  // Append incoming message to buffer
  public appendMessage(message: string): void {
    this.buffer += message;
  }

  // Try to parse the buffered message
  public tryParse(): ImageResponse | null {
    try {
      // Parse and reconstruct the JSON from buffer
      const parsedString = this.parseMessage(this.buffer);
      const data: ImageResponse = JSON.parse(parsedString);

      // Reset buffer
      this.buffer = '';
      return data;
    } catch (error) {
      // If an error occurs, do not print it and return null
      return null;
    }
  }

  // Clear buffer
  public clear(): void {
    this.buffer = '';
  }
}

export type ChatState = {
  messages: MessagesMap;
  imageMessages: ImageChatMessagesMap;
  activeView: string;
  connectionStatus: 'connected' | 'disconnected' | 'error' | 'closed';
  prompt: Prompt | null;
  questionActiveStatus: QuestionActiveStatus;
  cancelViewPrompt: string | null;
  selectedLanguage: LanguageItem | undefined;
  teacherModels: GPTModels[];
  studentModels: GPTModels[];
  selectedGPTModel: GPTModels;
  selectedFrequencyPenalty: number;
  selectedTemperature: number;
  selectedPresencePenalty: number;
  setQuestionActiveStatus: (view: string, status: boolean) => void;
  setCancelViewPrompt: (id: string | null) => void;
  setPrompt: (prompt: Prompt | null) => void;
  addMessage: (view: string, qaItem: QAItem) => void;
  addResponse: (view: string, response: string) => void;
  addImageMessage: (view: string, prompt: string) => void;
  addImageResponse: (view: string, response: string) => void;
  setConnectionStatus: (status: ChatState['connectionStatus']) => void;
  getViewLatestQuestionId: (view: string) => string | undefined;
  setSelectedLanguage: (language: LanguageItem | undefined) => void;
  markLastQuestionProcessed: (view: string) => void;
  clearEmptyMessageIfExist: (view: string) => void;
  cancelQuestion: (view: string) => void;
  setSelectedGPTModel: (model: GPTModels) => void;
  setSelectedFrequencyPenalty: (penalty: number) => void;
  setSelectedTemperature: (temperature: number) => void;
  setSelectedPresencePenalty: (penalty: number) => void;
  setActiveView: (view: string) => void;
  generateImageMessage: GenerateImageMessage | null;
  setGenerateImageMessage: (innerMessage: GenerateImageMessageInner) => void;
  imageResponseBuffer: ImageMessageBuffer;
};
