import React, { FormEvent, useEffect, useState, useRef } from 'react';
import useStore from 'store';
import { useTranslation } from 'react-i18next';
import Icon from 'components/app/AppIcon';
import SearchFormLanguageSwitcher from 'components/search/SearchFormLanguageSwitcher';
import './SearchForm.scss';
import { useLocation } from 'wouter';
import useCurrentPage from 'hooks/useCurrentPage';
import { AppRoutes } from 'models/enums/AppRoutes';
import { storeSearchQuery } from 'utils/AnalyticsHelpers';
interface Props {
  isComingFromMediaSearch: boolean;
}

export default function SearchForm({ isComingFromMediaSearch }: Props) {
  const [t, i18n] = useTranslation();
  const [query, setQuery] = useState('');
  const [location, setLocation] = useLocation();
  const [searchLanguage, setSearchLanguage] = useState(i18n.language);
  const [showSearchLanguageDropdown, setShowSearchLanguageDropdown] = useState(false);
  const loading = useStore((state) => state.loading);
  const currentPage = useCurrentPage();
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!query && currentPage.rawQuery) {
      if (isOnlyNumbers(currentPage.rawQuery) || currentPage.rawQuery.includes('%2F')) {
        return;
      }
      setQuery(decodeURIComponent(currentPage.rawQuery).replaceAll('_', ' '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.rawQuery]);

  useEffect(() => {
    if (currentPage.isStart) {
      setQuery('');
    }
  }, [currentPage.isStart]);

  useEffect(() => {
    if (currentPage.searchLang && currentPage.searchLang !== searchLanguage) {
      setSearchLanguage(currentPage.searchLang);
      // eslint-disable-next-line eqeqeq
    } else if (i18n.language == 'da') {
      setSearchLanguage('en');
    } else {
      setSearchLanguage(i18n.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const isOnlyNumbers = (string: string) => {
    return /^-?\d+$/.test(string);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const cleanQuery = query.trim();

    if (cleanQuery.length === 0) {
      return;
    }

    searchInput.current?.blur();

    if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSearch || currentPage.isEncyclopediaSingle) {
      setLocation(AppRoutes.ENCYCLOPEDIA_SEARCH.replace(':searchLang', searchLanguage).replace(':query', cleanQuery));
    } else if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
      setLocation(AppRoutes.DICTIONARY_SINGLE.replace(':searchLang', searchLanguage).replace(':query', cleanQuery));
    } else if (currentPage.isMediaStart || currentPage.isMediaSearch || isComingFromMediaSearch) {
      setLocation(AppRoutes.MEDIA_SEARCH.replace(':searchLang', searchLanguage).replace(':query', cleanQuery));
    } else {
      setLocation(AppRoutes.MULTI_SEARCH.replace(':searchLang', searchLanguage).replace(':query', cleanQuery));
    }
  };

  let inputPlaceholder = t('appHero.startInputPlaceholder');
  if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
    inputPlaceholder = t('appHero.dictionaryInputPlaceholder');
  } else if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSearch || currentPage.isEncyclopediaSingle) {
    inputPlaceholder = t('appHero.encyclopediaInputPlaceholder');
  } else if (currentPage.isMediaStart || currentPage.isMediaSearch) {
    inputPlaceholder = t('appHero.mediaSearchInputPlaceholder');
  }

  const handleSearchLanguageClick = (language: string) => {
    setSearchLanguage(language);
    setShowSearchLanguageDropdown(false);
  };

  let searchType = `Unknown (${location})`;
  if (currentPage.isEncyclopediaStart || currentPage.isEncyclopediaSearch || currentPage.isEncyclopediaSingle) {
    searchType = 'Encyclopedia';
  } else if (currentPage.isDictionaryStart || currentPage.isDictionarySingle) {
    searchType = 'Dictionary';
  } else if (currentPage.isMediaStart || currentPage.isMediaSearch || isComingFromMediaSearch) {
    searchType = 'Media Search';
  } else if (currentPage.isStart || currentPage.isMultiSearch) {
    searchType = 'Multi Search';
  }

  return (
    <form
      onSubmit={(event) => {
        onSubmit(event);
        storeSearchQuery(query, searchType);
      }}
      data-testid="searchForm"
      className="SearchForm"
      action=""
      role="search"
    >
      <div className="field has-addons">
        <div className="control has-icons-left is-expanded">
          <input
            id="searchInput"
            ref={searchInput}
            className="input is-rounded pl-6"
            type="text"
            readOnly={loading}
            placeholder={inputPlaceholder}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            data-testid="searchInput"
            data-cy="hero-search-input"
          />

          {loading ? (
            <Icon name="spinner" left className="ml-2" type="fas fa-spin loading" />
          ) : (
            <Icon name="search" left className="ml-2" />
          )}
        </div>

        <SearchFormLanguageSwitcher
          searchLanguage={searchLanguage}
          showSearchLanguageDropdown={showSearchLanguageDropdown}
          setShowSearchLanguageDropdown={(show) => setShowSearchLanguageDropdown(show)}
          handleSearchLanguageClick={(language) => handleSearchLanguageClick(language)}
        />

        <label className="label is-sr-only" htmlFor="searchInput">
          {t('general.search')}
        </label>
      </div>
    </form>
  );
}
