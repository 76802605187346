import React from 'react';
import './PageCategory.scss';
import { Link } from 'wouter';
import { useTranslation } from 'react-i18next';
import Icon from 'components/app/AppIcon';
import EncyclopediaImage from 'assets/images/encyclopedia.png';
import DictionaryImage from 'assets/images/dictionary.png';
import MediaImage from 'assets/images/mediasearch.png';
import useCurrentPage from 'hooks/useCurrentPage';
import { AppRoutes } from 'models/enums/AppRoutes';
import { tmpUserConfig } from 'utils/tmpUserConfig';

export default function PageCategory() {
  const [t, i18n] = useTranslation();
  const currentPage = useCurrentPage();

  let defaultItems = tmpUserConfig.items.encyclopedia;
  let baseRoute = AppRoutes.ENCYCLOPEDIA_SINGLE;
  let emptyCategoryPageText = t('encyclopedia.emptyCategoryPage');
  let image = EncyclopediaImage;
  if (currentPage.isDictionaryStart) {
    defaultItems = tmpUserConfig.items.dictionary;
    baseRoute = AppRoutes.DICTIONARY_SINGLE;
    emptyCategoryPageText = t('dictionary.emptyCategoryPage');
    image = DictionaryImage;
  } else if (currentPage.isMediaStart) {
    defaultItems = tmpUserConfig.items.media;
    baseRoute = AppRoutes.MEDIA_SEARCH;
    emptyCategoryPageText = t('mediasearch.emptyCategoryPage');
    image = MediaImage;
  } else if (currentPage.isUkraineStart) {
    defaultItems = tmpUserConfig.items.media;
    baseRoute = AppRoutes.UKRAINE_SEARCH;
    emptyCategoryPageText = t('ukraine.emptyCategoryPage');
    image = MediaImage;
  }

  const defaultListItems = defaultItems.map((item, index) => {
    let icon = undefined;
    if (item.type === 'favorite') {
      icon = { name: 'heart', type: 'fas' };
    } else if (item.type === 'history') {
      icon = { name: 'clock', type: 'far' };
    }

    return (
      <div className="column is-half-tablet is-one-third-desktop" key={index}>
        <Link
          href={baseRoute.replace(':searchLang', i18n.language).replace(':query', item.title)}
          className="item default-item"
          title={item.title}
        >
          <p className="title has-text-weight-medium">{item.title}</p>
          <p className="text">{item.text}</p>
          {icon && <Icon className={`item-icon ${item.type}`} type={icon.type} name={icon.name}></Icon>}
        </Link>
      </div>
    );
  });

  return (
    <section className="PageCategory section py-0">
      {defaultListItems && defaultListItems.length > 0 ? (
        <div className="columns is-multiline is-variable is-3-tablet is-7-desktop pt-0">{defaultListItems}</div>
      ) : (
        <div className="no-default-list-items">
          <h2 className="title is-size-4">{emptyCategoryPageText}</h2>
          <img src={image} alt={emptyCategoryPageText} />
        </div>
      )}
    </section>
  );
}
