import React from 'react';
import useStore from 'store';
import './BackButton.scss';
import IconBack from 'assets/icons/back.png';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

export default function BackButton() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const loading = useStore((state) => state.loading);

  const goBack = () => {
    const routeHistory = JSON.parse(window.sessionStorage.getItem('routeHistory') ?? '[]');
    let newRoute;

    if (routeHistory.length > 3 && routeHistory[routeHistory.length - 2] === 'redirect') {
      newRoute = routeHistory[routeHistory.length - 4];
      routeHistory.splice(-3);
    } else if (routeHistory.length > 1 && routeHistory[routeHistory.length - 2] !== 'redirect') {
      newRoute = routeHistory[routeHistory.length - 2];
      routeHistory.splice(-1);
    } else {
      window.history.back();
      return;
    }

    window.sessionStorage.setItem('routeHistory', JSON.stringify(routeHistory.slice(-50)));
    setLocation(newRoute);
  };

  const hiddenClass = loading ? 'hidden' : '';

  return (
    <div className="BackButton is-inline-block hide-when-no-results">
      <div
        className={`button-wrap is-flex is-align-items-center is-clickable is-unselectable py-2 mb-1 ${hiddenClass}`}
        onClick={() => goBack()}
        data-cy="back-button"
      >
        <img src={IconBack} alt={t('navigation.back')} />
        <span>{t('navigation.back')}</span>
      </div>
    </div>
  );
}
