import { BaseAPI } from '../BaseAPI';
import { definableValue } from '../../utils/definableValue';
import { SRAPIMethods } from './SRAPIMethods';
import { SRResponse } from './models/SRResponse';
import { clearAuthentication, getAuthenticationStringAttachment } from 'utils/AuthenticationHelpers';
import { SourceType } from 'models/enums/SourceType';

export class SRAPI extends BaseAPI implements SRAPIMethods {
  constructor() {
    super(process.env.REACT_APP_BACKEND_URL as string);
  }

  async findEpisodes(keyWord: string, language: string): Promise<SRResponse[]> {
    const authenticationString = getAuthenticationStringAttachment();
    const { data } = await this.request.get<any>(
      `/api/sr/episodes?title=${keyWord}&authString=${authenticationString}&language=${language}`
    );

    var srEntry: SRResponse[] = [];
    if (definableValue(data)) {
      for (let i = 0; i < data.length; i++) {
        srEntry.push(this.createSRResponse(data[i]));
      }
    }
    return srEntry;
  }

  createSRResponse = (data: any): SRResponse => {
    const newSRResponse = {
      title: data.title,
      content: data.description,
      imageURL: data.imageURL,
      downloadURL: data.downloadURL,
      source: 'Sveriges Radio',
      sourceType: SourceType.SverigesRadio,
      publishDate:
        new Date(parseInt(data.publishDateUTC?.replace(/[^0-9]/g, '')))?.toISOString()?.split('.')?.[0] + 'Z', // Convert /Date(1605009900000)/ to 2020-11-10T12:05:00Z
      programName: data.programName,
      episodeId: data.episodeId,
      programId: data.programId,
      url: data.url,
      sourceCriticism: data.sourceCriticism,
    };
    const cacheKey = `sr/single/${data.episodeId}`;
    window.sessionStorage.setItem(cacheKey, JSON.stringify(newSRResponse));
    return newSRResponse;
  };

  async getSingle(query: string): Promise<SRResponse | undefined> {
    try {
      const cacheKey = `sr/single/${query}`;
      const cachedResponse = window.sessionStorage.getItem(cacheKey);
      if (cachedResponse) {
        return JSON.parse(cachedResponse);
      }
      const authenticationString = getAuthenticationStringAttachment();
      const { data } = await this.request.get<any>(
        `/api/sr/episodes/single?id=${query}&authString=${authenticationString}`
      );
      data.source = 'Sveriges Radio';
      data.sourceType = SourceType.SverigesRadio;
      const result = this.createSRResponse(data);
      window.sessionStorage.setItem(cacheKey, JSON.stringify(result));
      return result;
    } catch (error) {
      console.log('error', error);
    }
  }

  async getResult(keyWord: string, language: string = 'sv') {
    const cacheKey = `sr/${language}/${keyWord}`;
    const cachedResponse = window.sessionStorage.getItem(cacheKey);
    if (cachedResponse) {
      return JSON.parse(cachedResponse);
    }
    try {
      const result = await this.findEpisodes(keyWord, language);
      window.sessionStorage.setItem(cacheKey, JSON.stringify(result));
      return result;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        clearAuthentication();
        document.location.reload();
      } else if (error?.response?.status === 401) {
        clearAuthentication();
        document.location.reload();
      }
    }
  }
}
export const srAPI = new SRAPI();
