import React from 'react';
import './LoadingDots.scss'; // Import your CSS
import Stack from '@mui/material/Stack';

const LoadingDots = () => (
  <div className="loading-dots">
    <Stack direction="row" spacing={0.1}>
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </Stack>
  </div>
);

export default LoadingDots;
