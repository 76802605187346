export const storeSearchQuery = (query: string, searchType: string) => {
  const lowerCaseQuery = query.toLocaleLowerCase();
  (window as any).plausible('Search Queries', {
    props: { Query: lowerCaseQuery, Type: searchType },
  });
};

export const storeMostClickedResults = (api: string, source: string | undefined, url: string) => {
  (window as any).plausible('Most Clicked Results', { props: { Source: source, API: api, URL: url } });
};
