import React from 'react';
import './PageSSO.scss';
export default function PageSSO() {
  const sso = () => {
    const skolonAuthUrl = `${process.env.REACT_APP_SKOLON_IDP_URL}/oauth/auth?response_type=code&client_id=${process.env.REACT_APP_SKOLON_CLIENT_ID}&scope=authenticatedUser.identifier+authenticatedUser.school.read+authenticatedUser.profile.read+authenticatedUser.licenses.read&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/logga-in`;
    window.location.href = skolonAuthUrl;
    return (
      <div>
        <div className="section">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <div className="loader-wrapper is-active">
                <div className="loader is-loading" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{sso()}</div>;
}
