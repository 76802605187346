export interface userConfigItem {
  title: string;
  text: string;
  type: string;
}

export const tmpUserConfig = {
  showSourceCriticism: true,
  requestLanguages: ['sv', 'en'],
  items: {
    encyclopedia: [
      // {
      //   title: 'Gitarr',
      //   text: 'Gitarr är ett stränginstrument som består av en resonanslåda med flat eller något rundad botten och en lång hals försedd med greppbräd...',
      //   type: 'favorite',
      // },
      // {
      //   title: 'Kronärtskocka',
      //   text: 'Kronärtskocka (Cynara cardunculus, Scolymus-gruppen) är en kulturgrupp inom arten kardon (Cynara cardunculus) i familjen kor...',
      //   type: 'history',
      // },
      // {
      //   title: 'Spanien',
      //   text: 'Spanien (spanska: España), officiellt Konungariket Spanien[8] (spanska: Reino de España)[e][9], är ett land och en medlemsstat i Europeiska...',
      //   type: 'history',
      // },
      // {
      //   title: 'Andra världskriget',
      //   text: 'Andra världskriget var en väpnad konflikt som pågick från år 1939 till 1945 och involverade de flesta av världens nationer, inklusive alla...',
      //   type: 'history',
      // },
      // {
      //   title: 'Elektricitet',
      //   text: 'Elektricitet, från grekiska ”elektron”, ’bärnsten’[1], är ett fysikaliskt fenomen. Grunden till elektricitet är att materia kan ha en elektrisk...',
      //   type: 'history',
      // },
    ] as userConfigItem[],
    dictionary: [
      // {
      //   title: 'Träd',
      //   text: 'substantiv ~et; pl. ~ el. vard. trän, best. pl. ~en el. vard. träna - Alla sammansättn. med träd- hör till träd.',
      //   type: 'favorite',
      // },
      // {
      //   title: 'Garderob',
      //   text: '[rå´b] substantiv ~en ~er 1 förvaringsrum för kläder 2 förråd av kläder - Alla sammansättn. med...',
      //   type: 'history',
      // },
      // {
      //   title: 'Hus',
      //   text: 'substantiv ~et; pl. ~ 1 mindre byggnad; bildl. inneslutning; gå man ur huse gå ut mangrant...',
      //   type: 'history',
      // },
      // {
      //   title: 'Linne',
      //   text: 'substantiv ~t ~n 1 knappast pl.; vävnad av lingarn; uppsättning lakan, dukar o.d. 2 damunderplagg; nattlinne...',
      //   type: 'history',
      // },
    ] as userConfigItem[],
    media: [
      // {
      //   title: 'Träd',
      //   text: 'substantiv ~et; pl. ~ el. vard. trän, best. pl. ~en el. vard. träna - Alla sammansättn. med träd- hör till träd.',
      //   type: 'favorite',
      // },
      // {
      //   title: 'Garderob',
      //   text: '[rå´b] substantiv ~en ~er 1 förvaringsrum för kläder 2 förråd av kläder - Alla sammansättn. med...',
      //   type: 'history',
      // },
    ] as userConfigItem[],
  },
};
