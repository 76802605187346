import React from 'react';
import './PageMultiSearch.scss';
import useCurrentPage from 'hooks/useCurrentPage';
import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';
import { EncyclopediaSearchResult } from 'models/EncyclopediaSearchResult';
import { DictionarySearchResult } from 'models/DictionarySearchResult';
import { AppRoutes } from 'models/enums/AppRoutes';
import { GNewsResponse } from 'apis/gnews/models/GNewsResponse';
import { SRResponse } from 'apis/sr/models/SRResponse';
import { Sources, SourceType } from 'models/enums/SourceType';
import {
  getLogoFromSource,
  getSourceCriticismData,
  convertSNLArticleUrlJsonToQuery,
  allowedSNLImageLicenses,
  convertNewsUrlToQuery,
} from 'utils/searchHelpers';
import Icon from 'components/app/AppIcon';
import PageNoResults from 'components/pages/PageNoResults';
import iconHeadphones from 'assets/icons/headphones.png';
import { tmpUserConfig } from 'utils/tmpUserConfig';
import { storeMostClickedResults } from 'utils/AnalyticsHelpers';
import { SNLMultiResponse, SNLSingleResponse } from 'apis/snl/models/SNLResponses';
import { SourceVariables } from 'models/enums/SourceVariables';
import { ActiveAudioMeta } from 'models/ActiveAudioMeta';

interface Props {
  encyclopediaResult: EncyclopediaSearchResult | undefined;
  encyclopediaSuggestionResult: EncyclopediaSearchResult[] | undefined;
  dictionaryResult: DictionarySearchResult | undefined;
  gnewsResult: GNewsResponse[] | undefined;
  srResult: SRResponse[] | undefined;
  snlResult: SNLMultiResponse[] | undefined;
  singleResult:
    | EncyclopediaSearchResult
    | DictionarySearchResult
    | GNewsResponse
    | SRResponse
    | SNLSingleResponse
    | undefined;
  setSingleResult?: (singleResult: EncyclopediaSearchResult | DictionarySearchResult | undefined) => void;
  sourceTagFilters: string[];
  activeAudioSrc: string | null;
  activeAudioisPlaying: boolean | null;
  setActiveAudioSrc: (src: string | null) => void;
  setActiveAudioIsPlaying: (isPlaying: boolean) => void;
  setActiveAudioMeta: (meta: ActiveAudioMeta | null) => void;
}

interface resultItem {
  title?: string;
  text?: string;
  image?: string;
  source?: string;
  sourceType: string;
  sourceUrl?: string;
  fullResult?: EncyclopediaSearchResult | DictionarySearchResult;
  articleUrl?: string;
  articleUrlJson?: string;
  downloadURL?: string;
  publishDate?: string;
  programName?: string;
  episodeId?: string;
  programId?: string;
  license?: string;
  trustFactor: number;
  API: string;
  order: number;
}

const SVD = 'Svenska Dagbladet';

export default function PageMultiSearch({
  encyclopediaResult,
  encyclopediaSuggestionResult,
  dictionaryResult,
  singleResult,
  gnewsResult,
  srResult,
  snlResult,
  sourceTagFilters,
  activeAudioSrc,
  activeAudioisPlaying,
  setActiveAudioSrc,
  setActiveAudioIsPlaying,
  setActiveAudioMeta,
}: Props) {
  const currentPage = useCurrentPage();
  const [t] = useTranslation();
  const [, setLocation] = useLocation();

  const shortenText = (text: string, maxLength = 134) => {
    const partOfText = text.slice(0, maxLength).trim();
    return text.length > maxLength ? `${partOfText}...` : partOfText;
  };

  const getFilteredResultItems = (resultItems: resultItem[]) => {
    if (sourceTagFilters.length === 0) {
      return resultItems;
    } else {
      return resultItems.filter((resultItem) => sourceTagFilters.includes(resultItem.source as string));
    }
  };

  const getUniqueTopResults = (resultItems: resultItem[], limit = 6) => {
    const topResults: resultItem[] = [];
    resultItems.forEach((resultItem) => {
      const resultItemSourceExistsInTopResults = topResults.find((topResult) => topResult.source === resultItem.source);
      if ((!resultItemSourceExistsInTopResults && topResults.length < limit) || resultItem.source === SVD) {
        topResults.push(resultItem);
      }
    });
    return topResults;
  };

  const getResultItemsWithUniqueTopResults = (resultItems: resultItem[], uniqueTopResultsCount = 6) => {
    const uniqueTopResults = getUniqueTopResults(resultItems, uniqueTopResultsCount);
    const resultItemsWithOutUniqueTopResults = resultItems.filter(
      (resultItem) =>
        !uniqueTopResults.find(
          (topResult) =>
            topResult.source === resultItem.source &&
            topResult.title === resultItem.title &&
            topResult.text === resultItem.text
        )
    );
    return uniqueTopResults.concat(resultItemsWithOutUniqueTopResults);
  };

  const resultItems: resultItem[] = [];

  const createEncyclopediaResultItem = (encyclopediaResult: EncyclopediaSearchResult, order: number) => {
    return {
      title: encyclopediaResult.heading,
      text: shortenText(encyclopediaResult.text),
      image: encyclopediaResult.imageURL,
      source: encyclopediaResult.source,
      sourceType: encyclopediaResult.sourceType,
      fullResult: encyclopediaResult,
      trustFactor: encyclopediaResult.trustFactor,
      API: 'Inoolabs',
      order,
    };
  };

  if (encyclopediaResult) {
    resultItems.push(createEncyclopediaResultItem(encyclopediaResult, 1));
  }
  if (encyclopediaSuggestionResult) {
    encyclopediaSuggestionResult.forEach((encyclopediaResult, index) => {
      resultItems.push(createEncyclopediaResultItem(encyclopediaResult, 200 + index));
    });
  }

  if (gnewsResult) {
    gnewsResult.forEach((newsItem, index) => {
      const order = newsItem.source === SVD ? 3 + index : 100 + index;
      resultItems.push({
        title: newsItem.title,
        text: newsItem.description?.length > 50 ? shortenText(newsItem.description) : shortenText(newsItem.content),
        image: newsItem.imageURL,
        source: newsItem.source,
        sourceType: newsItem.sourceType,
        sourceUrl: newsItem.sourceURL,
        articleUrl: newsItem.articleURL,
        publishDate: newsItem.publishDate,
        trustFactor: newsItem.sourceCriticism?.trustFactor,
        API: 'GNews',
        order,
      });
    });
  }

  if (srResult) {
    srResult.forEach((newsItem, index) => {
      resultItems.push({
        title: newsItem.title,
        text: shortenText(newsItem.content),
        image: newsItem.imageURL,
        source: newsItem.source,
        sourceType: newsItem.sourceType,
        publishDate: newsItem.publishDate,
        programName: newsItem.programName,
        episodeId: newsItem.episodeId,
        programId: newsItem.programId,
        downloadURL: newsItem.downloadURL,
        trustFactor: newsItem.sourceCriticism?.trustFactor,
        API: 'Sveriges Radio',
        order: 100 + index,
      });
    });
  }

  if (snlResult) {
    snlResult.forEach((item, index) => {
      const order = index === 0 ? 1 : 200 + index;
      resultItems.push({
        title: item.title,
        text: shortenText(item.firstTwoSentences),
        image: allowedSNLImageLicenses.includes(item.firstImageLicense) ? item.firstImageUrl : '',
        articleUrl: item.articleUrl,
        articleUrlJson: item.articleUrlJson,
        source: item.source,
        sourceType: item.sourceType,
        license: item.license,
        trustFactor: 0,
        API: 'SNL',
        order,
      });
    });
  }

  if (dictionaryResult && dictionaryResult.multiSearchPreviewText !== '') {
    resultItems.push({
      title: dictionaryResult.word.charAt(0).toUpperCase() + dictionaryResult.word?.slice(1),
      text: shortenText(dictionaryResult.multiSearchPreviewText, 200),
      image: '',
      source: dictionaryResult.source,
      sourceType: dictionaryResult.sourceType as string,
      fullResult: dictionaryResult,
      trustFactor: dictionaryResult.trustFactor,
      API: 'Skolup (Ordbok)',
      order: 2,
    });
  }

  resultItems.sort((a, b) => a.order - b.order);
  const filteredResultItems = getFilteredResultItems(resultItems);
  const resultItemsWithUniqueTopResults = getResultItemsWithUniqueTopResults(filteredResultItems, 6);

  const resultListItems = resultItemsWithUniqueTopResults.map((item, index) => {
    const logo = getLogoFromSource(item.source);
    let route = '';
    let term = encodeURI((item?.title as string).toLocaleLowerCase());

    if (item.sourceType === SourceType.Encyclopedia) {
      route = AppRoutes.ENCYCLOPEDIA_SINGLE;
    } else if (item.sourceType === SourceType.Dictionary) {
      route = AppRoutes.DICTIONARY_SINGLE;
    } else if (item.sourceType === SourceType.SverigesRadio) {
      route = AppRoutes.SR_SINGLE;
      term = item.episodeId as string;
    } else if (item.sourceType === SourceType.SNL) {
      route = AppRoutes.ENCYCLOPEDIA_SINGLE;
      term = convertSNLArticleUrlJsonToQuery(item.articleUrlJson);
    } else if (item.sourceType === SourceType.News) {
      route = AppRoutes.UKRAINE_NEWS_SINGLE;
      term = convertNewsUrlToQuery(item.articleUrl);
    }

    if (currentPage.isUkraineSearch && item.source !== Sources.RT) {
      route = AppRoutes.UKRAINE_SR_SINGLE;
    } else if (currentPage.isUkraineSearch && item.source === Sources.RT) {
      route = AppRoutes.UKRAINE_NEWS_SINGLE;
    }

    let href = route.replace(':searchLang', currentPage.searchLang).replace(':query', term);
    let openInNewWindow = false;
    let rel = 'noopener noreferrer';

    if (item.sourceType === SourceType.News && item.source !== Sources.RT) {
      href = item.articleUrl as string;
      openInNewWindow = true;
    }

    if (item.sourceType === SourceType.SNL && item.license !== SourceVariables.SNL_FREE_LICENSE) {
      href = item.articleUrl as string;
      openInNewWindow = true;
    }

    if (item.source === SVD) {
      rel = '';
    }

    const date = item.publishDate ? item.publishDate?.split('T')?.[0] : '';

    const contentClass = item.image ? 'content' : 'content no-image';
    const titleClass = item.title && item.title.length > 36 ? 'is-size-5' : '';

    const sourceCriticismData = getSourceCriticismData(item.sourceType, item.source);

    const currentEpisodeIsPlaying = activeAudioisPlaying && activeAudioSrc === item.downloadURL;

    return (
      <div className="column is-half-tablet is-one-third-desktop" key={index}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={href}
          className="item result-item"
          title={`${item.source} - ${item.title}`}
          target={openInNewWindow ? '_blank' : ''}
          onClick={(e) => {
            storeMostClickedResults(item.API, item.source, href);
            if (!openInNewWindow) {
              e.preventDefault();
              setLocation(href);
            }
          }}
          rel={rel}
          data-cy="result-item"
        >
          {item.image && (
            <div className="image-container">
              <div className="image" style={{ backgroundImage: `url(${item.image})` }} />
            </div>
          )}
          <div className={contentClass}>
            <h2 className={`title has-text-weight-medium ${titleClass}`}>{item.title}</h2>
            <p className="text">{item.text}</p>
            {date && (
              <div className="extra left">
                {item.sourceType === SourceType.SverigesRadio && !currentPage.isUkraineSearch && (
                  <div
                    className="sr-icon-container"
                    onClick={(e) => {
                      if (item.sourceType === SourceType.SverigesRadio) {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveAudioSrc(item.downloadURL as string);
                        setActiveAudioMeta({ title: item.title as string, source: item.source as string });
                        if (currentEpisodeIsPlaying) {
                          setActiveAudioIsPlaying(false);
                        } else {
                          setActiveAudioIsPlaying(true);
                        }
                      }
                    }}
                  >
                    <img className="info" src={iconHeadphones} alt="" />
                    <Icon className="toggle" name={currentEpisodeIsPlaying ? 'pause' : 'play'} />
                  </div>
                )}
                <span className="text">{date}</span>
              </div>
            )}
            {logo && (
              <div className={`source-container source-${item.source}`}>
                <img className="source" src={logo} alt={item.source} />
              </div>
            )}
            {!logo && (
              <div className="extra right">
                <span className="text">{shortenText(item.source!, 20)}</span>
              </div>
            )}
          </div>

          {tmpUserConfig.showSourceCriticism && (
            <div
              title={t(sourceCriticismData.textShortTranslationKey)}
              className={`source-criticism-container ${sourceCriticismData.className} tf-${index}`}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                document.querySelector(`.tf-${index}`)?.classList.toggle('active');
                document.querySelector(`.tf-${index}`)?.classList.add('transitioning');
                await new Promise((r) => setTimeout(r, 200));
                document.querySelector(`.tf-${index}`)?.classList.remove('transitioning');
              }}
            >
              <Icon name={sourceCriticismData.iconName} />
              <div className="info">{t(sourceCriticismData.textShortTranslationKey)}</div>
            </div>
          )}
        </a>
      </div>
    );
  });

  return (
    <section className="PageMultiSearch section pt-4">
      {!resultListItems.length && <PageNoResults />}
      <div className="columns is-multiline is-variable is-3-tablet is-7-desktop pt-4">{resultListItems}</div>
    </section>
  );
}
